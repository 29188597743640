define("@nsf/ember-properties-loader/utils/object-traverse", ["exports", "@nsf/ember-properties-loader/utils/is-object"], function (_exports, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = traverseObject;
  function traverseRecursive(input, parentChain, callback, currentDepth, maxDepth, sortKeys) {
    const type = (0, _isObject.isObject)(input) ? 'object' : Array.isArray(input) ? 'array' : typeof input;
    callback.call(null, input, type, parentChain);
    if ((0, _isObject.isObject)(input)) {
      let keys = Object.keys(input);
      if (sortKeys) {
        keys = keys.sort();
      }
      if (currentDepth <= maxDepth) {
        for (let i = 0; i < keys.length; i += 1) {
          traverseRecursive(input[keys[i]], [...parentChain, keys[i]], callback, currentDepth + 1, maxDepth, sortKeys);
        }
      }
    } else if (Array.isArray(input)) {
      for (let i = 0; i < input.length; i += 1) {
        traverseRecursive(input[i], [...parentChain, `[${i}]`], callback, currentDepth, maxDepth, sortKeys);
      }
    }
  }
  function traverseObject(input, callback) {
    let maxDepth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
    let sortKeys = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    traverseRecursive(input, [], callback, 1, maxDepth, sortKeys);
    return input;
  }
});