define("@nsf-open/ember-currency-utils/index", ["exports", "@nsf-open/ember-currency-utils/formatters", "@nsf-open/ember-currency-utils/computed-macros"], function (_exports, _formatters, _computedMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_formatters).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _formatters[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _formatters[key];
      }
    });
  });
  Object.keys(_computedMacros).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _computedMacros[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _computedMacros[key];
      }
    });
  });
});