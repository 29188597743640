import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<a\n  ...attributes\n  href={{this.href}}\n  rel='nofollow noopener noreferrer'\n  target='_blank'\n>\n  {{yield}}<UiIcon class='icon-pad-left' @name='external-link' />\n</a>", {"contents":"<a\n  ...attributes\n  href={{this.href}}\n  rel='nofollow noopener noreferrer'\n  target='_blank'\n>\n  {{yield}}<UiIcon class='icon-pad-left' @name='external-link' />\n</a>","moduleName":"@rgov/ui-elements/components/rgov-external-url/index.hbs","parseOptions":{"srcName":"@rgov/ui-elements/components/rgov-external-url/index.hbs"}});
import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';
import type { PropertiesService } from '@nsf/ember-properties-loader';
import type { RGovConfig } from '@rgov/webapp-config';

type Args = { href: string };

/**
 * The RGovExternalUrl component creates an HTML anchor element that hyperlinks to a external
 * redirect interstitial which provides information to the user about their leaving an NSF
 * website.
 *
 * The URL of the intended destination is provided as the `url` query parameter, and after a
 * short pause the interstitial makes sure that the user is forwarded to that destination.
 *
 * The only required attribute is `@href`; all additional attributes are passed directly to
 * the anchor element unaltered
 *
 * ```handlebars
 * <RgovExternalUrl @href="https://www.google.com" aria-label="Search via Google">
 *   Google
 * </RgovExternalUrl>
 * ```
 */
export default class RgovExternalUrl extends Component<Args> {
  @service
  protected declare readonly properties: PropertiesService<RGovConfig>;

  protected get href() {
    const url = this.args.href;
    const address = new URL(url);

    if (/\.gov$/.test(address.hostname)) return url;

    return this.properties.getReplace('externalRedirect', {
      url: encodeURIComponent(url),
    });
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    assert(
      'The RgovExternalUrl component requires an @href value',
      typeof args.href === 'string',
    );
  }
}
