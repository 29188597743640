// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { guidFor } from '@ember/object/internals';
import { layout } from '@ember-decorators/component';
import template from './template';
import { FileDescription, FileMimeTypes, FileIconClassNames } from '@rgov/ui-elements/constants';

interface FileManager {
  files: FileDescription[];
  onFileSelected: (file: File) => unknown;
  onFileDeleted: (id: string) => unknown;
  onFileLinkClicked: (id: string) => void;
}

/**
 * The RGovFileManager component is responsible to manage file upload, view & delete
 */
@layout(template)
// eslint-disable-next-line ember/require-tagless-components
export default class RGovFileManager extends Component implements FileManager {

  /**
   * The aria-label of the file input.
   */
  public label?: string = 'File Input Field';
  
  public browseLabelText = 'Browse for file to upload';

  public browseLabelTextViewOnly = 'Uploaded Files';

  public browseLabelClass?: string;

  public browseButtonText = 'Browse ...';

  public browseButtonAnotherText = 'Browse for another...';

  public browseButtonClass?: string;

  public browseButtonSize?: string;

  public singleFileUploadDisableText = 'Only one file can be uploaded. To replace the file, delete it, then browse for a new file.';

  public multiFileUploadDisableText = 'Maximum number of files have been uploaded. To replace a file, delete it, then browse for a new file.';

  public deleteButtonTitleText = 'Delete file';

  public deleteTrashCanIconClass = 'trash-o';

  public spinnerIconClass = 'spinner spin';

  public noFilesUploadedMessage = 'Currently, no files have been uploaded';

  public uploadingText = 'Uploading...';

  public pendingFileName = '';

  public pendingFileIcon = '';

  public isUploading?: boolean;

  public isDeleting?: boolean;

  public deleteButtonLabel = 'Delete';

  public deletePendingText = 'Deleting...';

  public deleteConfirmText = 'Are you sure you want to delete';

  public fileLinkTitleText = 'Opens in new window';

  /**
   * FileDescription is a type which all uploaded files must adhere to
   * FileDescription = {
   *  id: string;
   *  name: string;
   *  type: string;
   *  size: number;
   *  iconClass: string;
   *  url: string;
   * }
   */
   public files: FileDescription[] = [];
  
   /**
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
   * allowedFileTypes is a string containing one or more of these 
   * unique file type specifiers, separated by commas.
   * default is pdf
   */
  public allowedFileTypes?: string = '.pdf';

   /**
   * maxFilesAllowed sets how many files can be uploaded
   * default is set to 1 
   */
  public maxFilesAllowed = 1;

  /**
   * state when no files can be uploaded e.g. proposal is submitted
   * think of it as submitted state. 
   * (browse button is hidden)
   */
  public viewOnly = false;

  /**
   * state when no files can be uploaded e.g. due date is passed
   * think of it as in progress 'disabled' state. 
   * (browse button is shown but disabled & canNotUploadReason is hover over text)
   */
   public canNotUploadReason = '';

  /**
   * @protected
   */
  inputFileId!: string;
    
  /**
   * @protected
   */
  browseButtonId!: string;

  /**
   * Callback which executes whenever file is selected.
   */
  public onFileSelected!: (file: File) => unknown;

  /**
   * Callback which executes whenever file is deleted.
   */
  public onFileDeleted!: (id: string) => unknown;

  /**
   * Callback which executes whenever file link is clicked to open file in new window.
   */
   public onFileLinkClicked!: (id: string) => void;

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init();

    const guid = guidFor(this);
    set(this, 'inputFileId', `${guid}-inputFile`);
    set(this, 'browseButtonId', `${guid}-button`);
  }

  fileIconClassName(type: string) {
    if (!isEmpty(type)) {
      const fileType = type.toLowerCase();
      if (FileMimeTypes.PDF.includes(fileType)) {
        return FileIconClassNames.PDF;
      } else if (FileMimeTypes.MS_WORD.includes(fileType)) {
        return FileIconClassNames.MS_WORD;
      } else if (FileMimeTypes.MS_EXCEL.includes(fileType)) {
        return FileIconClassNames.MS_EXCEL;
      } else if (FileMimeTypes.TEXT.includes(fileType)) {
        return FileIconClassNames.TEXT;
      } else if (FileMimeTypes.IMAGE.includes(fileType)) {
        return FileIconClassNames.IMAGE;
      }
      return FileIconClassNames.DEFAULT;
    }
    return ''
  }

  @computed('viewOnly', 'browseLabelTextViewOnly', 'browseLabelText')
  get labelText() {
    return this.viewOnly ? this.browseLabelTextViewOnly : this.browseLabelText;
  }

  @computed('maxFilesAllowed', 'files.[]', 'browseButtonAnotherText', 'browseButtonText')
  get buttonText() {
   return (this.maxFilesAllowed > 1 && this.files.length > 0 )
    ? this.browseButtonAnotherText : this.browseButtonText;
  }

  @computed('maxFilesAllowed', 'files.[]', 'isUploading', 'isDeleting', 'canNotUploadReason')
  get browseButtonDisabled() {
    const { maxFilesAllowed, files, isUploading, isDeleting,  canNotUploadReason  } = this;
    
    if (isUploading || isDeleting ) {
      return true;
    }
    if (!isEmpty(canNotUploadReason)) {
      return true;
    }
    return (files.length > maxFilesAllowed - 1);
  }

  @computed('maxFilesAllowed', 'files.[]', 'isUploading', 'isDeleting', 'canNotUploadReason', 'multiFileUploadDisableText', 'singleFileUploadDisableText')
  get browseButtonTitleText() {
    const { maxFilesAllowed, files, canNotUploadReason, multiFileUploadDisableText, singleFileUploadDisableText } = this;

    if (!isEmpty(canNotUploadReason)) {
      return canNotUploadReason;
    }
    if (files.length > maxFilesAllowed - 1) {
      const buttonDisabledText = (maxFilesAllowed > 1) ? multiFileUploadDisableText : singleFileUploadDisableText;
      return buttonDisabledText;
    }
    return '';
  }

  @computed('files.[]')
  public get updatedFiles() {
    const updatedFiles = this.files;
    if (!isEmpty(updatedFiles)) {
      updatedFiles.forEach((updatedFile) => {
        updatedFile.iconClass = this.fileIconClassName(updatedFile.type);
      });
    }
    return updatedFiles;
  }

  @computed('files.[]', 'viewOnly')
  public get showNoFilesUploadedMessage() {
    return (isEmpty(this.files) && this.viewOnly);
  }

  /**
   * trigger click event for input file as field is hidden
   * open native file picker
   */
  @action
  browseForFile() {
    const element = document.getElementById(this.inputFileId) as HTMLInputElement;
    element?.click();
  }

  @action
  async fileSelect(files: (File)[]) {
    const fileObject = files[0];
    
    if (typeof this.onFileSelected === 'function') {
      set(this, 'isUploading', true);
      set(this, 'pendingFileName', fileObject.name);
      set(this, 'pendingFileIcon', this.fileIconClassName(fileObject.type));

      const isFileUploaded = await this.onFileSelected?.(fileObject);
      
      set(this, 'isUploading', false);
      set(this, 'pendingFileName', '');
      set(this, 'pendingFileIcon', '');

      // Scenario user selects a file then perform delete action, Now user selects the same file again.
      // Clear input file values, This ensures that the onChange event 
      // will be triggered if the same file is selected again.
      const element = document.getElementById(this.inputFileId) as HTMLInputElement;
      element.value = ''
      
      return isFileUploaded;
    } 
    //file cannot be validated/uploaded as onFileSelected 
    //callback was not specified
    return false;
  }

  /**
   * once user confirms in modal proceed with delete
   */
  @action
  async fileDelete(file: FileDescription) {
    set(this, 'isDeleting', true);
    await this.onFileDeleted?.(file.id);
    set(this, 'isDeleting', false);
  }

  @action
  fileLinkClick(id: string) {
    this.onFileLinkClicked?.(id);
  }
}
