import type RSVP from 'rsvp';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { addObserver } from '@ember/object/observers';
import { scheduleOnce } from '@ember/runloop';
import { layout } from '@ember-decorators/component';
import template from './template';

type LayoutContent = {
  header:     string | RSVP.PromiseState<string>,
  navigation: string | RSVP.PromiseState<string>,
  footer:     string | RSVP.PromiseState<string>
}

declare global {
  interface Window {
    dcsMultiTrack?: (...args: unknown[]) => void;
  }
}

/**
 * Provide an empty `dcsMultiTrack` WebTrends function if one doesn't exist.
 */
function polyfillWebTrendsTrackingFn() {
  if (typeof window.dcsMultiTrack !== 'function') {
    window.dcsMultiTrack = function stubbedDcsMultiTrack() { /* noop */ };
  }
}

/**
 * Find the HTML in what's provided for the header, nav, nav footer.
 */
function extractStringContent(content?: string | RSVP.PromiseState<string>): string {
  if (content) {
    if (typeof content === 'string') {
      return content;
    }
    else if (content.state === 'rejected') {
      throw content.reason;
    }
    else if (content.state === 'pending') {
      return '';
    }

    return content.value;
  }

  return '';
}

/**
 * Create an HTML fragment from a raw string.
 */
function makeHTMLElement(string: string) {
  const div = document.createElement('div');
  div.innerHTML = string;

  return div;
}

/**
 * Adds a class name to the queried element.
 */
function addClassName(element: HTMLElement, querySelector: string, name: string) {
  element.querySelector(querySelector)?.classList.add(name);
}

/**
 * The RGovLayout component is responsible for rendering HTML content from the semantic content
 * service.
 */
@layout(template)
// eslint-disable-next-line ember/require-tagless-components
export default class RGovLayout extends Component {
  public static readonly positionalParams = ['content', 'user'];

  /**
   * A object that will be used to populate the header welcome message. At minimum, this must
   * be an object with both `firstName` and `lastName` properties.
   */
  public user?: { firstName: string, lastName: string };

  /**
   * An object that contains header, navigation, and footer properties. Each can either be
   * a string or a PromiseState object such as what is returned from the Semantic Content
   * Data service's getAll.
   */
  public content?: LayoutContent;

  /** If true, then header content will be rendered. This is only a visual utility. */
  public showHeader = true;

  /** If true, then navigation content will be rendered. This is only a visual utility. */
  public showNavigation = true;

  /** If true, then footer content will be rendered. This is only a visual utility. */
  public showFooter = true;

  /**
   * If provided, this will be attached as a click listener to the login hyperlink
   * provided by the Semantic Content service.
   */
  public onLoginClick?: (event: Event) => void;

  /**
   * If provided, this will be attached as a click listener to the logout hyperlink
   * provided by the Semantic Content service.
   */
  public onLogoutClick?: (event: Event) => void;

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init();
    // @ts-expect-error - failing on property expansion
    // eslint-disable-next-line ember/no-observers
    addObserver(this, 'user.{firstName,lastName}', this.scheduleWelcomeMessageUpdate);
    polyfillWebTrendsTrackingFn();
  }

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didInsertElement() {
    super.didInsertElement();
    document.getElementById('loading')?.classList.add('complete');
  }

  /**
   * @private
   */
  @computed('content.{header,header.state}')
  get headerContent() {
    try {
      const content = extractStringContent(this.content?.header);
      const html    = makeHTMLElement(content);

      this.updateWelcomeMessage(html);

      return html.innerHTML;
    } catch (e) {
      // Log Exception
    }

    // A minimal fill in for a header which just contains the logo. Better than nothing.
    return '<div><div id="masthead"><a class="logo" aria-label="research.gov logo" href="#"></a></a></div></div>';
  }

  /**
   * @private
   */
  @computed('content.{navigation,navigation.state}')
  get navigationContent() {
    try {
      return extractStringContent(this.content?.navigation);
    } catch (e) {
      // Log Exception
    }

    return '';
  }

  /**
   * @private
   */
  @computed('content.{footer,footer.state}')
  get footerContent() {
    try {
      const content = extractStringContent(this.content?.footer);
      const html    = makeHTMLElement(content);

      addClassName(html, '#footer .content', 'container');

      return html.innerHTML;
    } catch (e) {
      // Log Exception
    }

    return '';
  }

  /**
   * @private
   */
  scheduleWelcomeMessageUpdate() {
    const element = this.element.querySelector('header.rgov-asset-header');

    if (element) {
      scheduleOnce('afterRender', this, this.updateWelcomeMessage, element);
    }
  }

  /**
   * @private
   */
  updateWelcomeMessage(element: Element) {
    const updateNode = element.querySelector('#welcome a');

    if (updateNode) {
      updateNode.textContent = this.user
        ? `Welcome ${this.user.firstName} ${this.user.lastName}`
        : 'Welcome';
    }
  }

  /**
   * @private
   */
  @action
  handleHeaderClickEvents(event: Event) {
    if (event.target instanceof HTMLAnchorElement) {
      if (event.target.textContent?.match(/(Log|Sign)\s?In/i)) {
        this.onLoginClick?.(event);
      }
      else if (event.target.textContent?.match(/(Log|Sign)\s?Out/i)) {
        this.onLogoutClick?.(event);
      }
    }
  }
}
