define("@nsf-open/ember-general-utils/utils/objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasProperty = hasProperty;
  _exports.isObject = isObject;
  _exports.isPrimitive = isPrimitive;
  _exports.isPromiseLike = isPromiseLike;
  const PrimitiveValues = ['string', 'number', 'boolean', 'symbol', 'undefined', 'bigint'];

  /**
   * Javascript primitive types.
   * Also see: https://developer.mozilla.org/en-US/docs/Glossary/Primitive
   */

  /**
   * Tests whether the argument is a _plain old javascript object_ (POJO) or class instance.
   */
  function isObject(value) {
    return Object.prototype.toString.call(value) === '[object Object]';
  }

  /**
   * Tests whether an enumerable property with the provided name exists on the given object.
   */
  function hasProperty(obj, val) {
    return !!obj && Object.prototype.hasOwnProperty.call(obj, val);
  }

  /**
   * Tests whether the argument is one of Javascript's primitive types.
   */
  function isPrimitive(value) {
    return value === null || PrimitiveValues.includes(typeof value);
  }

  /**
   * Tests whether the argument is "then-able", e.g. it has a `then()` method.
   */
  function isPromiseLike(obj) {
    return obj === undefined || obj === null ? false : typeof obj.then === 'function';
  }
});