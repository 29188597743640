define("@nsf-open/ember-general-utils/utils/regex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.escapeRegExp = escapeRegExp;
  /**
   * Makes a string safe for use inside a regular expression by escaping regex special characters.
   */
  function escapeRegExp(string) {
    if (typeof string === 'string') {
      // $& means the whole matched string
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    return string;
  }
});