define("@nsf/ember-oauth-iam/utils/timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * The Timer class does exactly what is on the tin. A timer is initiated by providing it a `resolution`,
   * e.g. the rate at which the timer will tick. Callback methods can be provided which will execute with
   * every tick, or on a given number of ticks.
   *
   * This class should be used with the knowledge that timing events in JavaScript are not an exact science
   * (due to its single threaded nature). For instance, `setTimeout(cb, 1000)` will execute `cb` after 1000
   * milliseconds - on average. There are many factors which may cause it to execute a little behind schedule.
   * This class tries to correct for this over multiple ticks by comparing the time that it should have ticked
   * with the time it actually did. So, in the example above, if `cb` were actually executed after 1100
   * milliseconds, the next tick will be re-calibrated to occur after only 900 milliseconds. The result is an
   * average tick interval of 1000 milliseconds.
   */
  class Timer {
    /**
     * Returns the current UNIX timestamp.
     *
     * @param [resolution=Timer.second] Convert the timestamp from milliseconds to some other unit.
     */
    static now() {
      let resolution = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Timer.second;
      return Math.floor((Date.now ? Date.now() : new Date().getTime()) / resolution);
    }

    /** The speed at which this timer will "tick", in milliseconds. */

    /**
     * @constructor
     *
     * @param {number} [resolution=Timer.second] The speed at which this
     * timer will "tick", in milliseconds.
     */
    constructor() {
      let resolution = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Timer.second;
      _defineProperty(this, "resolution", void 0);
      /** The number of times that the timer has "ticked" since it started. */
      _defineProperty(this, "ticks", void 0);
      /** The ID of the timeout for the next timer tick that can be used to cancel it. */
      _defineProperty(this, "timeoutId", void 0);
      /** A Unix timestamp of the expected time for the next tick to occur. */
      _defineProperty(this, "expected", void 0);
      /**
       * A running counter that is incremented each time a callback is added so that
       * each callback can have a unique identifier.
       */
      _defineProperty(this, "nextCallbackId", 1);
      /**
       * An array that contains the information needed to run one-time callback functions.
       */
      _defineProperty(this, "oneTimeCallbacks", []);
      /**
       * An array that contains the information needed to run callback functions that are
       * executed with each tick of the timer.
       */
      _defineProperty(this, "onEveryCallbacks", []);
      this.resolution = resolution;
      this.ticks = 0;
    }
    /** Starts the timer. */
    start() {
      if (!this.timeoutId) {
        this.expected = Timer.now(Timer.millisecond) + this.resolution;
        this.startNewTimeout(this.resolution);
      }
      return this;
    }

    /**
     * Stops the running timer.
     *
     * @param reset If true, the timer's tick count will be reset.
     */
    stop() {
      let reset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = undefined;
      }
      if (reset) {
        this.reset();
      }
      return this;
    }

    /** Reset the timer's tick counter to zero. */
    reset() {
      this.ticks = 0;
      return this;
    }

    /** Stop, reset, and start the timer. */
    restart() {
      this.stop(true);
      this.start();
      return this;
    }

    /**
     * Add a callback method which will be executed when the timer reaches a certain count.
     *
     * @param scope     The context that the callback will run in.
     * @param callback  The callback method to be executed.
     * @param tickCount The tick count that the method will be executed at.
     *
     * @returns A unique ID which can be used to remove the callback.
     */
    at(scope, callback, tickCount) {
      const callbackId = this.nextCallbackId.toString();
      this.nextCallbackId += 1;
      this.oneTimeCallbacks.push([callbackId, false, tickCount, callback, scope]);
      return callbackId;
    }

    /**
     * Add a callback method which will be executed on each timer tick.
     *
     * @param scope     The context that the callback will run in.
     * @param callback  The callback method to be executed.
     *
     * @returns A unique ID which can be used to remove the callback.
     */
    every(scope, callback) {
      const callbackId = this.nextCallbackId.toString();
      this.nextCallbackId += 1;
      this.onEveryCallbacks.push([callbackId, callback, scope]);
      return callbackId;
    }

    /**
     * Remove a callback that was set with `at()` or `every()`.
     *
     * @param id The unique ID of the callback provided when it was created.
     */
    remove(id) {
      this.removeFromCallbackArray(this.oneTimeCallbacks, id) || this.removeFromCallbackArray(this.onEveryCallbacks, id);
      return this;
    }
    timerTickCallback() {
      const drift = Timer.now(Timer.millisecond) - this.expected;
      this.ticks += 1;
      this.onEveryCallbacks.forEach(item => {
        item[1].call(item[2], this.ticks);
      });
      this.oneTimeCallbacks.forEach(item => {
        if (!item[1] && item[2] <= this.ticks) {
          item[1] = true;
          item[3].call(item[4]);
        }
      });
      this.expected += this.resolution;
      this.startNewTimeout(Math.max(0, this.resolution - drift));
    }
    startNewTimeout(interval) {
      this.timeoutId = window.setTimeout(this.timerTickCallback.bind(this), interval);
    }
    removeFromCallbackArray(array, id) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[i][0] === id) {
          array.splice(i, 1);
          return true;
        }
      }
      return false;
    }
  }
  _exports.default = Timer;
  _defineProperty(Timer, "millisecond", 1);
  _defineProperty(Timer, "second", 1000);
  _defineProperty(Timer, "minute", 60000);
  _defineProperty(Timer, "hour", 3600000);
});