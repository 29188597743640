export * from '@nsf-open/ember-ui-foundation/constants';

/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const FileMimeTypes = {
    PDF: ['application/pdf'],
    MS_WORD: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    MS_EXCEL: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    TEXT: ['text/plain'],
    IMAGE: ['image/jpeg', 'image/png'],
}

export enum FileIconClassNames {
    PDF = 'file-pdf-o',
    MS_WORD = 'file-word-o',
    MS_EXCEL = 'file-excel-o',
    TEXT = 'file-text-o',
    IMAGE = 'file-image-o',
    DEFAULT = 'file',
}

export type FileDescription = {
    id: string;
    name: string;
    type: string;
    size?: number;
    iconClass?: string;
    url?: string;
}

export const ErrorMessages = {
    somethingUnexpected: 'We apologize for the inconvenience as an unexpected error occurred. Please try again, and if you still experience difficulties, contact the NSF IT Service Desk at Rgov@nsf.gov or 1 (800) 381-1532.',
};
