define("@nsf/ember-oauth-iam/initializers/oauth-iam", ["exports", "ember", "@nsf/ember-oauth-iam/internal-session", "@nsf/ember-oauth-iam/utils/typed-ember-simple-auth-reexports", "@nsf/ember-oauth-iam/configuration"], function (_exports, _ember, _internalSession, _typedEmberSimpleAuthReexports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /**
   * Puts together the configuration singleton for the running application, and
   * registers various class factories with the DI system.
   */
  function initialize(application) {
    // Configuration setup
    const env = application.resolveRegistration('config:environment');
    (0, _configuration.initializeConfiguration)(Object.assign({
      rootURL: env.rootURL,
      environment: env.environment
    }, env['ember-oauth-iam'] || {}));

    // This overwrites what SimpleAuth provides as session:main.
    application.register('session:main', _internalSession.default);

    // SimpleAuth forces the use of their "Ephemeral" data store when testing, which
    // is pretty darn annoying IMO (and took a long time to debug on).
    if (_ember.default.testing) {
      application.register('session-store:test', _typedEmberSimpleAuthReexports.CookieStore);
    }
  }
  var _default = _exports.default = {
    initialize,
    name: 'oauth-iam',
    after: 'ember-simple-auth',
    // @ts-expect-error - If ember-cli-mirage is not installed in the parent app, declaring
    // it as a requirement of the DAG would cause an exception.
    before: requirejs.entries['ember-cli-mirage/index'] ? 'ember-cli-mirage' : undefined
  };
});