define("@nsf/ember-properties-loader/configuration", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getConfig = getConfig;
  _exports.initializeConfiguration = initializeConfiguration;
  _exports.resetConfig = resetConfig;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const Defaults = {
    rootURL: '/',
    environment: '',
    domain: '',
    namespace: '/properties/',
    beforeEnvMap: [],
    environmentMap: [{
      name: 'local.json',
      hosts: ['localhost', '127.0.0.1']
    }],
    afterEnvMap: [],
    noCache: false
  };
  let singleton;

  /**
   * To be called once by the application initializer to bake in the
   * configuration defaults.
   */
  function initializeConfiguration() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    Object.assign(Defaults, options);
  }

  /**
   * Retrieves the singleton configuration instance.
   */
  function getConfig(config) {
    if (!singleton) {
      singleton = new PropertiesLoaderConfiguration(Defaults);
    }
    if (config) {
      singleton.update(config);
    }
    return singleton;
  }

  /**
   * Deletes the singleton configuration instance, if it exists. A new one will
   * be created with the next call to `getConfig`.
   */
  function resetConfig() {
    singleton = undefined;
  }

  /**
   * Configurable options for ember-properties-service.
   */
  class PropertiesLoaderConfiguration {
    constructor(values) {
      /** The rootURL as defined in the app config. */
      _defineProperty(this, "rootURL", Defaults.rootURL);
      /** The environment as defined in the app config or command argument. */
      _defineProperty(this, "environment", Defaults.environment);
      /** The default domain name to prefix calls with. */
      _defineProperty(this, "domain", Defaults.domain);
      /** The default URL path to prefix calls with. */
      _defineProperty(this, "namespace", Defaults.namespace);
      /**
       * An array of config file definitions that will be loaded before the
       * environment specific file is.
       */
      _defineProperty(this, "beforeEnvMap", Defaults.beforeEnvMap);
      /**
       * An array of possible config file definitions. One will be selected by
       * comparing the contents of its `hosts` array to the current hostname as
       * reported by the browser. If none is found, the last definition in the
       * array will be used.
       */
      _defineProperty(this, "environmentMap", Defaults.environmentMap);
      /**
       * An array of config file definitions that will be loaded after the
       * environment specific file is.
       */
      _defineProperty(this, "afterEnvMap", Defaults.afterEnvMap);
      /**
       * Boolean to control whether files will have cache busting
       * true has cache busting
       * false does not have cache busting
       */
      _defineProperty(this, "noCache", Defaults.noCache);
      if (values) {
        this.update(values);
      }
    }
    /**
     * Updates the current configuration.
     */
    update(values) {
      const keys = Object.keys(values);
      // @ts-expect-error - TS interprets `values[key]` as the union of all possible Configuration types
      keys.forEach(key => (0, _object.set)(this, key, values[key]));
    }
  }
});