import Helper from '@ember/component/helper';

/**
 * Format numbers as dollar amounts.
 * 
 * ```handlebars
 *     {{format-whole-dollar 5768}}
 * ```
 * renders 
 *  $5,768
 * 
 * ```handlebars
 *     {{format-whole-dollar 12000}}
 * ```
 * renders 
 *  $12,000
 * 
 * ```handlebars
 *     {{format-whole-dollar 175000}}
 * ```
 * renders 
 *  $175,000
 *  
 */
  export default class FormatWholeDolar extends Helper {
    /**
     * @protected
     */
    compute([amountToFormat]: [number | string]) {
        // Convert string to number if needed
        const numericAmount = typeof amountToFormat === 'string' ? parseFloat(amountToFormat) : amountToFormat;

        // Check if the numericAmount is NaN or null
        if (isNaN(numericAmount) || numericAmount === null) {
          return '';
        }
        
        // Use Intl.NumberFormat to format the numericAmount as a currency in USD
        const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // Set minimumFractionDigits based on whether there are cents or not
        minimumFractionDigits: numericAmount % 1 === 0 ? 0 : 2,
        }).format(numericAmount);
    
        return formattedAmount;
    }
  }