define("@nsf/ember-webtrend-analytics/instance-initializers/webtrends-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initialize;
  function initialize(instance) {
    const analytics = instance.lookup('service:webtrend-analytics');
    if (analytics) {
      instance.register('service:webtrends', analytics, {
        singleton: true,
        instantiate: false
      });
      const config = instance.resolveRegistration('config:environment') || {};
      const addonConfig = config['ember-webtrend-analytics'] || {};
      analytics.rootURL = config.rootURL;
      analytics.enabled = addonConfig.enabled !== false;
      if (addonConfig.initializeOnStartup) {
        analytics.start(addonConfig.config);
      }
    }
  }
});