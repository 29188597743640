define("@nsf/ember-webtrend-analytics/utils/enhanced-tracking-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getElementDetails = getElementDetails;
  const LandmarkRoles = ['banner', 'navigation', 'main', 'complementary', 'contentinfo', 'search', 'form', 'application'];
  const ImplicitRoleTags = ['header', 'nav', 'main', 'aside', 'footer', 'form', 'section'];
  const LandmarkRoleSelector = LandmarkRoles.map(role => `[role="${role}"]`).join(',');
  const ImplicitRoleTagSelector = ImplicitRoleTags.join(',');
  const FullRoleSelector = `${LandmarkRoleSelector},${ImplicitRoleTagSelector}`;

  /**
   * Builds a super simple query selector containing the element tag and
   * role attribute, if available.
   */
  function buildSelector(element) {
    const tagName = element.tagName.toLowerCase();
    const role = element.getAttribute('role');
    return role ? `${tagName}[role="${role}"]` : tagName;
  }

  /**
   * Builds a query selector for an element that uniquely identifies it within
   * the context of another element.
   */
  function buildSelectorWithin(element, ancestor) {
    const selector = buildSelector(element);
    const allMatches = ancestor.querySelectorAll(selector);
    if (allMatches && allMatches.length > 1) {
      const indexOfElement = Array.from(allMatches).indexOf(element);
      return `${selector}:nth-child(${indexOfElement + 1})`;
    }
    return selector;
  }

  /**
   * Puts together some information about an element such as relevant text content and a
   * half-decent attempt at a query selector that points to it.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles#3._landmark_roles
   */
  function getElementDetails(element) {
    let parent = element.closest(FullRoleSelector);
    let selector = buildSelectorWithin(element, parent ?? document.body);
    while (parent) {
      const nextParent = parent.parentElement?.closest(FullRoleSelector);
      const nextSelector = buildSelectorWithin(parent, nextParent ?? document.body);
      selector = `${nextSelector} ${selector}`;
      parent = nextParent ?? null;
    }
    return {
      text: element.textContent || element.ariaLabel,
      query: selector
    };
  }
});