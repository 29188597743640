define("@nsf/ember-oauth-iam/services/iam-data-service", ["exports", "@ember/service", "@nsf/ember-oauth-iam/configuration", "fetch", "@nsf/ember-oauth-iam/errors"], function (_exports, _service, _configuration, _fetch, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The IamDataService can communicate with IAM OAuth and SAML endpoints to gather information
   * about the current user session.
   */
  class IamDataService extends _service.default {
    /**
     * Makes a call to the identity provider for user information given the current
     * access token.
     */
    async getUserInfo(token) {
      const user = await this.makeFetchRequest((0, _configuration.getConfig)().userInfoURL, token);
      return {
        id: user.sub,
        fullName: `${user.given_name} ${user.family_name}`,
        firstName: user.given_name,
        lastName: user.family_name,
        email: user.email,
        incommon: user.incommon === true
      };
    }

    /**
     * Makes a call to validate the supplied access token.
     */
    async validateToken(token) {
      return await this.makeFetchRequest((0, _configuration.getConfig)().validateURL, token);
    }

    /**
     * Checks with IAM for a current user session.
     */
    async checkForSession() {
      return await this.querySessionAPI('_action=isActive');
    }

    /**
     * Checks the current idle time of an IAM session.
     */
    async getIdleTime() {
      return await this.querySessionAPI('_action=getIdle').then(user => user.idleTime);
    }

    /**
     * Resets the idle time of an IAM session.
     */
    async resetIdleTime() {
      return await this.querySessionAPI('_action=isActive&refresh=true').then(user => user.idleTime);
    }

    /**
     * Makes a GET request to the session info endpoint.
     */
    async querySessionAPI() {
      let query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      const response = await (0, _fetch.default)((0, _configuration.getConfig)().sessionInfoURL + '?' + query, this.getFetchOptions(undefined, {
        credentials: 'include'
      }));
      if (response.status >= 200 && response.status < 300) {
        const content = await response.json();
        const user = content.SessionResponseWrapper?.user || {};
        const code = content.SessionResponseWrapper?.statusCode || 404;
        if (code >= 400) {
          const error = new _errors.FetchError(response);
          error.status = code;
          throw error;
        }
        return user;
      }
      throw new _errors.FetchError(response);
    }
    async makeFetchRequest(url, token) {
      const response = await (0, _fetch.default)(url, this.getFetchOptions(token));
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
      throw new _errors.FetchError(response);
    }
    getFetchOptions(token, additionalOptions) {
      const config = {
        cache: 'reload',
        method: 'GET'
      };
      if (typeof token === 'string') {
        config.headers = {
          Authorization: `bearer ${token}`
        };
      }
      return additionalOptions ? Object.assign(config, additionalOptions) : config;
    }
  }
  _exports.default = IamDataService;
});