// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import template from './template';
import { layout, tagName } from '@ember-decorators/component';
import { set } from '@ember/object';

// eslint-disable-next-line ember/require-tagless-components
@tagName('')
@layout(template)
export default class RgovStickyButtons extends Component {

  observer!: IntersectionObserver;

  stickyMenu!: HTMLElement;

  buttonHarbor!: HTMLElement;

  stickyBackgroundHeight!: number;

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  didInsertElement() {
    super.didInsertElement();
    const stickyMenu = document.querySelector('.sticky') as HTMLElement;
    set(this, 'stickyMenu', stickyMenu);
    
    const buttonHarbor = stickyMenu.querySelector('#button-harbor') as HTMLElement;
    set(this, 'buttonHarbor', buttonHarbor);
    
    const stickyBackgroundHeight = Math.ceil(buttonHarbor?.clientHeight as number) + 1.5*stickyMenu.clientHeight as number;

    set(this, 'stickyBackgroundHeight', stickyBackgroundHeight);

    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.boundingClientRect.top > 0) {
          this.setButtonsInPage();
        } else if (e.intersectionRatio < 1) {
          this.makeButtonsSticky();
        } else {
          this.setButtonsInPage();
        }
      },
      {
        threshold: [1],
      }
    );

    set(this, 'observer', observer);
    observer.observe(stickyMenu);
  }

  makeButtonsSticky() {
    const { stickyBackgroundHeight, stickyMenu, buttonHarbor }  = this;
    stickyMenu.classList.add('sticky-background');
    stickyMenu.setAttribute('style', `height: ${ stickyBackgroundHeight }px`);
    buttonHarbor?.classList.add('scrolling');
  }

  setButtonsInPage() {
    const { stickyMenu, buttonHarbor }  = this;
    stickyMenu.classList.remove('sticky-background');
    stickyMenu.removeAttribute('style');
    buttonHarbor?.classList.remove('scrolling');
  }

  // eslint-disable-next-line ember/no-component-lifecycle-hooks
  willDestroyElement() {
    this.observer.unobserve(this.stickyMenu);
    super.willDestroyElement();
  }
}
