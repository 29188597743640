define("@nsf-open/ember-datetime-utils/computed-macros", ["exports", "@ember/object", "@nsf-open/ember-datetime-utils/formatters"], function (_exports, _object, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bigEndianDateString = bigEndianDateString;
  _exports.middleEndianDateString = middleEndianDateString;
  _exports.middleEndianDateTimeString = middleEndianDateTimeString;
  _exports.middleEndianTimeString = middleEndianTimeString;
  /**
   * Exposes the `toMiddleEndianDateString` formatter as an Ember computed decorator to format
   * a date-like value as "MONTH/DAY/YEAR".
   *
   * ```javascript
   * public dateValue: new Date(1970, 9, 25, 13, 10, 50);
   *
   * @middleEndianDateString('dateValue');
   * public formattedDate!: string; // => '10/25/1970'
   * ```
   */
  function middleEndianDateString(dependantKey) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return (0, _object.computed)(dependantKey, function computedMiddleEndianDateString() {
      return (0, _formatters.toMiddleEndianDateString)((0, _object.get)(this, dependantKey), defaultValue, options);
    });
  }

  /**
   * Exposes the `middleEndianTimeString` formatter as an Ember computed decorator to format
   * a date-like value as "HOUR:MINUTE MERIDIEM".
   *
   * ```javascript
   * public dateValue: new Date(1970, 9, 25, 13, 10, 50);
   *
   * @middleEndianTimeString('dateValue');
   * public formattedTime!: string; // => '1:10 PM'
   * ```
   */
  function middleEndianTimeString(dependantKey) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return (0, _object.computed)(dependantKey, function computedMiddleEndianTimeString() {
      return (0, _formatters.toMiddleEndianTimeString)((0, _object.get)(this, dependantKey), defaultValue, options);
    });
  }

  /**
   * Exposes the `middleEndianDateTimeString` formatter as an Ember computed decorator to format
   * a date-like value as "MONTH/DAY/YEAR HOUR:MINUTE MERIDIEM".
   *
   * ```javascript
   * public dateValue: new Date(1970, 9, 25, 13, 10, 50);
   *
   * @middleEndianDateTimeString('dateValue');
   * public formattedDateTime!: string; // => '10/25/1970 1:10 PM'
   * ```
   */
  function middleEndianDateTimeString(dependantKey) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return (0, _object.computed)(dependantKey, function computedMiddleEndianDateTimeString() {
      return (0, _formatters.toMiddleEndianDateTimeString)((0, _object.get)(this, dependantKey), defaultValue, options);
    });
  }

  /**
   * Exposes the `bigEndianDateString` formatter as an Ember computed decorator to format
   * a date-like value as "YEAR-MONTH-DAY".
   *
   * ```javascript
   * public dateValue: new Date(1970, 9, 25, 13, 10, 50);
   *
   * @bigEndianDateString('dateValue');
   * public formattedDate!: string; // => '1970-10-25'
   * ```
   */
  function bigEndianDateString(dependantKey) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return (0, _object.computed)(dependantKey, function computedBigEndianDateString() {
      return (0, _formatters.toBigEndianDateString)((0, _object.get)(this, dependantKey), defaultValue, options);
    });
  }
});