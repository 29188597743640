define("@nsf/ember-oauth-iam/configuration", ["exports", "@ember/object", "@ember/utils", "@nsf/ember-oauth-iam/utils/path"], function (_exports, _object, _utils, _path) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getConfig = getConfig;
  _exports.initializeConfiguration = initializeConfiguration;
  _exports.resetConfig = resetConfig;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const Defaults = {
    noMocks: false,
    pendingExpirationWarning: [900, 300],
    // 900 seconds = 15 minutes, 300 seconds = 5 minutes
    pendingInactivityWarning: [300],
    // 300 seconds: 5 minutes
    inactivityTimeout: 7200,
    // 7200 seconds: 2 hours
    activityEvents: ['click', 'keyup'],
    authenticationFailedRoute: 'index',
    routeAfterAuthentication: 'index',
    routeIfAlreadyAuthenticated: 'index',
    clientId: '',
    scope: 'profile',
    domain: null,
    logoutDomain: null,
    enableHtmlMocks: false,
    expandCookieDomain: 1,
    cookieDomain: '',
    cookieName: 'NSFUserSession',
    rootURL: '/',
    includeDomainInReturnToValue: false,
    overrideProviderRedirectUri: null,
    environment: 'development',
    logoutPath: '/research-portal/appmanager/base/desktop?_nfpb=true&_pageLabel=research_home_page&logout=1',
    revokePath: '/sso/oauth2/token/revoke',
    validatePath: '/sso/oauth2/tokeninfo',
    userInfoPath: '/sso/oauth2/userinfo',
    responseType: 'token',
    timerInterval: 1,
    // 1 second
    callbackPath: '/iam/auth-response.html',
    providerPath: '/sso/oauth2/authorize',
    sessionInfoPath: '/sso/sessions',
    mockProviderPath: '/iam/mocks/identity-provider.html',
    mockLogoutPath: '/iam/mocks/logout.html',
    authenticatorName: 'authenticator:iam'
  };

  /**
   * Configuration.
   */

  /**
   * To be called once by the application initializer to bake in the configuration defaults.
   */
  function initializeConfiguration() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    Object.assign(Defaults, options);
  }
  let singleton;

  /**
   * Retrieves the singleton configuration instance.
   */
  function getConfig(values) {
    if (!singleton) {
      singleton = new OAuthIamConfiguration(Defaults);
    }
    if (values) {
      singleton.update(values);
    }
    return singleton;
  }

  /**
   * Deletes the singleton configuration instance, if it exists. A new one will
   * be created with the next call to `getConfig`.
   */
  function resetConfig() {
    singleton = undefined;
  }

  /**
   * Ember OAuth IAM configuration.
   */
  let OAuthIamConfiguration = _exports.default = (_dec = (0, _object.computed)('enableHtmlMocks', 'providerPath', 'mockProviderPath'), _dec2 = (0, _object.computed)('enableHtmlMocks', 'logoutDomain', 'logoutPath', 'mockLogoutPath'), _dec3 = (0, _object.computed)('domain', 'userInfoPath'), _dec4 = (0, _object.computed)('domain', 'validatePath'), _dec5 = (0, _object.computed)('domain', 'revokePath'), _dec6 = (0, _object.computed)('domain', 'sessionInfoPath'), _dec7 = (0, _object.computed)('rootURL', 'callbackPath'), (_class = class OAuthIamConfiguration {
    constructor(values) {
      /** Force the disabling of the IAM mock views and API endpoints. */
      _defineProperty(this, "noMocks", Defaults.noMocks);
      /**
       * The number of seconds before the token expires that the session should emit a
       * `pendingTokenExpiration` event.
       */
      _defineProperty(this, "pendingExpirationWarning", Defaults.pendingExpirationWarning);
      /**
       * The number of seconds before the allowed inactivity maximum that the session should
       * emit a `pendingInactivityExpiration` event.
       */
      _defineProperty(this, "pendingInactivityWarning", Defaults.pendingInactivityWarning);
      /**
       * The number of seconds that an authenticated user is allowed to NOT interact with
       * the application before the session de-authenticates them.
       */
      _defineProperty(this, "inactivityTimeout", Defaults.inactivityTimeout);
      /**
       * An array of event names which qualify as "activity events". Listeners will
       * be attached to the document body, and when triggered the inactivity timer will
       * be reset.
       */
      _defineProperty(this, "activityEvents", Defaults.activityEvents);
      /**
       * The route that the application transitions to if the user has made it all the way through
       * IAM and back to the application, but then something gets borked while finalizing their
       * session setup in the application (its pretty specific). This will most likely happen from
       * a failure to successfully hit OAuth's userinfo endpoint (the final token validity check).
       *
       * It's really important that this route NOT itself be authenticated-only... you know, because
       * its getting transitioned to only if the authentication failed. The authenticator tries to
       * keep crazy endless redirect loops from happening, but its ultimately a team effort.
       */
      _defineProperty(this, "authenticationFailedRoute", Defaults.authenticationFailedRoute);
      /**
       * The route that the application transitions to after the authentication workflow has
       * completed. This is only used if a URL could not be generated from the captured
       * `lastTransitionAttempt`. The user has to go somewhere!
       */
      _defineProperty(this, "routeAfterAuthentication", Defaults.routeAfterAuthentication);
      /**
       * The route that the application transitions to if authenticated but trying to access
       * an unauthenticated-only view.
       */
      _defineProperty(this, "routeIfAlreadyAuthenticated", Defaults.routeIfAlreadyAuthenticated);
      /** The application's OAuth client_id. */
      _defineProperty(this, "clientId", Defaults.clientId);
      /** A list of scopes to send with the OAuth request. */
      _defineProperty(this, "scope", Defaults.scope);
      /** The domain name of the OAuth identity provider to use. You're definitely going to need to set this one. */
      _defineProperty(this, "domain", Defaults.domain);
      /** The domain name of the logout route that the user will be directed to after their session is invalidated. */
      _defineProperty(this, "logoutDomain", Defaults.logoutDomain);
      /** If true, then mock IAM "login" and "logout" pages will be used. */
      _defineProperty(this, "enableHtmlMocks", Defaults.enableHtmlMocks);
      /**
       * Describes the number of parent domains that the cookie will be made accessible to. For example:
        *
        * Given: `bar.baz.com`     and `expandCookieDomain = 1` then cookie domain `domain=baz.com`
        * Given: `foo.bar.baz.com` and `expandCookieDomain = 2` then cookie domain `domain=baz.com`
        * Given: `foo.bar.baz.com` and `expandCookieDomain = 1` then cookie domain `domain=bar.baz.com`
        *
        * The cookie domain will never be allowed to expand beyond the top-level domain - `baz.com` in
        * the examples above - and this behavior will be ignored altogether if an explicit `cookieDomain`
        * configuration value is provided.
       */
      _defineProperty(this, "expandCookieDomain", Defaults.expandCookieDomain);
      /**
        * The domain of the cookie. The default "" assigns the cookie to the host its created on.
        */
      _defineProperty(this, "cookieDomain", Defaults.cookieDomain);
      /** The name of the cookie that stores session info. */
      _defineProperty(this, "cookieName", Defaults.cookieName);
      /** The root URL of the application, as set in the configuration. */
      _defineProperty(this, "rootURL", Defaults.rootURL);
      /**
       * Whether to include the domain name in the "return to" URL that will attempt to be loaded
       * by the auth response page (the authentication flow goes app -> oauth provider ->
       * auth response -> the URL being discussed here).
       *
       * This is a non-standard feature that typically isn't required, but comes in handy for
       * certain complex authentication flows.
       *
       * @see overrideProviderRedirectUri
       */
      _defineProperty(this, "includeDomainInReturnToValue", Defaults.includeDomainInReturnToValue);
      /**
       * A completely custom URI that will be provided to the identity provider as the expected redirect_uri.
       * Remember that this value must already be configured with the provider in order for it to work.
       *
       * This is a non-standard feature that typically isn't required. It's useful in scenarios where the
       * need for numerous application deploys would be too cumbersome to manage if each had to be individually
       * registered with the I.D. Using a combination of this property and `includeDomainInReturnToValue`, some
       * logic sitting at a lone redirect_uri could play traffic marshall.
       *
       * @see includeDomainInReturnToValue
       */
      _defineProperty(this, "overrideProviderRedirectUri", Defaults.overrideProviderRedirectUri);
      /** The current environment name, as set in the configuration or at build time/server start. */
      _defineProperty(this, "environment", Defaults.environment);
      /**
       * The root relative path to the IAM logout page for ending the server-side session. Prefix with `domain`
       * to get the full path.
       */
      _defineProperty(this, "logoutPath", Defaults.logoutPath);
      /** The root relative path to the token revocation API endpoint. Prefix with `domain` to get the full path. */
      _defineProperty(this, "revokePath", Defaults.revokePath);
      /** The root relative path to the token validation API endpoint. Prefix with `domain` to get the full path. */
      _defineProperty(this, "validatePath", Defaults.validatePath);
      /** The root relative path to the user information API endpoint. Prefix with `domain` to get the full path. */
      _defineProperty(this, "userInfoPath", Defaults.userInfoPath);
      /** The value of the response_type query parameter sent to IAM at the start of the authorization workflow. */
      _defineProperty(this, "responseType", Defaults.responseType);
      /**
       * The number of seconds between timer "ticks" in the session's internal timer used to check the status of
       * token expiration, inactivity, and other important things.
       */
      _defineProperty(this, "timerInterval", Defaults.timerInterval);
      _defineProperty(this, "callbackPath", Defaults.callbackPath);
      _defineProperty(this, "providerPath", Defaults.providerPath);
      _defineProperty(this, "sessionInfoPath", Defaults.sessionInfoPath);
      _defineProperty(this, "mockProviderPath", Defaults.mockProviderPath);
      _defineProperty(this, "mockLogoutPath", Defaults.mockLogoutPath);
      _defineProperty(this, "authenticatorName", Defaults.authenticatorName);
      if (values) {
        this.update(values);
      }
    }

    /**
     * Available when using the mocked endpoints, this is the same of the cookie that mock session
     * data will be stored in.
     */

    /** True if PretenderJs is presently enabled to intercept requests. */
    get httpInterceptorLoaded() {
      // @ts-expect-error
      return !!(window.FakeXMLHttpRequest
      // @ts-expect-error - FakeXMLHttpRequest is not formally defined on the window global
      && Object.getPrototypeOf(window.XMLHttpRequest.prototype) === window.FakeXMLHttpRequest.prototype);
    }
    get loginURL() {
      return this.enableHtmlMocks ? _path.default.join(this.rootURL, this.mockProviderPath) : this.addDomainToPath(this.providerPath);
    }
    get logoutURL() {
      return this.enableHtmlMocks ? _path.default.join(this.rootURL, this.mockLogoutPath) : _path.default.join(this.logoutDomain, this.logoutPath);
    }
    get userInfoURL() {
      return this.addDomainToPath(this.userInfoPath);
    }
    get validateURL() {
      return this.addDomainToPath(this.validatePath);
    }
    get revokeURL() {
      return this.addDomainToPath(this.revokePath);
    }
    get sessionInfoURL() {
      return this.addDomainToPath(this.sessionInfoPath);
    }
    get callbackURL() {
      return _path.default.join(this.rootURL, '/', this.callbackPath);
    }
    get configuredCookieDomain() {
      let domain = '';
      if (this.cookieDomain?.trim?.() !== '') {
        return this.cookieDomain;
      }
      if (this.expandCookieDomain > 0) {
        const host = this.getHost();
        const parts = host.split('.');
        const remain = 0 - Math.max(parts.length - this.expandCookieDomain, 2);

        // The `remain` const indicates how many segments should be kept.
        // It will never be fewer than 2, so that we never wind up describing
        // the cookie domain as something wild like "com" or "gov".

        domain = parts.splice(remain).join('.');
      }
      if (domain.endsWith(window.location.port)) {
        domain = domain.replace(`:${window.location.port}`, '');
      }
      return domain;
    }
    addDomainToPath(path) {
      return (0, _utils.isBlank)(_path.default.parse(path).host) ? _path.default.join(this.domain, path) : path;
    }
    update(values) {
      const keys = Object.keys(values);
      // @ts-expect-error
      keys.forEach(key => (0, _object.set)(this, key, values[key]));
    }

    /**
     * Wraps location.host for testing purposes.
     * @protected
     */
    getHost() {
      return window.location.host;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "loginURL", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "loginURL"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logoutURL", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "logoutURL"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "userInfoURL", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "userInfoURL"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateURL", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "validateURL"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "revokeURL", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "revokeURL"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sessionInfoURL", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "sessionInfoURL"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "callbackURL", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "callbackURL"), _class.prototype)), _class));
});