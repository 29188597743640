define("@nsf/ember-oauth-iam/utils/broadcast-manager", ["exports", "@nsf/ember-oauth-iam/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * The BroadcastManager class is used internally by the AuthSessionService to coordinate logout
   * activities between browser windows/tabs, should multiple be open when a logout occurs.
   */
  class BroadcastManager {
    constructor(session) {
      _defineProperty(this, "session", void 0);
      _defineProperty(this, "channel", void 0);
      _defineProperty(this, "callback", void 0);
      this.session = session;
    }
    login() {
      this.channel = new BroadcastChannel('ember-oauth-iam');
      this.callback = this.handleIncomingBroadcasts.bind(this);
      if (this.callback) {
        this.channel.addEventListener('message', this.callback);
      }
    }
    logout() {
      if (this.channel) {
        if (this.callback) {
          this.channel.removeEventListener('ember-oauth-iam', this.callback);
        }
        this.channel.postMessage({
          scope: (0, _configuration.getConfig)().configuredCookieDomain,
          action: 'logout'
        });
        this.reset();
      }
    }
    reset() {
      if (this.channel) {
        if (this.callback) {
          this.channel.removeEventListener('ember-oauth-iam', this.callback);
        }
        this.channel.close();
        this.channel = undefined;
        this.callback = undefined;
      }
    }
    handleIncomingBroadcasts(event) {
      const {
        scope,
        action
      } = event.data;
      const authDomain = (0, _configuration.getConfig)().configuredCookieDomain;
      if (scope === authDomain && action === 'logout' && this.session.isAuthenticated) {
        return this.session.invalidate();
      }
      return undefined;
    }
  }
  _exports.default = BroadcastManager;
});