import Helper from '@ember/component/helper';
import { formatDateTime } from '../utils';
  
  type Positional = [number | Date | string, object, boolean];

/**
 * Reformats a date according to format specified.
 *
 * returns default format MM/DD/YYYY
 * 
 * ```handlebars
 *     {{format-date-time 1611245724633}}
 * ```
 * renders 
 *  1/21/2021
 * 
 * options can be passed as second param to render date in certain formats
 * see below for all the options choices
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 * 
 * const options = { month: 'numeric', 
 *  day: 'numeric', 
 *  year: 'numeric', 
 *  hour: 'numeric',
 *  minute: 'numeric',
 *  timeZone: 'America/New_York',
 *  timeZoneName: 'short'};
 * 
 * in this options we want to see time & timezone.
 * 
 * ```handlebars
 *     {{format-date-time 1611245724633 options}}
 * ```
 * renders 
 *  1/21/2021 11:15 AM EST
 * 
 * Optionally, a boolean value can be passed as the third parameter to preserve the comma in the date string format.
 * 
 * ```handlebars
 *     {{format-date-time 1611245724633 options true}}
 * ```
 * renders 
 *  January 21, 2021
 * 
 */
  export default class FormatDateTime extends Helper {
    /**
     * @protected
     */
    compute([dateToFormat, options, commaIncludedDateString]: Positional) {

      return formatDateTime(dateToFormat, options, commaIncludedDateString);
    }
  }