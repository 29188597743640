define("@nsf/ember-webtrend-analytics/utils/webtrends-instance", ["exports", "@nsf/ember-webtrend-analytics/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.destroyWebtrendsInstance = destroyWebtrendsInstance;
  _exports.hasWebtrendsInstance = hasWebtrendsInstance;
  _exports.normalizeConfig = normalizeConfig;
  _exports.webtrendsInstance = webtrendsInstance;
  /**
   * Normalize an object to have all lowercase-only keys to be provided as configuration
   * for Webtrends.
   */
  function normalizeConfig(config) {
    const result = {};
    Object.keys(config).reduce(function (acc, cur) {
      const lowercaseKey = cur.toLowerCase();

      // @ts-expect-error - Webtrends uses all lowercase names for config options.
      result[lowercaseKey] = config[cur];
      return acc;
    }, result);
    return result;
  }

  /**
   * Get the current Webtrends instance, creating it if it does not exist.
   */
  function webtrendsInstance(config) {
    if (!hasWebtrendsInstance()) {
      const mergedConfig = Object.assign(normalizeConfig(_constants.WebtrendsConfigDefaults), config ? normalizeConfig(config) : {});
      window.webtrendsTracker = typeof window.dcsInitWebtrends === 'function' ? window.dcsInitWebtrends(mergedConfig) : undefined;
    }
    return window.webtrendsTracker;
  }

  /**
   * Deletes the current Webtrends instance if it exists.
   */
  function destroyWebtrendsInstance() {
    if (hasWebtrendsInstance()) {
      delete window.webtrendsTracker;
    }
  }

  /**
   * Check if a Webtrends instance exists.
   */
  function hasWebtrendsInstance() {
    return !!window.webtrendsTracker;
  }
});