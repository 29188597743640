define("@nsf/ember-oauth-iam/components/oauth-iam/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "@ember/object/computed", "@ember-decorators/component", "@nsf/ember-oauth-iam/internal-session", "@nsf/ember-oauth-iam/components/oauth-iam/template"], function (_exports, _component, _service, _object, _computed, _component2, _internalSession, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5; // @ts-expect-error
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * Takes an amount of seconds and turns it into a human-friendly string.
   * ```
   * toTimerString(65); // 1 minute 5 seconds
   * ```
   */
  function toTimerString(seconds) {
    const minuteCount = Math.floor(seconds / 60);
    const secondCount = Math.floor(seconds % 60);
    const minuteString = `${minuteCount} minute${minuteCount === 1 ? '' : 's'}`;
    const secondString = `${secondCount} second${secondCount === 1 ? '' : 's'}`;
    return `${minuteString} ${secondString}`;
  }

  /**
   * @class OAuthIAMComponent
   */
  let OAuthIAMComponent = _exports.default = (_dec = (0, _component2.layout)(_template.default), _dec2 = (0, _computed.readOnly)('session.isExpiring'), _dec3 = (0, _computed.readOnly)('session.isInactive'), _dec4 = (0, _computed.readOnly)('session.tokenExpiresIn'), _dec5 = (0, _computed.readOnly)('session.idleLogoutIn'), _dec6 = (0, _object.computed)('isExpiring', 'tokenExpiresIn'), _dec7 = (0, _object.computed)('isExpiring', 'showExpirationNotice', 'expirationNoticeShown'), _dec8 = (0, _object.computed)('isInactive', 'idleLogoutIn'), _dec9 = (0, _object.computed)('isInactive', 'isExpiring', 'showExpirationNotice', 'expirationNoticeShown'), _dec(_class = (_class2 = class OAuthIAMComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "showExpirationNotice", false);
      _defineProperty(this, "expirationNoticeShown", false);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "isExpiring", _descriptor2, this);
      _initializerDefineProperty(this, "isInactive", _descriptor3, this);
      _initializerDefineProperty(this, "tokenExpiresIn", _descriptor4, this);
      _initializerDefineProperty(this, "idleLogoutIn", _descriptor5, this);
    }
    get tokenExpiresInString() {
      return this.isExpiring ? toTimerString(this.tokenExpiresIn) : undefined;
    }
    get doRenderExpirationNotice() {
      return this.isExpiring && this.showExpirationNotice && !this.expirationNoticeShown;
    }
    get idleLogoutInString() {
      return this.isInactive ? toTimerString(this.idleLogoutIn) : undefined;
    }
    get doRenderIdleLogoutNotice() {
      // Only render the idle notice if the inactive checkpoint has been reached AND the token is not expiring
      // OR the token is expiring but the final expiration notice has not yet been dismissed.
      return this.isInactive && (!this.isExpiring || this.showExpirationNotice && !this.expirationNoticeShown);
    }
    resetIdle() {
      this.session.resetIdle();
    }
    logout() {
      this.session.invalidate();
      return false;
    }
    closeExpirationNotice() {
      (0, _object.set)(this, 'expirationNoticeShown', true);
    }
    didInsertElement() {
      super.didInsertElement();

      // This is pointing at the AuthSessionService's "session" which is an instance of AuthInternalSession.
      // SimpleAuth didn't do too well with the naming convention there... confusing.
      this.session.session.on(_internalSession.SessionEvents.PENDING_EXPIRATION, this, this.sessionTokenExpirationPending);
    }
    sessionTokenExpirationPending(idx) {
      // The second configured expiration notice time.
      if (idx === 1) {
        (0, _object.set)(this, 'showExpirationNotice', true);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isExpiring", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isInactive", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "tokenExpiresIn", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "idleLogoutIn", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "tokenExpiresInString", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "tokenExpiresInString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doRenderExpirationNotice", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "doRenderExpirationNotice"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "idleLogoutInString", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "idleLogoutInString"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "doRenderIdleLogoutNotice", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "doRenderIdleLogoutNotice"), _class2.prototype)), _class2)) || _class);
});