import { isEmpty } from '@ember/utils';
/**
 * https://github.com/inmogr/node-ts-mask-email-address
 * 
 * This function creates a masked email address
 * @param emailAddress
 * @returns maskedEmailAddress
 * 
 * maskEmailAddress("foobar@gmail.com") => *****r@gmail.com
 */
export default function maskEmailAddress(emailAddress: string): string {
    if (isEmpty(emailAddress)) {
        return '';
    }
    return emailAddress.replace(/([\w.]+)@([\w.]+)(\.[\w.]+)/g, (_m, p1, p2, p3) => {
        return maskPart(p1) + "@" + p2 + p3;
    });
}

const maskPart = (part: string) => {
    return [...part].reduce((acc, x, i) => (i < part.length - 1) ? acc+'*' : acc+x , '');
};
