define("@nsf/ember-properties-loader/index", ["exports", "@nsf/ember-properties-loader/services/properties"], function (_exports, _properties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PropertiesService = void 0;
  // eslint-disable-next-line @typescript-eslint/no-empty-interface

  // A type parameter can also be passed in to extend the available properties
  // of the service.

  const PropertiesService = _exports.PropertiesService = _properties.default;
});