define("@nsf/ember-properties-loader/components/property-loader-values/component", ["exports", "@ember/component", "@ember/service", "@ember/object", "@nsf/ember-properties-loader/utils/object-chain-value", "@nsf/ember-properties-loader/components/property-loader-values/template"], function (_exports, _component, _service, _object, _objectChainValue, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A utility component to output current properties value information.
   *
   * @class PropertiesLoaderValuesComponent
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    properties: (0, _service.inject)('properties'),
    propertyDetails: (0, _object.computed)('properties.loadedPropertyMeta', function () {
      const metadata = this.properties.loadedPropertyMeta;
      const details = [];
      if (!metadata) {
        return details;
      }
      [...metadata.keys()].sort().forEach(entry => {
        const prop = metadata.get(entry);
        const type = prop?.sources[prop?.sources.length - 1].type;
        const isObject = type === 'object' || type === 'instance';
        const isArray = type === 'array';
        const isString = type === 'string';
        const isBoolean = type === 'boolean';
        const isNumber = type === 'number';
        const sources = prop?.sources.map(source => source.url);
        const value = isObject || isArray ? null : (0, _objectChainValue.default)(this.properties, prop?.propertyChain);
        details.push({
          chain: prop?.propertyChain.join('.'),
          type,
          isObject,
          isArray,
          isString,
          isBoolean,
          isNumber,
          sources,
          value
        });
      });
      return details;
    })
  });
});