import { htmlSafe } from '@ember/template';

import { ErrorMessages } from '../constants';

/**
 * An object that aggregates messages received from a response.
 */
export default class MessageAggregation {
  private static pullMessagesOfType<T extends string>(source: Record<T, unknown>, key: T): MessageArray {
    const messages = new MessageArray();
    const value: unknown = source[key];
    if (Array.isArray(value)) {
      messages.add(...value);
    }
    return messages;
  }


  /** Are there any messages at all? */                                 readonly  hasMessages:       boolean;
  /** Are there any success  messages? */                               readonly hasSuccesses:       boolean;
  /** Are there any info     messages? */                               readonly hasInformationals:  boolean;
  /** Are there any caution  messages? */                               readonly hasCautions:        boolean;
  /** Are there any warning  messages? */                               readonly hasWarnings:        boolean;
  /** Are there any error    messages? */                               readonly hasErrors:          boolean;
  /** Are there any dangers  messages? */                               readonly hasDangers:         boolean;
  /** The number of success  messages. */                               readonly successCount:       number;
  /** The number of info     messages. */                               readonly informationalCount: number;
  /** The number of warning  messages. */                               readonly cautionCount:       number;
  /** The number of warning  messages. */                               readonly warningCount:       number;
  /** The number of error    messages. */                               readonly errorCount:         number;
  /** The number of dangers  messages. */                               readonly dangersCount:       number;
  /** The success messages. */                                          readonly successes:          MessageArray;
  /** The info     messages. */                                         readonly informationals:     MessageArray;
  /** The caution  messages. */                                         readonly cautions:           MessageArray;
  /** The warning  messages. */                                         readonly warnings:           MessageArray;
  /** The error    messages. Used for HTTP status messages */           readonly errors:             MessageArray;
  /** The dangers  messages. Used for hard stop api error messages */   readonly dangers:            MessageArray;
    

  /**
	 * Construct a new instance given a payload from an API response.
	 * @param payload - the payload returned by the server
	 */
  constructor(payload: Record<string, unknown> = {}) {
    // 4xx and 5xx HTTP responses using the fetch API will have a payload property,
    // but some other scenarios will not.
    const msgs = payload.payload as Record<string, unknown> || payload;

    this.successes      = MessageAggregation.pullMessagesOfType(msgs, 'successes');
    this.informationals = MessageAggregation.pullMessagesOfType(msgs, 'informationals');
    this.cautions       = MessageAggregation.pullMessagesOfType(msgs, 'cautions');
    this.warnings       = MessageAggregation.pullMessagesOfType(msgs, 'warnings');
    this.errors         = MessageAggregation.pullMessagesOfType(msgs, 'errors');
    this.dangers        = MessageAggregation.pullMessagesOfType(msgs, 'dangers');
        
    this.warnings.add(...MessageAggregation.pullMessagesOfType(msgs, 'errWarnings'));

    // This will need to get built out more, but proper runtime exceptions - as
    // opposed to HTTP fault responses - will probably find their way in here
    // too. If we've received such a thing and couldn't get errors out of it in
    // the usual way then having something would be nice.
    if (typeof payload?.stack === 'string' && !this.errors.length) {
      this.errors.push(payload.message as string);
    }

    // Special handling of some HTTP statuses.
    if (payload?.status || payload?.httpStatus) {
      const code: number = parseInt(payload?.status as string || payload?.httpStatus as string, 10);
      if (code >= 500) {
        // Not positive that dumping out all other errors is the right way to go, but the Awards API
        // likes to return stack traces that shouldn't be displayed.

        // TODO: Handling a SafeString object doesn't mesh well with the error POJOs
        // returned from the API. Need to determine at what layer it makes sense to
        // distinguish between the typical { detail: "An error occurred" } errors and
        // this generic 500 error SafeString object.
        this.errors.length = 0;
        this.errors.add(htmlSafe(ErrorMessages.somethingUnexpected) as unknown as string);
      }
    }

    // Sometimes, the Awards API does not return text content, but rather the
    // number of success/failures that occurred. Yes, "failure" and not "error".
    this.successCount       = msgs.successCount       as number || this.successes.length;
    this.informationalCount = msgs.informationalCount as number || this.informationals.length;
    this.cautionCount       = msgs.cautionCount       as number || this.cautions.length;
    this.warningCount       = msgs.warningCount       as number || this.warnings.length;
    this.errorCount         = msgs.failureCount       as number || this.errors.length;
    this.dangersCount       = msgs.failureCount       as number || this.dangers.length;

    this.hasSuccesses      = !!this.successes.length;
    this.hasInformationals = !!this.informationals.length;
    this.hasCautions       = !!this.cautions.length;
    this.hasWarnings       = !!this.warnings.length;
    this.hasErrors         = !!this.errors.length;
    this.hasDangers        = !!this.dangers.length;

    this.hasMessages = this.hasSuccesses || this.hasInformationals || this.hasCautions || this.hasWarnings || this.hasErrors || this.hasDangers;
  }

  /**
	 * Return a new aggregation that is a clone of this one.
	 * @return a new MessageAggregation object with the same messages as this aggregation
	 */
  clone(): MessageAggregation {
    return this.merge(new MessageAggregation());
  }

  /**
	 * Return a new aggregation that is a result of merging this one with a given argument.
	 * @param that - the aggregation to merge with
	 * @return a new MessageAggregation object with the combined messages of this aggregation and the argument
	 */
  merge(that: MessageAggregation): MessageAggregation {
    return new MessageAggregation({
      successes:      this.successes      .clone().add(...that.successes),
      informationals: this.informationals .clone().add(...that.informationals),
      cautions:       this.cautions       .clone().add(...that.cautions),
      warnings:       this.warnings       .clone().add(...that.warnings),
      errors:         this.errors         .clone().add(...that.errors),
      dangers:        this.dangers        .clone().add(...that.dangers),
    });
  }
}



/**
 * A string array that carries along the original object from which each string was derived.
 */
class MessageArray extends Array<string> {
  private readonly _raw: unknown[] = [];

  get raw(): unknown[] {
    return this._raw.slice();
  }

  add(...items: Array<string | {
		detail?:      unknown,
		description?: unknown,
		[K: string]:  unknown,
	}>): this {
    const uniqueMessages: { [key: string]: boolean } = {};

    items.forEach((item) => {
      let valueToAdd: string | undefined;

      if (typeof item === 'string') {
        valueToAdd = item;
      } else if (item && typeof item === 'object') {
        if (typeof item.detail === 'string') {
          valueToAdd = item.detail;
        } else if (typeof item.description === 'string') {
          valueToAdd = item.description;
        }
      }

      // Check for duplicates before adding
      if (valueToAdd !== undefined && !uniqueMessages[valueToAdd]) {
        uniqueMessages[valueToAdd] = true;
        this.push(valueToAdd);
      }

      this._raw.push(item);
    });
    return this;
  }

  replaceStrings(mapObj: Record<string, string>): this {
    this.forEach((_, i) => {
      for (const [key, replacement] of Object.entries(mapObj)) {
          this[i] = this[i].replace(new RegExp(key, 'g'), replacement);
      }
    });
    return this;
  }

  clone(): MessageArray {
    const cloned = new MessageArray();
    this.forEach((item) => {
      cloned.push(item); // not using `cloned.add` because that would affect `cloned._raw`
    });
    this._raw.forEach((rawItem) => {
      cloned._raw.push(rawItem);
    });
    return cloned;
  }
}
