(function (root, factory) {
  // AMD. Make globally available as well
  if (typeof define === 'function' && define.amd) {
    define(['moment', 'jquery'], function (moment, jquery) {
      if (!jquery.fn) jquery.fn = {}; // webpack server rendering
      return factory(moment, jquery);
    });
  }
  // Node / Browserify - isomorphic issue
  else if (typeof module === 'object' && module.exports) {
    var jQuery = (typeof window !== 'undefined') ? window.jQuery : undefined;

    if (!jQuery) {
      jQuery = require('jquery');
      if (!jQuery.fn) jQuery.fn = {};
    }

    var moment = (typeof window !== 'undefined' && typeof window.moment !== 'undefined')
      ? window.moment
      : require('moment');

    module.exports = factory(moment, jQuery);
  }
  // Browser globals
  else {
    var moment = (typeof root !== 'undefined' && typeof root.moment !== 'undefined')
      ? root.moment
      : require('moment');

    root.daterangepicker = factory(moment, root.jQuery);
  }
}(this, function(moment, $) {
  // *******************
  // Utility methods
  // *******************
  var encodeText = function(text) {
    var elem = document.createElement('textarea');
    elem.innerHTML = text;

    return elem.value;
  };

  var isNull  = function(obj) { return obj === null;};
  var isArray = function(obj) { return Object.prototype.toString.call(obj) === '[object Array]'; };

  var isType = function(obj, type) {
    if (type === 'null') {
      return isNull(obj);
    }
    else if (type === 'array') {
      return isArray(obj);
    }

    return typeof obj === type;
  };

  var isObject      = function(obj) { return isType(obj, 'object'); };
  var isFunction    = function(obj) { return isType(obj, 'function'); };
  var isNumber      = function(obj) { return isType(obj, 'number'); };
  var isString      = function(obj) { return isType(obj, 'string'); };
  var isBoolean     = function(obj) { return isType(obj, 'boolean'); };
  var isUndefined   = function(obj) { return isType(obj, 'undefined'); };
  var isJQuery      = function(obj) { return obj instanceof $; };
  var exists        = function(obj) { return !isUndefined(obj) && !isNull(obj); };
  var isEmpty       = function(obj) { return !exists(obj) || (isString(obj) && obj === '') || (isObject(obj) && !Object.keys(obj).length); };
  var concat        = function() { return Array.prototype.slice.call(arguments).reduce(function(acc, val) { return acc + val }, ''); };

  var propString = function(string, value) {
    if (isString(string)) {
      return !isEmpty(value) ? string +'="'+ value +'"' : string;
    }

    return '';
  };

  var dataSelector = function(string, value) {
    var result = propString(string, value);
    return !isEmpty(result) ? '['+ result +']' : '';
  };

  var debounce = function(scope, fn, wait, immediate) {
    wait || (wait = 200);
    immediate = immediate !== false; // A bit different, execute on the leading edge by default
    var timeout;

    return function() {
      var context = scope || this;
      var args = arguments;

      var later = function() {
        timeout = null;
        if (!immediate)
          fn.apply(context, args);
      };

      var callNow = immediate && !timeout;

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow)
        fn.apply(context, args);
    };
  };




  // *******************
  // Constants
  // *******************
  var PLUGIN_NAME = 'dateRangePicker';
  var LEFT        = 'left';
  var RIGHT       = 'right';
  var CENTER      = 'center';
  var UP          = 'up';
  var DOWN        = 'down';
  var PREV        = 'prev';
  var NEXT        = 'next';
  var START       = 'start';
  var END         = 'end';
  var HIDE        = 'hide';
  var SHOW        = 'show';
  var HIDE_CAL    = 'hideCalendar';
  var SHOW_CAL    = 'showCalendar';
  var APPLY       = 'apply';
  var CANCEL      = 'cancel';
  var INVALID     = 'invalid';
  var DISABLED    = 'disabled';
  var OUT_CLICK   = 'outsideClick';
  var YEAR        = 'year';
  var MONTH       = 'month';
  var HOUR        = 'hour';
  var MINUTE      = 'minute';
  var SECOND      = 'second';
  var MERIDIEM    = 'meridiem';
  var ACTIVE      = 'active';
  var AVAILABLE   = 'available';
  var IN_RANGE    = 'in-range';
  var WEEK        = 'week';

  var DATA_CALENDAR           = 'data-calendar';
  var DATA_APPLY_BTN          = 'data-apply-btn';
  var DATA_CANCEL_BTN         = 'data-cancel-btn';
  var DATA_CALENDAR_TABLE     = 'data-calendar-table';
  var DATA_CALENDAR_TIME      = 'data-calendar-time';
  var DATA_CALENDAR_CELL      = 'data-title';
  var DATA_CALENDAR_PREV      = 'data-prev';
  var DATA_CALENDAR_PREV_WRAP = 'data-prev-wrapper';
  var DATA_CALENDAR_NEXT      = 'data-next';
  var DATA_CALENDAR_NEXT_WRAP = 'data-next-wrapper';
  var DATA_DATE_INPUT_GROUP   = 'data-date-input-group';
  var DATA_DATE_SELECT        = 'data-date-select';
  var DATA_TIME_SELECT        = 'data-time-select';
  var DATA_SIDEBAR            = 'data-sidebar';
  var DATA_RANGE_LIST         = 'data-range-list';
  var DATA_RANGE_KEY          = 'data-range-key';

  var CONTAINER_CLASS         = 'date-range-picker';
  var CALENDAR_VISIBLE_CLASS  = 'show-calendar';
  var CALENDAR_SINGLE_CLASS   = 'single';
  var AUTO_APPLY_CLASS        = 'auto-apply';
  var HAS_RANGES_CLASS        = 'with-ranges';
  var HAS_DATE_SELECT_CLASS   = 'with-date-select';
  var HAS_TIME_SELECT_CLASS   = 'with-time-select';
  var TIME_SELECT_ONLY_CLASS  = 'with-time-only-select';
  var HAS_WEEKS_DISPLAYED     = 'with-week-display';
  var SIDEBAR_CLASS           = 'sidebar';
  var SELECT_CLASS            = 'form-control input-sm';
  var FOCUSED_CLASS           = 'focused';
  var EMPTY_ROW_CLASS         = 'empty';
  var CONTROL_BTN_GROUP_CLASS = 'controls';



  // *******************
  // Define constructor
  // *******************
  var DateRangePicker = function(element, options, callback) {
    this.element          = $(element);
    this.isInputElement   = this.element.is('input');
    this.isButtonElement  = this.element.is('button');
    options               = $.extend(this.element.data(), isObject(options) ? options : {});

    // Public properties
    this.parentEl = (isString(options.parentEl) || isJQuery(options.parentEl)) ? $(options.parentEl) : $('body');

    this.singleDatePicker       = isBoolean(options.singleDatePicker)       ? options.singleDatePicker      : false;
    this.timePickerOnly         = isBoolean(options.timePickerOnly)         ? options.timePickerOnly        : false;
    this.timePicker             = isBoolean(options.timePicker)             ? options.timePicker            : true;
    this.timePickerSeconds      = isBoolean(options.timePickerSeconds)      ? options.timePickerSeconds     : true;
    this.timePicker24Hour       = isBoolean(options.timePicker24Hour)       ? options.timePicker24Hour      : false;
    this.timePickerIncrement    = isNumber(options.timePickerIncrement)     ? options.timePickerIncrement   : 1;
    this.dateLimit              = isObject(options.dateLimit)               ? options.dateLimit             : false;
    this.autoUpdateInput        = isBoolean(options.autoUpdateInput)        ? options.autoUpdateInput       : true;
    this.autoApply              = isBoolean(options.autoApply)              ? options.autoApply             : false;
    this.linkedCalendars        = isBoolean(options.linkedCalendars)        ? options.linkedCalendars       : true;
    this.opens                  = isString(options.opens)                   ? options.opens                 : RIGHT;
    this.drops                  = isString(options.drops)                   ? options.drops                 : DOWN;
    this.showDropdowns          = isBoolean(options.showDropdowns)          ? options.showDropdowns         : true;
    this.alwaysShowCalendars    = isBoolean(options.alwaysShowCalendars)    ? options.alwaysShowCalendars   : false;
    this.showCustomRangeLabel   = isBoolean(options.showCustomRangeLabel)   ? options.showCustomRangeLabel  : true;
    this.showWeekNumbers        = isBoolean(options.showWeekNumbers)        ? options.showWeekNumbers       : false;
    this.showISOWeekNumbers     = isBoolean(options.showISOWeekNumbers)     ? options.showISOWeekNumbers    : false;
    this.showOffMonthDates      = isBoolean(options.showOffMonthDates)      ? options.showOffMonthDates     : true;
    this.lockStartDate          = isBoolean(options.lockStartDate)          ? options.lockStartDate         : false;
    this.lockEndDate            = isBoolean(options.lockEndDate)            ? options.lockEndDate           : false;
    this.buttonClasses          = isString(options.buttonClasses)           ? options.buttonClasses         : 'btn btn-sm';
    this.applyClass             = isString(options.applyClass)              ? options.applyClass            : 'btn-success';
    this.cancelClass            = isString(options.cancelClass)             ? options.cancelClass           : 'btn-default';
    this.rangeClass             = isString(options.rangeClass)              ? options.rangeClass            : 'btn-default';
    this.linkedNavClass         = isString(options.linkedNavClass)          ? options.linkedNavClass        : 'btn-default';
    this.callback               = isFunction(callback)                      ? callback                      : function() {};
    this.isCustomDate           = isFunction(options.isCustomDate)          ? options.isCustomDate          : function() {};
    this.isInvalidDate          = isFunction(options.isInvalidDate)         ? options.isInvalidDate         : function() {};
    this.yearRangePast          = isNumber(options.yearRangePast)           ? options.yearRangePast         : 10;
    this.yearRangeFuture        = isNumber(options.yearRangeFuture)         ? options.yearRangeFuture       : 10;
    this.linkCalendarToInput    = isBoolean(options.linkCalendarToInput)    ? options.linkCalendarToInput   : false;
    this.allowEmpty             = isBoolean(options.allowEmpty)             ? options.allowEmpty            : true;
    this.keepInvalid            = isBoolean(options.keepInvalid)            ? options.keepInvalid           : true;
    this.useStrict              = isBoolean(options.useStrict)              ? options.useStrict             : false;


    this.locale = {
      direction:          isString(options.locale.direction)          ? options.locale.direction          : 'ltr',
      format:             isString(options.locale.format)             ? options.locale.format             : moment.localeData().longDateFormat('L'),
      parseFormats:       isArray(options.locale.parseFormats)        ? options.locale.parseFormats       : ['YYYY-MM-DD'],
      separator:          isString(options.locale.separator)          ? options.locale.separator          : ' - ',
      applyLabel:         isString(options.locale.applyLabel)         ? options.locale.applyLabel         : 'Apply',
      cancelLabel:        isString(options.locale.cancelLabel)        ? options.locale.cancelLabel        : 'Cancel',
      weekLabel:          isString(options.locale.weekLabel)          ? options.locale.weekLabel          : 'W',
      customRangeLabel:   isString(options.locale.customRangeLabel)   ? options.locale.customRangeLabel   : 'Custom Range',
      daysOfWeek:         isArray(options.locale.daysOfWeek)          ? options.locale.daysOfWeek         : moment.weekdaysMin(),
      monthNames:         isArray(options.locale.monthNames)          ? options.locale.monthNames         : moment.monthsShort(),
      firstDay:           isNumber(options.locale.firstDay)           ? options.locale.firstDay           : moment.localeData().firstDayOfWeek(),

      beforeMinDateTitle: isString(options.locale.beforeMinDateTitle) || isNull(options.locale.beforeMinDateTitle)
        ? options.locale.beforeMinDateTitle
        : 'This day is before the allowed minimum',

      afterMaxDateTitle: isString(options.locale.afterMaxDateTitle) || isNull(options.locale.afterMaxDateTitle)
        ? options.locale.afterMaxDateTitle
        : 'This day is after the allowed maximum'
    };


    // Private properties
    this.startDate        = false;
    this.endDate          = false;
    this.oldStartDate     = null;
    this.oldEndDate       = null;
    this.isShowing        = false;
    this.leftCalendar     = {};
    this.rightCalendar    = {};
    this.chosenRangeLabel = null;
    this.ranges           = {};
    this.hasRanges        = isObject(options.ranges);
    this.manualDateChange = null;


    // Create the default template for the picker popup if a custom one was not provided.
    if (!isString(options.template) && !isJQuery(options.template)) {
      options.template = this.buildDefaultPickerTemplate();
    }


    // Locking start/end dates requires a date to be provided
    if (isUndefined(options.startDate)) {
      this.lockStartDate = false;
    }

    if (isUndefined(options.endDate)) {
      this.lockEndDate = false;
    }


    // Time (hah, get it!) to set up some dates!
    this.minDate = this.toMoment(options.minDate, false);
    this.maxDate = this.toMoment(options.maxDate, false);

    var start;
    var end;

    if (this.isInputElement && (isUndefined(options.startDate) || isUndefined(options.endDate))) {
      var dates = this.fromFormat(this.element.val());
      start     = dates[0];
      end       = dates[1];
    }

    start = this.toMoment(start || options.startDate, 'now');

    if (!start.isValid()) {
      start = moment();
    }

    end = this.singleDatePicker ? start.clone() : this.toMoment(end || options.endDate, 'now');

    this.setDates(start, end);


    // Update day names order to firstDay
    if (this.locale.firstDay !== 0) {
      var iterator = this.locale.firstDay;

      while (iterator > 0) {
        this.locale.daysOfWeek.push(this.locale.daysOfWeek.shift());
        iterator--;
      }
    }

    // Force enable the time picker
    if (this.timePickerOnly) {
      this.timePicker = true;
    }

    // These can't be used together for now
    if (this.timePicker && this.autoApply) {
      this.autoApply = false;
    }

    // Create the picker container
    this.container = $(options.template).appendTo(this.parentEl);


    // Start/End date locks result in a lot of things being disabled
    if (!this.singleDatePicker && (this.lockStartDate || this.lockEndDate)) {
      this.hasRanges = false;

      if (this.lockStartDate) {
        this.getDateInputGroup(LEFT).addClass(DISABLED);
        this.getDateInput(LEFT).attr(DISABLED, DISABLED);
        this.minDate = (this.minDate && this.minDate.isAfter(this.startDate)) ? this.minDate : this.startDate.clone();

        if (!this.timePicker) {
          this.minDate = this.minDate.endOf('day');
        }
      }

      if (this.lockEndDate) {
        this.getDateInputGroup(RIGHT).addClass(DISABLED);
        this.getDateInput(RIGHT).attr(DISABLED, DISABLED);
        this.maxDate = (this.maxDate && this.maxDate.isBefore(this.endDate)) ? this.maxDate : this.endDate.clone();

        if (!this.timePicker) {
          this.maxDate = this.maxDate.startOf('day');
        }
      }
    }
    else {
      this.lockStartDate = false;
      this.lockEndDate = false;
    }


    // Modify for single picker
    if (this.singleDatePicker) {
      this.container.addClass(CALENDAR_SINGLE_CLASS);
      this.getCalendarSide(LEFT).addClass(CALENDAR_SINGLE_CLASS).show();
      this.getCalendarSide(RIGHT).hide();
      this.getDateInputGroup().hide();

      if (this.timePicker) {
        this.getRangeList().hide();
      }

      this.hasRanges = false;
    }
    else {
      // Set up pre-configured range options if provided
      if (this.hasRanges) {
        this.ranges = this.extractRangeOptions(options.ranges);
        var template = this.buildRangeOptionsTemplate();

        if (isEmpty(template)) {
          this.hasRanges = false;
        }
        else {
          this.getSidebar().prepend(template);
          this.container.addClass(HAS_RANGES_CLASS);

          if (this.opens === RIGHT) {
            this.container.prepend(this.getSidebar());
          }
        }
      }
    }

    // Modify apply and cancel buttons per configuration
    this.renderApplyAndCancelButtons();

    // A bit more setup
    this.container.addClass(this.locale.direction);
    this.container.addClass('opens'+ this.opens);

    if (this.drops === UP) {
      this.container.addClass('dropup');
    }

    if (!this.timePickerOnly && ((!this.hasRanges && !this.singleDatePicker) || this.alwaysShowCalendars)) {
      this.container.addClass(CALENDAR_VISIBLE_CLASS);
    }

    if (this.autoApply) {
      this.container.addClass(AUTO_APPLY_CLASS);
    }

    if (this.showDropdowns) {
      this.container.addClass(HAS_DATE_SELECT_CLASS);
    }

    if (this.timePicker) {
      this.container.addClass(HAS_TIME_SELECT_CLASS);
    }

    if (this.timePickerOnly) {
      this.container.addClass(TIME_SELECT_ONLY_CLASS);
    }

    if (this.showWeekNumbers || this.showISOWeekNumbers) {
      this.container.addClass(HAS_WEEKS_DISPLAYED);
    }


    // Attach event listeners
    this.attachEvent(this.container, 'click',       dataSelector(DATA_CALENDAR_PREV),         this.goToPrevMonth);
    this.attachEvent(this.container, 'click',       dataSelector(DATA_CALENDAR_NEXT),         this.goToNextMonth);
    this.attachEvent(this.container, 'mousedown',   dataSelector(DATA_CALENDAR_CELL),         this.onClickDate);
    this.attachEvent(this.container, 'mouseenter',  dataSelector(DATA_CALENDAR_CELL),         this.onHoverDate);
    this.attachEvent(this.container, 'mouseleave',  dataSelector(DATA_CALENDAR_CELL),         this.updateFormInputs);
    this.attachEvent(this.container, 'change',      dataSelector(DATA_DATE_SELECT, YEAR),     this.onMonthOrYearChange);
    this.attachEvent(this.container, 'change',      dataSelector(DATA_DATE_SELECT, MONTH),    this.onMonthOrYearChange);
    this.attachEvent(this.container, 'change',      dataSelector(DATA_TIME_SELECT, HOUR),     this.onTimeChange);
    this.attachEvent(this.container, 'change',      dataSelector(DATA_TIME_SELECT, MINUTE),   this.onTimeChange);
    this.attachEvent(this.container, 'change',      dataSelector(DATA_TIME_SELECT, SECOND),   this.onTimeChange);
    this.attachEvent(this.container, 'change',      dataSelector(DATA_TIME_SELECT, MERIDIEM), this.onTimeChange);
    this.attachEvent(this.container, 'click',       dataSelector(DATA_RANGE_KEY),             this.onClickRange);
    this.attachEvent(this.container, 'mouseenter',  dataSelector(DATA_RANGE_KEY),             this.onHoverRange);
    this.attachEvent(this.container, 'mouseleave',  dataSelector(DATA_RANGE_KEY),             this.updateFormInputs);

    var dateInputs = this.getDateInput();
    this.attachEvent(dateInputs, 'click',   this.showCalendars);
    this.attachEvent(dateInputs, 'focus',   this.onFormInputsFocused);
    this.attachEvent(dateInputs, 'blur',    this.onFormInputsBlurred);
    this.attachEvent(dateInputs, 'change',  this.onFormInputsChanged);
    this.attachEvent(dateInputs, 'keydown', this.onFormInputsKeyDown);


    if (this.isInputElement || this.isButtonElement) {
      this.attachEvent(this.element, 'click focusin', debounce(this, this.show));
      this.attachEvent(this.element, 'input', this.onElementChanged);
      this.attachEvent(this.element, 'keydown', this.onElementKeyDown);
    }
    else {
      this.attachEvent(this.element, 'click keydown', this.toggle);
    }
  };



  // *******************
  // Define Instance
  // *******************
  DateRangePicker.prototype = {
    constructor: DateRangePicker,


    /**
     * Update both the start and end dates.
     */
    setDates: function(startDate, endDate, updatePlugin) {
      this.setStartDate(startDate, false);
      this.setEndDate(endDate, isBoolean(updatePlugin) ? updatePlugin : true);
    },


    /**
     * Update the start date. This will modify the start date if the supplied value
     * falls outside the min or max bounds. If time picking is disabled, the value
     * will be rolled down to the beginning of the represented day.
     */
    setStartDate: function(date, updatePlugin) {
      if (this.lockStartDate === 'locked') {
        return this.startDate;
      }

      this.startDate = this.toMoment(date, null);
      this.startDate = this.constrainDateToMinMax(this.startDate);

      if (!this.timePicker) {
        this.startDate = this.startDate.startOf('day');
      }
      else {
        this.startDate = this.roundMinutesToIncrement(this.startDate);
      }

      if (updatePlugin !== false) {
        if (!this.isShowing) {
          this.updateElement();
        }

        this.updateMonthsInView();
      }

      if (this.lockStartDate) {
        this.lockStartDate = 'locked';
      }

      return this.startDate;
    },


    /**
     * Update the end date. This will modify the end date if the supplied value
     * falls outside the min, max, or limit bounds. If time picking is disabled,
     * the value will be rolled up to the end of the represented day.
     */
    setEndDate: function(date, updatePlugin) {
      if (this.lockEndDate === 'locked') {
        return this.endDate;
      }

      this.endDate = this.toMoment(date, null);
      this.endDate = this.constrainDateToMinMax(this.endDate);
      this.endDate = this.constrainDateToLimit(this.endDate);

      if (!this.timePicker) {
        this.endDate = this.endDate.endOf('day');
      }
      else {
        this.endDate = this.roundMinutesToIncrement(this.endDate);
      }

      if (updatePlugin !== false) {
        if (!this.isShowing) {
          this.updateElement();
        }

        this.updateMonthsInView();
      }

      if (this.lockEndDate) {
        this.lockEndDate = 'locked';
      }

      return this.endDate;
    },


    /**
     * Back the calendars up by one month.
     */
    goToPrevMonth: function(e) {
      var side = this.getSideFromEventTarget(e);

      if (side === LEFT) {
        this.leftCalendar.month.subtract(1, 'month');

        if (this.linkedCalendars) {
          this.rightCalendar.month.subtract(1, 'month');
        }

        this.updateCalendars();
      }
      else if (side === RIGHT) {
        this.rightCalendar.month.subtract(1, 'month');

        if (this.rightCalendar.month.isSame(this.leftCalendar.month, 'month')) {
          this.leftCalendar.month.subtract(1, 'month');
        }

        this.updateCalendars();
      }
    },


    /**
     * Advance the calendars forward by one month.
     */
    goToNextMonth: function(e) {
      var side = this.getSideFromEventTarget(e);

      if (side === LEFT) {
        this.leftCalendar.month.add(1, 'month');

        if (this.leftCalendar.month.isSame(this.rightCalendar.month, 'month')) {
          this.rightCalendar.month.add(1, 'month');
        }

        this.updateCalendars();
      }
      else if (side === RIGHT) {
        this.rightCalendar.month.add(1, 'month');

        if (this.linkedCalendars) {
          this.leftCalendar.month.add(1, 'month');
        }

        this.updateCalendars();
      }
    },


    /**
     * Check whether the provided date comes before the minDate. Optionally, limit the
     * query to the day, month, or whatever else Moment supports.
     */
    exceedsMinDate: function(date, granularity) {
      return this.minDate && date && date.isBefore(this.minDate, granularity);
    },


    /**
     * Check whether the provided date comes after the maxDate. Optionally, limit the
     * query to the day, month, or whatever else Moment supports.
     */
    exceedsMaxDate: function(date, granularity) {
      return this.maxDate && date && date.isAfter(this.maxDate, granularity);
    },


    /**
     *
     */
    isInputElementEmpty: function() {
      return this.isInputElement && this.element.val().trim() === '';
    },


    /**
     * If an empty value is not allowed, this will check whether the input is empty
     * and return true if it is.
     */
    shouldForceCurrentValues: function() {
      return !this.allowEmpty && this.isInputElementEmpty();
    },


    /**
     * If min and/or max date limits are set, this will return a moment which falls
     * on either bound if the supplied argument falls outside it. Otherwise, the
     * argument will be returned unaltered.
     */
    constrainDateToMinMax: function(date, granularity) {
      if (this.exceedsMinDate(date, granularity)) {
        return this.minDate.clone();
      }
      else if (this.exceedsMaxDate(date, granularity)) {
        return this.maxDate.clone();
      }

      return date;
    },


    /**
     * If the startDate and a dateLimit value is set, this will return a moment which
     * falls on the limit bound if the argument exceeds it. Otherwise, the argument
     * will be returned unaltered.
     */
    constrainDateToLimit: function(date) {
      if (this.dateLimit && this.startDate) {
        var limit = this.startDate.clone().add(this.dateLimit);

        if (limit.isBefore(date)) {
          return limit;
        }
      }

      return date;
    },


    /**
     * If the time picker is enabled, this will round the minutes value of the provided
     * moment argument to conform the configured `timePickerIncrement`.
     */
    roundMinutesToIncrement: function(momentObj) {
      if (moment.isMoment(momentObj) && this.timePicker && this.timePickerIncrement > 1) {
        return momentObj.minute(Math.round(momentObj.minute() / this.timePickerIncrement) * this.timePickerIncrement);
      }

      return momentObj;
    },


    /**
     * Sanitizes a ranges object, storing only the valid ones.
     */
    extractRangeOptions: function(ranges) {
      if (!isObject(ranges)) {
        return;
      }

      var results = {};
      var keys = Object.keys(ranges);
      var key;
      var start;
      var end;
      var range;

      for (var i = 0; i < keys.length; i += 1) {
        key = keys[i];
        range = ranges[key];
        start = this.toMoment(range[0], INVALID);
        end = this.toMoment(range[1], INVALID);

        if (!start.isValid() || !end.isValid()) {
          continue;
        }

        // If the start or end date exceed those allowed by the minDate or dateLimit
        // options, shorten the range to the allowable period.
        if (this.minDate && start.isBefore(this.minDate)) {
          start = this.minDate.clone();
        }

        var maxDate = this.maxDate;
        if (this.dateLimit && maxDate && start.clone().add(this.dateLimit).isAfter(maxDate)) {
          maxDate = start.clone().add(this.dateLimit);
        }

        if (maxDate && end.isAfter(maxDate)) {
          end = maxDate.clone();
        }

        // If the end of the range is before the minimum or the start of the range is
        // after the maximum, don't display this range option at all.
        var granular = this.timePicker ? 'minute' : 'day';
        if ((this.minDate && end.isBefore(this.minDate, granular)) || (maxDate && start.isAfter(maxDate, granular))) {
          continue;
        }

        results[encodeText(key)] = [start, end];
      }

      return results;
    },


    /**
     * Updates the value of the parent element that this DatePicker instance is attached to
     * if it is an `<input>`.
     */
    updateElement: function() {
      if (this.isInputElement && this.autoUpdateInput) {
        this.element.val(this.toFormat(this.startDate, this.endDate));
        this.element.trigger('change');
      }
    },


    /**
     * Calls the various render methods to update the picker UI based on new state.
     */
    updateView: function() {
      if (this.timePicker) {
        this.renderTimePicker(LEFT);
        this.renderTimePicker(RIGHT);

        if (!this.endDate) {
          this.getClock(RIGHT).attr(DISABLED, DISABLED).addClass(DISABLED);
        }
        else {
          this.getClock(RIGHT).removeAttr(DISABLED).removeClass(DISABLED);
        }
      }

      if (this.endDate) {
        this.getDateInput(END).removeClass(ACTIVE);
        this.getDateInput(START).addClass(ACTIVE);
      }
      else {
        this.getDateInput(END).addClass(ACTIVE);
        this.getDateInput(START).removeClass(ACTIVE);
      }

      this.updateMonthsInView();
      this.updateCalendars();
      this.updateFormInputs();
    },


    /**
     *
     */
    updateCalendars: function() {
      if (this.timePicker) {
        var time = this.getClockTime(this.endDate ? LEFT : RIGHT);
        this.leftCalendar.month.hour(time.hour).minute(time.minute).second(time.second);
        this.rightCalendar.month.hour(time.hour).minute(time.minute).second(time.second);
      }

      if (!this.timePickerOnly) {
        this.renderCalendars();
      }

      // Highlight any predefined range matching the current start and end dates
      this.getRangeList().removeClass(ACTIVE);

      if (this.endDate !== null) {
        return;
      }

      this.calculateActiveRange();
    },


    /**
     * Updates the values of the calendar date inputs based on the current start
     * and end date values.
     */
    updateFormInputs: function() {
      var startInput = this.getDateInput(LEFT);
      var endInput = this.getDateInput(RIGHT);

      // Ignore mouse movements while an above-calendar text input has focus
      if (startInput.is(":focus") || endInput.is(":focus")) {
        return;
      }

      startInput.val(this.toFormat(this.startDate));

      if (this.endDate) {
        endInput.val(this.toFormat(this.endDate));
      }

      if (this.singleDatePicker || (this.endDate && this.startDate.isSameOrBefore(this.endDate))) {
        this.getApplyButton().removeAttr(DISABLED);
      }
      else {
        this.getApplyButton().attr(DISABLED, DISABLED);
      }
    },


    /**
     * Ensures that the correct two months are represented in the left and right picker sides,
     * based on the current start and end dates.
     */
    updateMonthsInView: function() {
      var left = this.leftCalendar.month;
      var right = this.rightCalendar.month;
      var start = this.startDate;
      var end = this.endDate;

      if (end) {
        if (!this.singleDatePicker && !!left && !!right && this.isMonthInView(start) && this.isMonthInView(end)) {
          return;
        }

        this.leftCalendar.month = start.clone().date(2);

        if (!this.linkedCalendars && (end.month() !== start.month() || end.year() !== start.year())) {
          this.rightCalendar.month = end.clone().date(2);
        }
        else {
          this.rightCalendar.month = start.clone().date(2).add(1, 'month');
        }
      }
      else {
        var side = this.getSideOfMonthInView(start);

        if (side !== LEFT && side === RIGHT) {
          this.leftCalendar.month = start.clone().date(2);
          this.rightCalendar.month = start.clone().date(2).add(1, 'month');
        }
      }

      if (!this.singleDatePicker && this.linkedCalendars && this.exceedsMaxDate(right)) {
        this.rightCalendar.month = this.maxDate.clone().date(2);
        this.leftCalendar.month = this.maxDate.clone().date(2).subtract(1, 'month');
      }
    },


    /**
     * Check if the current range selection is the same as a pre-packaged range option, and if
     * so make it active.
     */
    calculateActiveRange: function() {
      var customRange = true;
      var format = this.timePicker ? (this.timePickerSeconds ? 'YYYY-MM-DD hh:mm:ss' : 'YYYY-MM-DD hh:mm') : 'YYYY-MM-DD';
      var keys = Object.keys(this.ranges);
      var start;
      var end;

      this.getRangeSelections().removeClass(ACTIVE);

      for (var i = 0; i < keys.length; i += 1 ) {
        start = this.ranges[keys[i]][0];
        end = this.ranges[keys[i]][1];

        if (this.endDate && start.format(format) === this.startDate.format(format) && end.format(format) === this.endDate.format(format)) {
          customRange = false;
          this.chosenRangeLabel = this.getRangeSelections().eq(i).addClass(ACTIVE).html();
          break;
        }
      }

      if (customRange) {
        this.chosenRangeLabel = this.showCustomRangeLabel
          ? this.getRangeSelections().last().addClass(ACTIVE).html()
          : null;
      }
    },


    /**
     * The sole purpose of this method is figure out any and every dang value that is needed to build
     * out a month's worth of calendar table cells, month and year selects, and other fantastic stuff that
     * you definitely don't care about.
     *
     * This could have all been done inside `renderCalendar`, but its a bit easier to reason out
     * what is happening by having them separate.
     */
    calculateCalendarGrid: function(side) {
      var cal   = this[side + 'Calendar'];
      var grid  = [];
      var props = {
        month   : cal.month.month(),
        year    : cal.month.year(),
        hour    : cal.month.hour(),
        minute  : cal.month.minute(),
        second  : cal.month.second(),
        minDate : side === LEFT ? this.minDate && this.minDate.clone() : this.startDate.clone(),
        maxDate : this.maxDate && this.maxDate.clone()
      };

      props.daysInMonth     = moment([props.year, props.month]).daysInMonth();
      props.firstDay        = moment([props.year, props.month, 1]);
      props.lastDay         = moment([props.year, props.month, props.daysInMonth]);
      props.lastMonth       = moment(props.firstDay).subtract(1, 'month').month();
      props.lastYear        = moment(props.firstDay).subtract(1, 'month').year();
      props.daysInLastMonth = moment([props.lastYear, props.lastMonth]).daysInMonth();
      props.dayOfWeek       = props.firstDay.day();
      props.startDay        = props.daysInLastMonth - props.dayOfWeek + this.locale.firstDay + 1;

      if (props.startDay > props.daysInLastMonth) {
        props.startDay -= 7;
      }

      if (props.dayOfWeek === this.locale.firstDay) {
        props.startDay = props.daysInLastMonth - 6;
      }

      for (var i = 0; i < 6; i += 1) {
        grid[i] = [];
      }

      var curDate = moment([props.lastYear, props.lastMonth, props.startDay, 12, props.minute, props.second]);
      var col, row;

      for (i = 0, col = 0, row = 0; i < 42; i += 1, col += 1, curDate = moment(curDate).add(24, 'hour')) {
        if (i > 0 && col % 7 === 0) {
          col = 0;
          row += 1;
        }

        grid[row][col] = curDate.clone().hour(props.hour).minute(props.minute).second(props.second);
        curDate.hour(12);

        if (side === LEFT && this.minDate && grid[row][col].isSame(this.minDate, 'day') && grid[row][col].isBefore(this.minDate)) {
          grid[row][col] = this.minDate.clone();
        }
        else if (side === RIGHT && this.maxDate && grid[row][col].isSame(this.maxDate, 'day') && grid[row][col].isAfter(this.maxDate)) {
          grid[row][col] = this.maxDate.clone();
        }
      }

      props.currentMonth  = grid[1][1].month();
      props.currentYear   = grid[1][1].year();
      props.maxYear       = (props.maxDate && props.maxDate.year()) || (props.currentYear + this.yearRangeFuture);
      props.minYear       = (props.minDate && props.minDate.year()) || (props.currentYear - this.yearRangePast);
      props.inMinYear     = props.currentYear === props.minYear;
      props.inMaxYear     = props.currentYear === props.maxYear;

      return { calendar: grid, props: props };
    },


    /**
     *
     */
    renderCalendars: function() {
      var unhookNavButtons = !this.singleDatePicker && this.linkedCalendars;

      if (unhookNavButtons) {
        this.container.find(dataSelector(DATA_CALENDAR_PREV_WRAP)).remove();
        this.container.find(dataSelector(DATA_CALENDAR_NEXT_WRAP)).remove();
      }

      this.renderCalendar(LEFT);

      if (!this.singleDatePicker) {
        this.renderCalendar(RIGHT);
      }

      if (unhookNavButtons) {
        var prevButton = this.getPrevButton();
        var nextButton = this.getNextButton();
        var wrap;

        if (nextButton.length) {
          wrap = $('<div>');
          wrap.attr(DATA_CALENDAR_NEXT_WRAP, '');
          wrap.append(nextButton.addClass(this.linkedNavClass));
          this.container.prepend(wrap);
        }

        if (prevButton.length) {
          wrap = $('<div>');
          wrap.attr(DATA_CALENDAR_PREV_WRAP, '');
          wrap.append(prevButton.addClass(this.linkedNavClass));
          this.container.prepend(wrap);
        }
      }
    },


    /**
     * Creates a calendar table and inserts it into the container on the provided side.
     */
    renderCalendar: function(side) {
      var properties  = this.calculateCalendarGrid(side);
      var p           = properties.props;
      var headerRow1  = '';
      var headerRow2  = '';
      var bodyContent = '';
      var headerDate  = this.locale.monthNames[properties.calendar[1][1].month()] + properties.calendar[1][1].format(" YYYY");
      var arrow       = this.locale.direction === 'ltr'
        ? { left: 'chevron-left', right: 'chevron-right' }
        : { left: 'chevron-right', right: 'chevron-left' };


      // Make the calendar object available to hoverDate/clickDate
      this[side + 'Calendar'].calendar = properties.calendar;

      // Arrow selector cell
      if ((!p.minDate || p.minDate.isBefore(p.firstDay)) && (!this.linkedCalendars || side === LEFT)) {
        headerRow1 += '<th>'
          + '<button type="button" class="btn btn-sm btn-default '+ concat(PREV, ' ', AVAILABLE) +'" '+ propString(DATA_CALENDAR_PREV, side) +'>'
          + '<span class="fa fa-' + arrow.left + '"></span>'
          + '<span class="sr-only">Last Month</span>'
          + '</button>'
          + '</th>';
      }
      else {
        headerRow1 += '<th></th>';
      }

      // Insert month and year selects if enabled
      if (this.showDropdowns) {
        headerDate = this.buildMonthSelectTemplate(p) + this.buildYearSelectTemplate(p);
      }

      headerRow1 += '<th colspan="'+ (this.showWeekNumbers || this.showISOWeekNumbers ? '6' : '5') +'" class="month">'+ headerDate +'</th>';

      // Arrow selector cell
      if ((!p.maxDate || p.maxDate.isAfter(p.lastDay)) && (!this.linkedCalendars || side === RIGHT || this.singleDatePicker)) {
        headerRow1 += '<th>'
          + '<button type="button" class="btn btn-sm btn-default '+ concat(NEXT, ' ', AVAILABLE) +'" '+ propString(DATA_CALENDAR_NEXT, side) +'>'
          + '<span class="fa fa-' + arrow.right + '"></span>'
          + '<span class="sr-only">Next Month</span>'
          + '</button>'
          + '</th>';
      }
      else {
        headerRow1 += '<th></th>';
      }

      // Week column label
      if (this.showWeekNumbers || this.showISOWeekNumbers) {
        headerRow2 += '<th class="week">'+ this.locale.weekLabel +'</th>';
      }

      // Days of week column labels
      $.each(this.locale.daysOfWeek, function(index, dayOfWeek) {
        headerRow2 += '<th>'+ dayOfWeek +'</th>';
      });


      // Adjust maxDate to reflect the dateLimit setting in order to
      // Grey out end dates beyond the dateLimit
      var maxDate = p.maxDate;
      var now = moment();

      if (this.endDate === null && this.dateLimit) {
        var maxLimit = this.startDate.clone().add(this.dateLimit).endOf('day');

        if (!maxDate || maxLimit.isBefore(maxDate)) {
          maxDate = maxLimit;
        }
      }

      // Build the cells of the table, one per day
      for (var row = 0; row < 6; row += 1) {
        var rowCells = [];

        for (var col = 0; col < 7; col += 1) {
          var cell = properties.calendar[row][col];
          var classNames = [];
          var cellTitle = null;

          // Grey out the dates in other months displayed at beginning and end of this calendar
          if (!cell.isSame(properties.calendar[1][1], 'month')) {
            // Skip altogether if we're not going to show off-month days
            if (!this.showOffMonthDates) {
              continue;
            }

            classNames.push('off', 'off-month');
          }

          // Highlight today's date
          if (cell.isSame(now, "day")) {
            classNames.push('today');
          }

          // Highlight weekends
          if (cell.isoWeekday() > 5) {
            classNames.push('weekend');
          }

          // Don't allow selection of dates before the minimum date
          // Don't allow selection of dates after the maximum date
          // Don't allow selection of date if a custom function decides it's invalid
          if (this.exceedsMinDate(cell, 'day') || this.exceedsMaxDate(cell, 'day') || this.isInvalidDate(cell)) {
            classNames.push('off', 'disabled');
          }
          else {
            classNames.push('available');
          }

          // Apply disabled titles
          if (this.locale.beforeMinDateTitle && this.exceedsMinDate(cell, 'day')) {
            cellTitle = this.locale.beforeMinDateTitle;
          }
          else if (this.locale.afterMaxDateTitle && this.exceedsMaxDate(cell, 'day')) {
            cellTitle = this.locale.afterMaxDateTitle;
          }


          // Highlight the currently selected start date
          if (cell.isSame(this.startDate, 'day')) {
            classNames.push('active', 'start-date');
          }

          // Highlight the currently selected end date
          if (cell.isSame(this.endDate, 'day')) {
            classNames.push('active', 'end-date');
          }

          // Highlight dates in-between the selected dates
          if (this.endDate && cell.isBetween(this.startDate, this.endDate, 'day')) {
            classNames.push('in-range');
          }

          // Apply custom classes for this date
          var isCustom = this.isCustomDate(cell);

          if (isString(isCustom)) {
            classNames.push(isCustom);
          }
          else if (isArray(isCustom)) {
            Array.prototype.push.apply(classNames, isCustom);
          }

          var dataTitle = propString(DATA_CALENDAR_CELL, 'r'+ row +'c'+ col);
          classNames = 'class="'+ classNames.join(' ').replace(/^\s+|\s+$/g, '') +'"';
          cellTitle = cellTitle ? 'title="'+ cellTitle +'"' : '';

          rowCells.push('<td '+ cellTitle +' '+ classNames +' '+ dataTitle +'>'+ cell.date() +'</td>');
        }

        if (rowCells.length) {
          // Pad the first line if needed
          if (row === 0) {
            while (rowCells.length < 7) {
              rowCells.unshift('<td></td>');
            }
          }
          // Pad the last line if needed
          else if (row === 5) {
            rowCells.push('<td></td>');
          }

          // Add week numbers if enabled
          if (this.showWeekNumbers) {
            rowCells.unshift('<td class="week">'+ properties.calendar[row][0].week() +'</td>');
          }
          else if (this.showISOWeekNumbers) {
            rowCells.unshift('<td class="week">'+ properties.calendar[row][0].isoWeek() +'</td>');
          }

          bodyContent += '<tr>'+ rowCells.join('') +'</tr>';
        }
        else {
          bodyContent += '<tr class="'+ EMPTY_ROW_CLASS +'">'
            + '<td colspan="'+ (this.showWeekNumbers || this.showISOWeekNumbers ? '8' : '7') +'"></td>'
            + '</tr>'
        }
      }


      headerRow1  = '<tr>'+ headerRow1 +'</tr>';
      headerRow2  = '<tr>'+ headerRow2 +'</tr>';
      bodyContent = '<tbody>'+ bodyContent +'</tbody>';


      this.getCalendarTable(side).html(
        '<table><thead>'+ headerRow1 + headerRow2 +'</thead>'+ bodyContent +'</table>'
      );
    },


    /**
     * Creates a series of select elements for time picking on the provided
     * side.
     */
    renderTimePicker: function(side) {
      // Don't bother updating the time picker if it's currently disabled
      // because an end date hasn't been clicked yet
      if (side === RIGHT && !this.endDate) {
        return;
      }


      if (this.lockStartDate || this.lockEndDate) {
        var staticFormat = this.timePicker24Hour ? 'HH:mm' : 'h:mm';
        staticFormat += this.timePickerSeconds ? ':ss' : '';
        staticFormat += this.timePicker24Hour ? '' : ' A';

        if (this.lockStartDate && side === LEFT) {
          this.getClock(LEFT).html('<p class="form-control-static">'+ this.startDate.format(staticFormat) +'</p>');
          return;
        }

        if (this.lockEndDate && side === RIGHT) {
          this.getClock(RIGHT).html('<p class="form-control-static">'+ this.endDate.format(staticFormat) +'</p>');
          return;
        }
      }

      var selected, minDate, maxDate = this.maxDate;

      if (maxDate) {
        maxDate = this.constrainDateToLimit(maxDate);
      }

      if (side === LEFT) {
        selected = this.startDate.clone();
        minDate = this.minDate;
      }
      else if (side === RIGHT) {
        selected = this.endDate.clone();
        minDate = this.startDate;

        var time = this.getClockTime(RIGHT);
        selected.hour(time.hour || selected.hour());
        selected.minute(time.minute || selected.minute());
        selected.second(time.second || selected.second());

        if (selected.isBefore(this.startDate)) {
          selected = this.startDate.clone();
        }

        if (maxDate && selected.isAfter(maxDate)) {
          selected = maxDate.clone();
        }
      }

      var html = '';
      html += this.buildHourSelectTemplate(selected, minDate, maxDate);
      html += ':' + this.buildMinuteSelectTemplate(selected, minDate, maxDate);

      if (this.timePickerSeconds) {
        html += ':' + this.buildSecondSelectTemplate(selected, minDate, maxDate);
      }

      if (!this.timePicker24Hour) {
        html += this.buildMeridiemSelectTemplate(selected, minDate, maxDate);
      }

      this.getClock(side).html(html);
    },


    /**
     * Modifies the apply and cancel buttons based on current configuration.
     */
    renderApplyAndCancelButtons: function() {
      if (this.applyAndCancelButtonsRendered) {
        return;
      }

      var applyButton = this.getApplyButton();
      var cancelButton = this.getCancelButton();

      // Apply CSS classes and labels to buttons
      applyButton
      .addClass(this.buttonClasses)
      .addClass(this.applyClass)
      .html(this.locale.applyLabel);

      cancelButton
      .addClass(this.buttonClasses)
      .addClass(this.cancelClass)
      .html(this.locale.cancelLabel);

      this.attachEvent(applyButton, 'click', this.onApplyClick);
      this.attachEvent(cancelButton, 'click', this.onCancelClick);

      this.applyAndCancelButtonsRendered = true;
    },


    /**
     * Show the picker popup if it is not visible.
     */
    show: function() {
      if (this.isShowing) {
        return;
      }

      this.attachEvent(document, 'click mousedown touchend', debounce(this, this.onOutsideClick));
      this.attachEvent(document, 'click', '[data-toggle=dropdown]', this.onOutsideClick);
      this.attachEvent(window, 'resize', this.repositionContainer);

      this.oldStartDate = this.startDate.clone();
      this.oldEndDate = this.endDate.clone();

      this.updateView();
      this.container.show();
      this.repositionContainer();
      this.triggerEvent(SHOW, [this]);
      this.isShowing = true;
    },


    /**
     * Hide the picker popup if it is visible.
     */
    hide: function() {
      if (!this.isShowing) {
        return;
      }

      // Incomplete date selection, revert to last values
      if (!this.endDate) {
        this.startDate = this.oldStartDate.clone();
        this.endDate = this.oldEndDate.clone();
      }

      // If a new date range was selected, invoke the user callback function
      if (!this.startDate.isSame(this.oldStartDate) || !this.endDate.isSame(this.oldEndDate)) {
        this.callback(this.startDate, this.endDate, this.chosenRangeLabel);
      }

      this.updateElement();
      this.detachAllEvents(document);
      this.detachAllEvents(window);
      this.container.hide();
      this.triggerEvent(HIDE, [this]);
      this.isShowing = false;
    },


    /**
     * Displays the calendar portion of the picker popup.
     */
    showCalendars: function() {
      this.container.addClass(CALENDAR_VISIBLE_CLASS);
      this.repositionContainer();
      this.triggerEvent(SHOW_CAL, [this]);
    },


    /**
     * Hides the calendar portion of the picker popup.
     */
    hideCalendars: function() {
      this.container.removeClass(CALENDAR_VISIBLE_CLASS);
      this.triggerEvent(HIDE_CAL, [this]);
    },


    /**
     * Toggle between show and hide based on the current open state of the picker.
     */
    toggle: function() {
      if (this.isShowing)
        this.hide();
      else
        this.show();
    },


    /**
     * Handles interaction with the outer document. Used to close the picker popup
     * when focus is lost.
     */
    onOutsideClick: function(e) {
      var t = $(e.target);
      var s = dataSelector(DATA_CALENDAR_TABLE);

      // A sloppy fix, but if the click came from a button which is part of the input group
      // of which the element is also a part, then don't consider it a close.
      var fromElement = t.closest(this.element).length;

      if (!fromElement && t.is('button')) {
        var parent = this.element.parent('.input-group');

        if (parent.length) {
          fromElement = t.closest(parent).length;
        }
      }


      // The focus in check is an IE modal dialog fix
      if (e.type === "focusin" || fromElement || t.closest(this.container).length || t.closest(s).length) {
        return;
      }

      this.triggerEvent(OUT_CLICK, [this]);
      this.handleManualDateChangeAndHide();
    },


    /**
     * Updates the picker based on changes to the bound input element.
     */
    onElementChanged: function() {
      if (!this.isInputElement) {
        return;
      }

      if (this.allowEmpty && this.isInputElementEmpty()) {
        this.manualDateChange = [null, null];
        this.setDates(moment(), moment());
      }
      else {
        var dates    = this.fromFormat(this.element.val());
        var start    = dates[0];
        var end      = dates[1];
        var areValid = start && start.isValid() && end && end.isValid();

        if (areValid) {
          this.manualDateChange = [start.clone(), end.clone()];
          this.setDates(start, end);

          if (this.linkCalendarToInput) {
            this.updateView();
          }
        }
        else {
          if (this.keepInvalid) {
            this.manualDateChange = [start, end];
          }
          else {
            this.manualDateChange = [this.oldStartDate.clone(), this.oldEndDate.clone()];
          }
        }
      }
    },


    /**
     * Handles keydown events on the bound input element.
     */
    onElementKeyDown: function(e) {
      var code = e ? e.keyCode : -1;

      // Hide on tab or enter
      if (code === 9 || code === 13) {
        this.handleManualDateChangeAndHide();
      }
      // Hide on esc and prevent propagation
      else if (code === 27) {
        e.preventDefault();
        e.stopPropagation();

        this.hide();
      }
    },


    /**
     *
     */
    handleManualDateChangeAndHide: function() {
      if (this.manualDateChange) {
        this.onApplyClick(this.manualDateChange);
        this.manualDateChange = null;
      }
      else {
        if (this.shouldForceCurrentValues()) {
          this.onApplyClick();
        }
        else {
          this.hide();
        }
      }
    },


    /**
     * Handles a click on the apply button. Can be used for other "apply-ish" actions
     * as needed.
     */
    onApplyClick: function(datesOrEvent) {
      this.hide();

      if (isArray(datesOrEvent)) {
        this.triggerEvent(APPLY, [this, datesOrEvent[0], datesOrEvent[1]]);
      }
      else {
        this.triggerEvent(APPLY, [this, this.startDate, this.endDate]);
      }
    },


    /**
     * Handles a click on the cancel button. Can be used for other "cancel-ish" actions
     * as needed.
     */
    onCancelClick: function() {
      this.startDate = this.oldStartDate;
      this.endDate = this.oldEndDate;
      this.hide();
      this.triggerEvent(CANCEL, [this]);
    },


    /**
     * Handles changes to the time select elements for both pickers.
     */
    onTimeChange: function(e) {
      var side = this.getSideFromEventTarget(e);
      var time = this.getClockTime(side);

      if (side === LEFT) {
        var start = this.startDate.clone();
        start.hour(time.hour).minute(time.minute).second(time.second);
        this.setStartDate(start);

        if (this.singleDatePicker) {
          this.endDate = this.startDate.clone();
        }
        else if (this.endDate && this.endDate.isSame(start, 'day') && this.endDate.isBefore(start)) {
          this.setEndDate(start.clone());
        }
      }
      else if (this.endDate) {
        var end = this.endDate.clone();
        end.hour(time.hour).minute(time.minute).second(time.second);
        this.setEndDate(end);
      }

      // Update the calendars so all clickable dates reflect the new time component
      this.updateCalendars();

      // Update the form inputs above the calendars with the new time
      this.updateFormInputs();

      // Re-render the time pickers because changing one selection can affect what's enabled in another
      this.renderTimePicker(LEFT);
      this.renderTimePicker(RIGHT);
    },


    /**
     * Handles changes to the month/year select elements for both pickers.
     */
    onMonthOrYearChange: function(e) {
      var side  = this.getSideFromEventTarget(e);
      var month = parseInt(this.getDateSelect(side, MONTH).val(), 10);
      var year  = parseInt(this.getDateSelect(side, YEAR).val(), 10);

      if (side !== LEFT) {
        if (year < this.startDate.year() || (year === this.startDate.year() && month < this.startDate.month())) {
          month = this.startDate.month();
          year = this.startDate.year();
        }
      }

      if (this.minDate) {
        if (year < this.minDate.year() || (year === this.minDate.year() && month < this.minDate.month())) {
          month = this.minDate.month();
          year = this.minDate.year();
        }
      }

      if (this.maxDate) {
        if (year > this.maxDate.year() || (year === this.maxDate.year() && month > this.maxDate.month())) {
          month = this.maxDate.month();
          year = this.maxDate.year();
        }
      }

      if (side === LEFT) {
        this.leftCalendar.month.month(month).year(year);

        if (this.linkedCalendars) {
          this.rightCalendar.month = this.leftCalendar.month.clone().add(1, 'month');
        }
      }
      else {
        this.rightCalendar.month.month(month).year(year);

        if (this.linkedCalendars) {
          this.leftCalendar.month = this.rightCalendar.month.clone().subtract(1, 'month');
        }
      }

      this.updateCalendars();
    },


    /**
     * Handles clicking on a date cell in one of the calendars. This function needs to do a few things:
     * - alternate between selecting a start and end date for the range,
     * - if the time picker is enabled, apply the hour/minute/second from the select boxes to the clicked date
     * - if autoapply is enabled, and an end date was chosen, apply the selection
     * - if single date picker mode, and time picker isn't enabled, apply the selection immediately
     * - if one of the inputs above the calendars was focused, cancel that manual input
     */
    onClickDate: function(e) {
      if (!this.isDayCellAvailable(e)) {
        return;
      }

      var time;
      var date = this.getDayCellDate(e);

      // Picking start
      if (!this.lockStartDate && (this.endDate || date.isBefore(this.startDate, 'day'))) {
        if (this.timePicker) {
          time = this.getClockTime(LEFT);
          date = date.clone().hour(time.hour).minute(time.minute).second(time.second);
        }

        this.endDate = null;
        this.setStartDate(date.clone());
      }
      // Special case: clicking the same date for start/end, but the time of the end date is before the start date
      else if (!this.endDate && date.isBefore(this.startDate)) {
        this.setEndDate(this.startDate.clone());
      }
      // Picking end
      else {
        if (this.timePicker) {
          time = this.getClockTime(RIGHT);
          date = date.clone().hour(time.hour).minute(time.minute).second(time.second);
        }

        this.setEndDate(date.clone());

        if (this.autoApply) {
          this.calculateActiveRange();
          this.onApplyClick();
        }
      }

      if (this.singleDatePicker) {
        this.setEndDate(this.startDate.clone());

        if (!this.timePicker) {
          this.onApplyClick();
        }
      }

      this.updateView();

      //This is to cancel the blur event handler if the mouse was in one of the inputs
      e.stopPropagation();
    },


    /**
     * Handles selection hinting while hovering over calendar day cells after before an end date
     * has been selected.
     */
    onHoverDate: function(e) {
      if (!this.isDayCellAvailable(e)) {
        return;
      }

      var date = this.getDayCellDate(e);

      if (!this.lockStartDate && this.endDate && !this.getDateInput(LEFT).is(":focus")) {
        this.getDateInput(LEFT).val(this.toFormat(date));
      }
      else if ((this.lockStartDate || !this.endDate) && !this.getDateInput(RIGHT).is(":focus")) {
        this.getDateInput(RIGHT).val(this.toFormat(date));
      }

      // Highlight the dates between the start date and the date being hovered as a potential end date
      if (!this.endDate) {
        var self = this;

        this.container.find(dataSelector(DATA_CALENDAR_CELL)).each(function(index, el) {
          el = $(el);

          // Skip week numbers, only look at dates
          if (el.hasClass(WEEK)) {
            return;
          }

          var dt = self.getDayCellDate(el);

          if (dt) {
            if ((dt.isAfter(self.startDate) && dt.isBefore(date)) || dt.isSame(date, 'day')) {
              el.addClass(IN_RANGE);
            }
            else {
              el.removeClass(IN_RANGE);
            }
          }
        });
      }
    },


    /**
     * Handles clicking on a pre-configured range selection.
     */
    onClickRange: function(e) {
      var label = e.target.getAttribute(DATA_RANGE_KEY);
      this.chosenRangeLabel = label;

      if (label === this.locale.customRangeLabel) {
        this.getRangeSelections().removeClass('ACTIVE').last().addClass(ACTIVE);
        this.showCalendars();
      }
      else {
        var dates = this.ranges[label];

        this.startDate = dates[0];
        this.endDate = dates[1];

        if (!this.timePicker) {
          this.startDate.startOf('day');
          this.endDate.endOf('day');
        }

        this.calculateActiveRange();

        if (!this.alwaysShowCalendars) {
          this.hideCalendars();
        }

        this.onApplyClick();
      }
    },


    /**
     * Handles hovering on a pre-configured range selection.
     */
    onHoverRange: function(e) {
      // Ignore mouse movements while an above-calendar text input has focus
      if (this.getDateInput(START).is(":focus") || this.getDateInput(END).is(":focus"))
        return;

      var label = e.target.getAttribute(DATA_RANGE_KEY);

      if (label === this.locale.customRangeLabel) {
        this.updateView();
      }
      else {
        var dates = this.ranges[label];
        this.getDateInput(START).val(this.toFormat(dates[0]));
        this.getDateInput(END).val(this.toFormat(dates[1]));
      }
    },


    /**
     * Handles changes to the date text inputs for both pickers.
     */
    onFormInputsChanged: function(e) {
      var side = this.getSideFromEventTarget(e);
      var start = this.toMoment(this.getDateInput(LEFT).val());
      var end = this.toMoment(this.getDateInput(RIGHT).val());

      if (start.isValid() && end.isValid()) {
        if (side === RIGHT && end.isBefore(start)) {
          start = end.clone();
        }

        this.setDates(start, end);

        if (side === RIGHT) {
          this.getDateInput(LEFT).val(this.toFormat(this.startDate));
        }
        else {
          this.getDateInput(RIGHT).val(this.toFormat(this.endDate));
        }
      }

      this.updateView();
    },


    /**
     * Highlight the focused input, and set the state such that if the user goes back to
     * using a mouse, the calendars are aware we're selecting the end of the range, not
     * the start. This allows someone to edit the end of a date range without re-selecting
     * the beginning, by clicking on the end date input then using the calendar.
     */
    onFormInputsFocused: function(e) {
      var side = this.getSideFromEventTarget(e);
      this.getDateInputGroup(side).addClass(FOCUSED_CLASS);
      this.getDateInput().removeClass(ACTIVE);
      this.getDateInput(side).addClass(ACTIVE);

      if (side === RIGHT) {
        this.endDate = null;
        this.setStartDate(this.startDate.clone());
        this.updateView();
      }
    },


    /**
     * This function has one purpose right now: if you tab from the first text input to
     * the second in the UI, the endDate is nulled so that you can click another, but
     * if you tab out without clicking anything or changing the input value, the old
     * endDate should be retained
     */
    onFormInputsBlurred: function(e) {
      var side = this.getSideFromEventTarget(e);
      this.getDateInputGroup(side).removeClass(FOCUSED_CLASS);

      if (!this.endDate) {
        var end = this.toMoment(this.getDateInput(RIGHT).val(), INVALID);

        if (end.isValid()) {
          this.setEndDate(end);
          this.updateView();
        }
      }
    },


    /**
     * This function ensures that if the 'enter' key was pressed in the input, then the calendars
     * are updated with the startDate and endDate.
     * This behaviour is automatic in Chrome/Firefox/Edge but not in IE 11 hence why this exists.
     * Other browsers and versions of IE are untested and the behaviour is unknown.
     */
    onFormInputsKeyDown: function(e) {
      // Prevent the calendar from being updated twice on Chrome/Firefox/Edge
      if (e.keyCode === 13) {
        e.preventDefault();
        this.onFormInputsChanged(e);
      }
    },


    /**
     * Repositions the picker popup around the parent element based on the `opens` and `drops`
     * configuration options.
     */
    repositionContainer: function() {
      var parentOffset = { top: 0, left: 0 };
      var containerTop;
      var parentRightEdge = $(window).width();

      if (!this.parentEl.is('body')) {
        parentOffset.top  = this.parentEl.offset().top - this.parentEl.scrollTop();
        parentOffset.left = this.parentEl.offset().left - this.parentEl.scrollLeft();
        parentRightEdge   = this.parentEl[0].clientWidth + this.parentEl.offset().left;
      }

      if (this.drops === UP) {
        containerTop = this.element.offset().top - this.container.outerHeight() - parentOffset.top;
      }
      else {
        containerTop = this.element.offset().top + this.element.outerHeight() - parentOffset.top;
      }

      this.container[this.drops === UP ? 'addClass' : 'removeClass']('dropup');

      if (this.opens === LEFT) {
        var right = parentRightEdge - this.element.offset().left - this.element.outerWidth();
        this.container.css({ top: containerTop, right: right, left: 'auto' });

        if (this.container.offset().left < 0) {
          this.container.css({ right: 'auto', left: 9 });
        }
      }
      else if (this.opens === CENTER) {
        var left = this.element.offset().left - parentOffset.left + this.element.outerWidth() / 2 - this.container.outerWidth() / 2;
        this.container.css({ top: containerTop, left: left, right: 'auto' });

        if (this.container.offset().left < 0) {
          this.container.css({ right: 'auto', left: 9 });
        }
      }
      else {
        this.container.css({ top: containerTop, left: this.element.offset().left - parentOffset.left, right: 'auto' });

        if (this.container.offset().left + this.container.outerWidth() > $(window).width()) {
          this.container.css({ left: 'auto', right: 0 });
        }
      }
    },


    /**
     * Checks whether the provided date is within the range of dates currently represented
     * in either the left or right calendar pickers.
     */
    isMonthInView: function(date) {
      return date.isSame(this.leftCalendar.month, 'month') || date.isSame(this.rightCalendar.month, 'month');
    },


    /**
     * Checks which of the two calendar pickers the provided date is represented in. Returns
     * either "left", "right", or a boolean `false`.
     */
    getSideOfMonthInView: function(date) {
      if (this.isMonthInView(date)) {
        return date.isSame(this.leftCalendar.month, 'month') ? LEFT : RIGHT;
      }

      return false;
    },


    /**
     * Attempts to parse a provided input into a moment instance. Moment, Date, and Strings
     * can be provided. The strings "now" and "invalid" are reserved and will return a moment
     * representing the current time, or an invalid moment, respectively. A fallback argument
     * may be provided, which will be returned if other parsing attempts fail.
     */
    toMoment: function(value, fallback) {
      if (moment.isMoment(value) || moment.isDate(value))
        return moment(value);

      else if (isString(value))
        return moment(value, [this.locale.format].concat(this.locale.parseFormats), this.useStrict);

      else if (fallback === 'now')
        return moment();

      else if (fallback === INVALID)
        return moment(INVALID);

      return isUndefined(fallback) ? moment(value) : fallback;
    },


    /**
     * Format the provided moment instances as a string using the locale format
     * and separator.
     */
    toFormat: function(dateA, dateB) {
      var string = '';

      if (moment.isMoment(dateA)) {
        string = dateA.format(this.locale.format);
      }

      if (!this.singleDatePicker && moment.isMoment(dateB)) {
        string += this.locale.separator + dateB.format(this.locale.format);
      }

      return string;
    },


    /**
     * Attempts to parse the provided string into a pair of moment instances by
     * splitting it at the locale separator.
     */
    fromFormat: function(string) {
      var result = [null, null];

      if (isString(string)) {
        var split = string.split(this.locale.separator);

        if (split.length === 2) {
          result[0] = this.toMoment(split[0], null, this.useStrict);
          result[1] = this.toMoment(split[1], null, this.useStrict);
        }
        else if (this.singleDatePicker && !isEmpty(string)) {
          result[0] = this.toMoment(string, null, this.useStrict);
          result[1] = this.toMoment(string, null, this.useStrict);
        }
      }

      return result;
    },


    /**
     * Creates an HTML string which will be used to generate the picker popup if no
     * custom template or jQuery object was provided.
     */
    buildDefaultPickerTemplate: function() {
      var inputTemplate = function(side, rangeCap) {
        return '<div class="date-input '+ side +'">'
          + '<div class="input-group input-group-sm" '+ propString(DATA_DATE_INPUT_GROUP, side) +'>'
          + '<span class="input-group-addon" aria-hidden="true">'
          + '<span class="fa fa-calendar"></span>'
          + '</span>'
          + '<input class="form-control" type="text" value="" aria-label="'+ rangeCap +' date" name="'+ concat(PLUGIN_NAME, '_', rangeCap) +'" />'
          + '</div>'
          + '</div>';
      };

      var calendarTemplate = function(side, rangeCap) {
        return '<div class="calendar '+ side +'" '+ propString(DATA_CALENDAR, side) +'>'
          + '<div class="calendar-table" '+ propString(DATA_CALENDAR_TABLE, side) +'></div>'
          + '<div class="calendar-time" '+ propString(DATA_CALENDAR_TIME, side) +'></div>'
          + '</div>';
      };

      return '<div class="'+ CONTAINER_CLASS +' dropdown-menu">'
        + '<div class="date-input-row">'
        + inputTemplate(LEFT, START)
        + inputTemplate(RIGHT, END)
        + '</div>'
        + '<div class="calendar-row">'
        + calendarTemplate(LEFT, START)
        + calendarTemplate(RIGHT, END)
        + '</div>'
        + '<div '+ propString('class', SIDEBAR_CLASS) +' '+ propString(DATA_SIDEBAR) +'></div>'
        + '<div class="'+ CONTROL_BTN_GROUP_CLASS +'">'
        + '<button disabled="disabled" type="button" '+ DATA_APPLY_BTN +'></button>'
        + '<button type="button" '+ DATA_CANCEL_BTN +'></button>'
        + '</div>'
        + '</div>';
    },


    /**
     * Creates an HTML string which will be used to generate the month select dropdown
     * for a calendar.
     */
    buildMonthSelectTemplate: function(calendarProps) {
      var string = '<select class="'+ SELECT_CLASS +' '+ MONTH +'" '+ propString(DATA_DATE_SELECT, MONTH) +'>';

      for (var m = 0; m < 12; m += 1) {
        var minEdgeValid = !calendarProps.inMinYear || m >= calendarProps.minDate.month();
        var maxEdgeValid = !calendarProps.inMaxYear || m <= calendarProps.maxDate.month();

        string += '<option'
          + ' value="'+ m +'"'
          + (m === calendarProps.currentMonth ? ' selected="selected"' : '')
          + (minEdgeValid && maxEdgeValid ? '' : ' disabled="disabled"')
          + '>'+ this.locale.monthNames[m] +'</option>';
      }

      return string + '</select>';
    },


    /**
     * Creates an HTML string which will be used to generate the year select dropdown
     * for a calendar.
     */
    buildYearSelectTemplate: function(calendarProps) {
      var string = '<select class="'+ SELECT_CLASS +' '+ YEAR +'" '+ propString(DATA_DATE_SELECT, YEAR) +'>';

      for (var y = calendarProps.minYear; y <= calendarProps.maxYear; y++) {
        string += '<option'
          + ' value="'+ y +'"'
          + (y === calendarProps.currentYear ? ' selected="selected"' : '')
          + '>' + y + '</option>';
      }

      return string + '</select>';
    },


    /**
     * Creates an HTML string which will be used to generate the hour select dropdown
     * for a calendar's time picker.
     */
    buildHourSelectTemplate: function(selectedDate, minDate, maxDate) {
      var string  = '<select class="'+ SELECT_CLASS +' '+ HOUR +'" '+ propString(DATA_TIME_SELECT, HOUR) +'>';
      var start   = this.timePicker24Hour ? 0 : 1;
      var end     = this.timePicker24Hour ? 23 : 12;

      for (var i = start; i <= end; i += 1) {
        var iIn24 = i;

        if (!this.timePicker24Hour) {
          iIn24 = selectedDate.hour() >= 12 ? (i === 12 ? 12 : i + 12) : (i === 12 ? 0 : i);
        }

        var time      = selectedDate.clone().hour(iIn24);
        var disabled  = (minDate && time.minute(59).isBefore(minDate)) || (maxDate && time.minute(0).isAfter(maxDate));

        string += '<option'
          + ' value="'+ i +'"'
          + (iIn24 === selectedDate.hour() && ! disabled ? ' selected="selected"' : '')
          + (disabled ? ' disabled="disabled" class="disabled"' : '')
          + '>'+ i +'</option>';
      }

      return string + '</select>';
    },


    /**
     * Creates an HTML string which will be used to generate the minute select dropdown
     * for a calendar's time picker.
     */
    buildMinuteSelectTemplate: function(selectedDate, minDate, maxDate) {
      var string = '<select class="'+ SELECT_CLASS +' '+ MINUTE +'" '+ propString(DATA_TIME_SELECT, MINUTE) +'>';
      var increment = isNaN(this.timePickerIncrement) ? 1 : this.timePickerIncrement;
      increment = increment < 1 ? 1 : increment;

      for (var i = 0; i < 60; i += increment) {
        var padded    = i < 10 ? '0' + i : i;
        var time      = selectedDate.clone().minute(i);
        var disabled  = (minDate && time.second(59).isBefore(minDate)) || (maxDate && time.second(0).isAfter(maxDate));

        string += '<option'
          + ' value="'+ i +'"'
          + (i === selectedDate.minute() && ! disabled ? ' selected="selected"' : '')
          + (disabled ? ' disabled="disabled" class="disabled"' : '')
          + '>'+ padded +'</option>';
      }

      return string + '</select>';
    },


    /**
     * Creates an HTML string which will be used to generate the second select dropdown
     * for a calendar's time picker.
     */
    buildSecondSelectTemplate: function(selectedDate, minDate, maxDate) {
      var string = '<select class="'+ SELECT_CLASS +' '+ SECOND +'" '+ propString(DATA_TIME_SELECT, SECOND) +'>';

      for (var i = 0; i < 60; i += 1) {
        var padded    = i < 10 ? '0' + i : i;
        var time      = selectedDate.clone().second(i);
        var disabled  = (minDate && time.isBefore(minDate)) || (maxDate && time.isAfter(maxDate));

        string += '<option'
          + ' value="'+ i +'"'
          + (i === selectedDate.second() && ! disabled ? ' selected="selected"' : '')
          + (disabled ? ' disabled="disabled" class="disabled"' : '')
          + '>'+ padded +'</option>';
      }

      return string + '</select>';
    },


    /**
     * Creates an HTML string which will be used to generate the meridiem select dropdown
     * for a calendar's time picker.
     */
    buildMeridiemSelectTemplate: function(selectedDate, minDate, maxDate) {
      var string  = '<select class="'+ SELECT_CLASS +' '+ MERIDIEM +'" '+ propString(DATA_TIME_SELECT, MERIDIEM) +'>';
      var amAttrs = '';
      var pmAttrs = '';

      if (minDate && selectedDate.clone().hour(12).minute(0).second(0).isBefore(minDate)) {
        amAttrs = ' disabled="disabled" class="disabled"';
      }

      if (maxDate && selectedDate.clone().hour(0).minute(0).second(0).isAfter(maxDate)) {
        pmAttrs = ' disabled="disabled" class="disabled"';
      }

      string += '<option value="AM"'
        + amAttrs + (selectedDate.hour() < 12 ? ' selected="selected"' : '')
        + '>AM</option>'
        + '<option value="PM"'
        + pmAttrs + (selectedDate.hour() >= 12 ? ' selected="selected"' : '')
        + '>PM</option>';

      return string + '</select>';
    },


    /**
     * Creates an HTML string with will be used to generate the pre-configured range
     * selection button list, and optionally the "custom range" button if enabled.
     */
    buildRangeOptionsTemplate: function() {
      var keys = Object.keys(this.ranges);
      var list = '';

      if (keys.length) {
        list = '<ul '+ propString(DATA_RANGE_LIST) +'>';
        var cnames = concat(this.buttonClasses, ' ', this.rangeClass);

        for (var i = 0; i < keys.length; i += 1) {
          list += '<li>'
            +'<button type="button" '+ propString('class', cnames) +' '+ propString(DATA_RANGE_KEY, keys[i]) +'>'+ keys[i] +'</button>'
            + '</li>';
        }

        if (this.showCustomRangeLabel) {
          list += '<li>'
            +'<button type="button" '+ propString('class', cnames) +' '+ propString(DATA_RANGE_KEY, this.locale.customRangeLabel) +'>'+ this.locale.customRangeLabel +'</button>'
            + '</li>';
        }

        list += '</ul>';
      }

      return list;
    },


    /**
     * Checks whether a given calendar day cell is available for selection.
     */
    isDayCellAvailable: function(target) {
      target = $(target.target || target);
      return target.hasClass(AVAILABLE);
    },


    /**
     * Retrieves the moment instance that the given calendar day cell represents.
     */
    getDayCellDate: function(target) {
      target = $(target.target || target);

      var side = this.getSideFromEventTarget(target);
      var data = target.attr(DATA_CALENDAR_CELL);

      if (!isString(data)) {
        return null;
      }

      var row = data.substr(1, 1);
      var col = data.substr(3, 1);

      if (isEmpty(row) || isEmpty(col)) {
        return null;
      }

      return side === LEFT ? this.leftCalendar.calendar[row][col] : this.rightCalendar.calendar[row][col];
    },


    /**
     * Attached a namespaced event to the target. The callback is always proxied, so the scope
     * of `this` is maintained.
     */
    attachEvent: function(target, eventName, delegateOrCallback, callback) {
      var delegate = isString(delegateOrCallback);
      callback = isFunction(delegateOrCallback) ? delegateOrCallback : callback;
      eventName = eventName.split(' ').map(function(item) { return item +'.'+ PLUGIN_NAME }).join(' ');

      if (delegate)
        $(target).on(eventName, delegateOrCallback, $.proxy(callback, this));
      else
        $(target).on(eventName, $.proxy(callback, this));
    },


    /**
     * Removes all namespaced event listeners from the target.
     */
    detachAllEvents: function(target) {
      $(target).off('.'+ PLUGIN_NAME);
    },


    /**
     * Triggers a DatePicker specific event. These are events like
     * "apply", "cancel", "open", and "close".
     */
    triggerEvent: function(name, eventArgs) {
      this.element.trigger(name +'.'+ PLUGIN_NAME, eventArgs);
    },


    /**
     * Gets the side, "left" or "right", of the picker that the event propagated from
     * based on the original target of that event. Answers the question "which calendar
     * should this interaction effect?"
     */
    getSideFromEventTarget: function(e) {
      var target = $(e.target || e);
      var selectors = dataSelector(DATA_CALENDAR)
        + ','
        + dataSelector(DATA_DATE_INPUT_GROUP)
        + ','
        + dataSelector(DATA_CALENDAR_PREV)
        + ','
        + dataSelector(DATA_CALENDAR_NEXT);

      var ancestor = target.closest(selectors);

      if (ancestor.length) {
        return ancestor.attr(DATA_CALENDAR)
          || ancestor.attr(DATA_DATE_INPUT_GROUP)
          || ancestor.attr(DATA_CALENDAR_PREV)
          || ancestor.attr(DATA_CALENDAR_NEXT)
      }

      return null;
    },


    /**
     *
     */
    getCalendarSide: function(side) {
      return this.container.find(dataSelector(DATA_CALENDAR, side));
    },


    /**
     *
     */
    getDateInputGroup: function(side) {
      return this.container.find(dataSelector(DATA_DATE_INPUT_GROUP, side));
    },


    /**
     *
     */
    getDateInput: function(side) {
      return this.container.find(dataSelector(DATA_DATE_INPUT_GROUP, side) + ' input');
    },


    /**
     *
     */
    getDateSelect: function(side, part) {
      var results = this.container.find(dataSelector(DATA_CALENDAR_TABLE, side));

      if (isString(part)) {
        return results.find(dataSelector(DATA_DATE_SELECT, part));
      }

      return results;
    },


    /**
     *
     */
    getCalendarTable: function(side) {
      return this.container.find(dataSelector(DATA_CALENDAR_TABLE, side));
    },


    /**
     *
     */
    getPrevButton: function(side) {
      return this.container.find(dataSelector(DATA_CALENDAR_PREV, side));
    },


    /**
     *
     */
    getNextButton: function(side) {
      return this.container.find(dataSelector(DATA_CALENDAR_NEXT, side));
    },


    /**
     *
     */
    getApplyButton: function() {
      return this.container.find(dataSelector(DATA_APPLY_BTN));
    },


    /**
     *
     */
    getCancelButton: function() {
      return this.container.find(dataSelector(DATA_CANCEL_BTN));
    },


    /**
     *
     */
    getSidebar: function() {
      return this.container.find(dataSelector(DATA_SIDEBAR));
    },


    /**
     *
     */
    getRangeList: function() {
      return this.container.find(dataSelector(DATA_RANGE_LIST));
    },


    /**
     *
     */
    getRangeSelections: function() {
      return this.container.find(dataSelector(DATA_RANGE_LIST)).find('button');
    },


    /**
     *
     */
    getClock: function(side, part) {
      if (!this.timePicker) {
        return null;
      }

      var results = this.container.find(dataSelector(DATA_CALENDAR_TIME, side));

      if (isString(part)) {
        return results.find(dataSelector(DATA_TIME_SELECT, part));
      }

      return results;
    },


    /**
     *
     */
    getClockTime: function(side) {
      var results = {};

      if (this.timePicker) {
        results.hour    = parseInt(this.getClock(side, HOUR).val(), 10);
        results.minute  = parseInt(this.getClock(side, MINUTE).val(), 10)
        results.second  = this.timePickerSeconds ? parseInt(this.getClock(side, SECOND).val(), 10) : 0;

        if (!this.timePicker24Hour) {
          results.meridiem = this.getClock(side, MERIDIEM).val();
          results.meridiemHour = results.hour;

          if (results.meridiem === 'PM' && results.hour < 12) {
            results.hour += 12;
          }

          if (results.meridiem === 'AM' && results.hour === 12) {
            results.hour = 0;
          }
        }
      }

      return results;
    },


    /**
     *
     */
    remove: function() {
      this.container.remove();
      this.detachAllEvents(this.element);
      this.element.removeData();
    },
  };



  // *******************
  // Register with jQuery
  // *******************
  $.fn[PLUGIN_NAME] = function(options, callback) {
    var implementOptions = $.extend(true, {}, $.fn[PLUGIN_NAME].defaultOptions, options);

    this.each(function() {
      var el = $(this);

      if (el.data(PLUGIN_NAME)) {
        el.data(PLUGIN_NAME).remove();
      }

      el.data(PLUGIN_NAME, new DateRangePicker(el, implementOptions, callback));
    });
    return this;
  };

  return DateRangePicker;
}));
