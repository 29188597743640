define("@nsf/ember-properties-loader/utils/object-merge", ["exports", "@nsf/ember-properties-loader/utils/is-object"], function (_exports, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ObjectMerge {
    /**
     * Create a deep copy of an object or array.
     */
    static clone(input) {
      const output = input;
      if (Array.isArray(input)) {
        return input.map(item => ObjectMerge.clone(item));
      } else if ((0, _isObject.isObject)(input)) {
        return Object.keys(input).reduce((acc, cur) => {
          acc[cur] = ObjectMerge.clone(input[cur]);
          return acc;
        }, {});
      }
      return output;
    }

    /**
     * Perform a shallow merge of two or more objects.
     */
    static shallow() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return ObjectMerge._merge(true, false, ...args);
    }

    /**
     * Perform a recursive merge of two or more objects.
     */
    static deep() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }
      return ObjectMerge._merge(true, true, ...args);
    }

    /**
     *
     */
    static _merge() {
      let clone = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      let recursive = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      for (var _len3 = arguments.length, targets = new Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
        targets[_key3 - 2] = arguments[_key3];
      }
      if (!targets || targets.length === 0) {
        return {};
      }
      const resultTarget = clone || !(0, _isObject.isObject)(targets[0]) ? {} : targets[0];
      for (let i = 0; i < targets.length; i += 1) {
        const target = targets[i];
        if (!(0, _isObject.isObject)(target) || !clone && i === 0) {
          continue;
        }
        for (const key in target) {
          if ({}.hasOwnProperty.call(target, key)) {
            const val = clone ? ObjectMerge.clone(target[key]) : target[key];
            if (recursive) {
              resultTarget[key] = ObjectMerge._mergeRecurse(resultTarget[key], val);
            } else {
              resultTarget[key] = val;
            }
          }
        }
      }
      return resultTarget;
    }

    /**
     * Step through the properties of `source`, copying them to `target`.
     */
    static _mergeRecurse(target, source) {
      if (!((0, _isObject.isObject)(target) && (0, _isObject.isObject)(source))) {
        return ObjectMerge.clone(source);
      }
      for (const key in source) {
        target[key] = (0, _isObject.isObject)(target[key]) && (0, _isObject.isObject)(source[key]) ? ObjectMerge._mergeRecurse(target[key], source[key]) : source[key];
      }
      return target;
    }
  }
  _exports.default = ObjectMerge;
});