define("@nsf-open/ember-general-utils/helpers/guid-for", ["exports", "@ember/component/helper", "@ember/object/internals"], function (_exports, _helper, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * An Ember template helper to generate a unique ID for an object using Ember's `guidFor()`
   * utility method. An optional suffix can also be provided so that the same object
   * can be used to derive multiple unique IDs.
   *
   * ```handlebars
   * {{! A globally unique ID for @someValue }}
   * {{guid-for @someValue}}
   *
   * {{! The GUID of @someValue suffixed with "-foo" }}
   * {{guid-for @someValue "foo"}}
   * ```
   *
   * @function guid-for
   * @category helpers
   */
  var _default = _exports.default = (0, _helper.helper)(function guidForHelperFunction(_ref) {
    let [target, suffix] = _ref;
    const guid = (0, _internals.guidFor)(target);
    return typeof suffix === 'string' ? `${guid}-${suffix}` : guid;
  });
});