define("@nsf/ember-oauth-iam/utils/timed-event-planner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * The TimedEventPlanner class is the solution to a peculiar need. Given a counter
   * which is ticking away at some given speed in some given direction (counting either
   * up or down), how do you have callback methods execute once and only once when that
   * counter passes an arbitrary number of particular values?
   */
  class TimedEventPlanner {
    /**
     * @param {Array<Number>} timings An array of numbers.
     * @param {Function} callback The method to execute when conditions are met.
     * @param {Boolean} [descending=false] Whether the counter value expected to
     * be ascending or descending in value.
     */
    constructor(timings, callback) {
      let descending = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      _defineProperty(this, "timings", void 0);
      _defineProperty(this, "callback", void 0);
      _defineProperty(this, "descending", void 0);
      _defineProperty(this, "currentIdx", void 0);
      let timingValues = Array.isArray(timings) ? timings : [timings];
      timingValues = timingValues.map(value => typeof value === 'string' ? parseInt(value, 10) : value).sort((a, b) => a - b);
      if (descending) {
        timingValues = timingValues.reverse();
      }
      this.timings = timingValues.map(item => {
        return {
          timestamp: item,
          wasCalled: false
        };
      });
      this.callback = callback;
      this.descending = descending;
      this.currentIdx = 0;
    }
    update(time) {
      const idx = this.currentIdx;
      const entry = this.timings[idx];
      if (entry && (this.descending && time <= entry.timestamp || !this.descending && time >= entry.timestamp)) {
        if (!entry.wasCalled) {
          this.timings[idx].wasCalled = true;
          this.currentIdx += 1;
          this.callback.apply(this, [idx, entry.timestamp, time]);
          return true;
        }
      }
      return false;
    }
    reset() {
      this.currentIdx = 0;
      this.timings.forEach(item => {
        item.wasCalled = false;
      });
    }
  }
  _exports.default = TimedEventPlanner;
});