define("@nsf/ember-oauth-iam/utils/typed-ember-simple-auth-reexports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SessionService = _exports.InternalSession = _exports.CookieStore = _exports.BaseAuthenticator = void 0;
  _exports.getDefaultExport = getDefaultExport;
  // ember-simple-auth is not typed as of the time of this writing, but this package needs
  // to extend from it anyway. This utility module obscures the extra bits of work needed
  // to make TS happy when this package is published.

  /**
   * Retrieve a module's default export using `require`.
   */
  function getDefaultExport(packageName) {
    // @ts-expect-error
    return require(packageName, null, null, true).default;
  }

  /**
   * The base class for all authenticators. __This serves as a starting point for
   * implementing custom authenticators and must not be used directly.__
   *
   * @see https://github.com/mainmatter/ember-simple-auth/blob/master/packages/ember-simple-auth/addon/authenticators/base.js
   */
  const BaseAuthenticator = _exports.BaseAuthenticator = getDefaultExport('ember-simple-auth/authenticators/base');

  /**
   * Session store that persists data in a cookie.
   *
   * @see https://github.com/mainmatter/ember-simple-auth/blob/master/packages/ember-simple-auth/addon/session-stores/cookie.js
   */
  const CookieStore = _exports.CookieStore = getDefaultExport('ember-simple-auth/session-stores/cookie');

  /**
   * @see https://github.com/mainmatter/ember-simple-auth/blob/master/packages/ember-simple-auth/addon/internal-session.js
   */
  const InternalSession = _exports.InternalSession = getDefaultExport('ember-simple-auth/internal-session');

  /**
   * __The session service provides access to the current session as well as
   *   methods to authenticate it, invalidate it, etc.__ It is the main interface for
   *   the application to Ember Simple Auth's functionality.
   *
   * @see https://github.com/mainmatter/ember-simple-auth/blob/master/packages/ember-simple-auth/addon/services/session.js
   */
  const SessionService = _exports.SessionService = getDefaultExport('ember-simple-auth/services/session');
});