// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, set } from '@ember/object';
import { layout, classNames } from '@ember-decorators/component';
import template from './template';

/**
 * A vertical navigation list pre-configured to Research.gov styling and functional standards.
 * Provides a top-level button to expand and collapse the all content, and yields helpers to
 * easily implement nested collapsing child groups.
 *
 * ```htmlbars
 * <RgovSidenav as |Nav|>
 *   <Nav.Item>
 *     <LinkTo @route="home">Home</LinkTo>
 *   </Nav.Item>
 *
 *   <Nav.Item @text="About" @onClick={{this.showAboutModal}} />
 *
 *   <Nav.Group @label="Your Profile">
 *     <Nav.Item>
 *       <LinkTo @route="user.profile">View/Edit</LinkTo>
 *     </Nav.Item>
 *   </Nav.Group>
 *
 *   <Nav.Item>
 *    <LinkTo @route="logout">Logout</LinkTo>
 *   </Nav.Item>
 * </RgovSidenav>
 * ```
 *
 * @class RGovSideNavComponent
 */
@classNames('rgov-sidenav')
@layout(template)
// eslint-disable-next-line ember/require-tagless-components
export default class RGovSideNav extends Component {

  /**
   * The string shown in the global toggle button when the menu is collapsed.
   *
   * @property showText
   * @type {string}
   * @default "Show Menu"
   */
  showText = 'Show Menu';

  /**
   * The string shown in the global toggle button when the menu is expanded.
   *
   * @property hideText
   * @type {string}
   * @default "Hide Menu"
   */
  hideText = 'Hide Menu';

  isOpen = true;

  disabled = false;

  onBeginOpening?: () => void;

  onFinishOpening?: () => void;

  onBeginClosing?: () => void;

  onFinishClosing?: () => void;


  @action
  toggleCollapsedState() {
    set(this, 'isOpen', !this.isOpen);
  }
}