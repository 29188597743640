define("@nsf/ember-semantic-content-data/configuration", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getConfig = getConfig;
  _exports.initializeConfiguration = initializeConfiguration;
  _exports.resetConfig = resetConfig;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const Defaults = {
    domain: '',
    namespace: '/semantic-content/',
    rootURL: '/'
  };

  /**
   * To be called once by the application initializer to bake in the configuration defaults.
   */
  function initializeConfiguration() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    Object.assign(Defaults, options);
  }
  let singleton;

  /**
   * Retrieves the singleton configuration instance.
   */
  function getConfig(values) {
    if (!singleton) {
      singleton = new SemanticContentDataConfiguration(Defaults);
    }
    if (values) {
      singleton.update(values);
    }
    return singleton;
  }

  /**
   * Deletes the singleton configuration instance, if it exists. A new one will
   * be created with the next call to `getConfig`.
   */
  function resetConfig() {
    singleton = undefined;
  }

  /**
   * @nsf/ember-semantic-content-data configuration.
   */
  let SemanticContentDataConfiguration = _exports.default = (_dec = (0, _object.computed)('domain', 'namespace'), (_class = class SemanticContentDataConfiguration {
    constructor(values) {
      /** The endpoint domain. */
      _defineProperty(this, "domain", Defaults.domain);
      /** The endpoint namespace. */
      _defineProperty(this, "namespace", Defaults.namespace);
      /** The rootURL as configured by the application. */
      _defineProperty(this, "rootURL", Defaults.rootURL);
      if (values) {
        this.update(values);
      }
    }
    /** The concatenated domain and namespace. */
    get basePath() {
      return `${this.domain}${this.namespace}`;
    }
    update(values) {
      const keys = Object.keys(values);
      // @ts-expect-error - mapping values back onto this object can be tricky
      keys.forEach(key => (0, _object.set)(this, key, values[key]));
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "basePath", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "basePath"), _class.prototype)), _class));
});