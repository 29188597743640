define("@nsf/ember-oauth-iam/components/oauth-iam-screen-overlay/component", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember-decorators/component", "@nsf/ember-oauth-iam/components/oauth-iam-screen-overlay/template"], function (_exports, _component, _object, _service, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor; // @ts-expect-error
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @class OAuthIAMScreenOverlayComponent
   */
  let OAuthIamScreenOverlayComponent = _exports.default = (_dec = (0, _component2.classNames)('iam-modal'), _dec2 = (0, _component2.layout)(_template.default), _dec3 = (0, _object.computed)('elementId'), _dec4 = (0, _object.computed)('modalId'), _dec5 = (0, _object.computed)('modalId'), _dec(_class = _dec2(_class = (_class2 = class OAuthIamScreenOverlayComponent extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "stopActivityMonitor", false);
      _defineProperty(this, "title", void 0);
      _defineProperty(this, "cancelButtonAction", void 0);
      _defineProperty(this, "lastFocusedElement", void 0);
      _initializerDefineProperty(this, "session", _descriptor, this);
    }
    get modalId() {
      return `${this.elementId}-modal`;
    }
    get describedById() {
      return `describes-${this.modalId}`;
    }
    get labelledById() {
      return `labels-${this.modalId}`;
    }
    didInsertElement() {
      super.didInsertElement();
      if (this.stopActivityMonitor) {
        this.session.activityMonitor?.stop();
      }
      this.lastFocusedElement = document.activeElement;
      document.body.classList.add('iam-modal-open');
      this.element.querySelector('button')?.focus();
    }
    willDestroyElement() {
      if (this.stopActivityMonitor) {
        this.session.activityMonitor?.start();
      }
      if (this.lastFocusedElement instanceof HTMLElement) {
        this.lastFocusedElement.focus();
      }
      document.body.classList.remove('iam-modal-open');
      super.willDestroyElement();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "modalId", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "modalId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "describedById", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "describedById"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "labelledById", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "labelledById"), _class2.prototype)), _class2)) || _class) || _class);
});