define("@nsf-open/ember-datetime-utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PARSE_FORMATS = _exports.MIDDLE_ENDIAN_TIME_FORMAT = _exports.MIDDLE_ENDIAN_DATE_FORMAT = _exports.MIDDLE_ENDIAN_DATETIME_FORMAT = _exports.BIG_ENDIAN_TIME_FORMAT = _exports.BIG_ENDIAN_DATE_FORMAT = _exports.BIG_ENDIAN_DATETIME_FORMAT = void 0;
  const MIDDLE_ENDIAN_DATE_FORMAT = _exports.MIDDLE_ENDIAN_DATE_FORMAT = 'MM/DD/YYYY';
  const MIDDLE_ENDIAN_TIME_FORMAT = _exports.MIDDLE_ENDIAN_TIME_FORMAT = 'h:mm A';
  const MIDDLE_ENDIAN_DATETIME_FORMAT = _exports.MIDDLE_ENDIAN_DATETIME_FORMAT = 'MM/DD/YYYY h:mm A';
  const BIG_ENDIAN_DATE_FORMAT = _exports.BIG_ENDIAN_DATE_FORMAT = 'YYYY-MM-DD';
  const BIG_ENDIAN_TIME_FORMAT = _exports.BIG_ENDIAN_TIME_FORMAT = 'HH:mm:ss.SSS';
  const BIG_ENDIAN_DATETIME_FORMAT = _exports.BIG_ENDIAN_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSS';
  const PARSE_FORMATS = _exports.PARSE_FORMATS = ['YYYY-MM-DD', 'MM/DD/YYYY', 'MM/DD/YYYY h:mm A', 'MM/DD/YYYY HH:mm:ss', 'MM/DD/YYYY h:mm A ZZ', 'YYYY-MM-DD[T]HH:mm:ss', 'YYYY-MM-DD[T]HH:mm:ssZZ', 'YYYY-MM-DD[T]HH:mm:ss.SSS', 'YYYY-MM-DD[T]HH:mm:ss.SSSZZ'];
});