define("@nsf/ember-webtrend-analytics/index", ["exports", "@nsf/ember-webtrend-analytics/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "WebtrendsEvents", {
    enumerable: true,
    get: function () {
      return _constants.WebtrendsEvents;
    }
  });
  Object.defineProperty(_exports, "WebtrendsTags", {
    enumerable: true,
    get: function () {
      return _constants.WebtrendsTags;
    }
  });
});