define("@nsf/ember-oauth-iam/errors/invalid-session-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Represents an invalid session. This will happen when session data is expired, or when
   * only partial session information is available.
   */
  class InvalidSessionError extends Error {
    constructor() {
      super('The session cannot be restored with currently available information.');
      this.name = 'Invalid Session';
    }
  }
  _exports.default = InvalidSessionError;
});