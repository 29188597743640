
import { isEmpty } from '@ember/utils';

const DEFAULT_LOCALE = 'en-US';
const DATE_OPTIONS = {
    month:  'numeric',
    day:    'numeric',
    year:   'numeric',
};
/**
 * Reformats a date according to format specified by default returns MM/DD/YYYY format.
 * 
 * formatDateTime(1611245724633) => 1/21/2021
 * 
 * options can be passed as second param to render date in certain formats
 * see below for all the options choices
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
 * 
 * const options = { month: 'numeric', 
 *  day: 'numeric', 
 *  year: 'numeric', 
 *  hour: 'numeric',
 *  minute: 'numeric',
 *  timeZone: 'America/New_York',
 *  timeZoneName: 'short'};
 * 
 *  formatDateTime(1611245724633, options) => 1/21/2021 11:15 AM EST
 * 
 * Optionally, a boolean value can be passed as the third parameter to preserve the comma in the date string format.
 * 
 *  formatDateTime(1611245724633, options, true) => January 21, 2021
 */
export function formatDateTime(dateToFormat: number | Date | string, options?: object, commaIncludedDateString?:boolean): Date | string {
    if (isEmpty(dateToFormat)) {
        return '';
    }
    const dateObject = new Date(dateToFormat);

    const dateOptions = (!isEmpty(options)) ? options : DATE_OPTIONS;

    let formattedDate = dateObject.toLocaleString(DEFAULT_LOCALE, dateOptions);

    formattedDate = formattedDate.replace('AM', 'a.m.').replace('PM', 'p.m.');

    return (commaIncludedDateString) ? formattedDate : formattedDate.replace(',','');
}

/**
 * Checks if the provided checkDate is within the range defined by the given startDate and endDate.
 * 
 * @param {number | Date | string} startDate - Represents the start date, which can be provided as a Unix timestamp, a JavaScript Date object, or a string representation of the date.
 * @param {number | Date | string} endDate - Represents the start date, which can be provided as a Unix timestamp, a JavaScript Date object, or a string representation of the date.
 * @param {number | Date | string} checkDate - Represents the start date, which can be provided as a Unix timestamp, a JavaScript Date object, or a string representation of the date.
 * @returns {boolean} Returns true if the checkDate falls within the range defined by startDate and endDate; otherwise, returns false.
 * 
 * isDateInRange(1722459600000, 1725138000000, '2024-08-21') => true
 * 
 */
export function isDateInRange(startDate: number | Date | string | null | undefined, endDate: number | Date | string | null | undefined, checkDate: number | Date | string | null | undefined): boolean {
  // Check if any of the dates is null or undefined
  if (startDate === null || endDate === null || checkDate === null) {
    return false; 
  }

  if (startDate && endDate && checkDate) {
    const checkDateTimestamp = new Date(checkDate).getTime();
    const startDateTimestamp = new Date(startDate).getTime();
    const endDateTimestamp = new Date(endDate).getTime();

    // Check if checkDate is between startDate and endDate
    return checkDateTimestamp >= startDateTimestamp && checkDateTimestamp <= endDateTimestamp;
  }

  return false;
}

/**
 * Formats the given date to the start of the day in the format 'YYYY-MM-DDT00:00:00'.
 * @param {Date} date - The date object to be formatted.
 * @returns {string} The formatted date string.
 */
export function formatDateToStartOfDay(date: number | Date | string | null | undefined): string {
  // Check if the date is truthy (not null or undefined)
  // If truthy, format the date to 'YYYY-MM-DDT00:00:00'
  // If not truthy, return an empty string
  return date ? new Date(date).toISOString().split('T')[0] + 'T00:00:00' : '';
}

