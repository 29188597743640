// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import template from './template';
import { layout } from '@ember-decorators/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

@layout(template)
// eslint-disable-next-line ember/require-tagless-components
export default class RgovActionMenu extends Component {
    /**
     * The title text of the panel.
     *
     * This can also be set as the first positional parameter of the component.
     */
    public heading?: string;

    /**
     * Add one or more buttons to the action menu.
     *
     * <RGovActionMenu
     *   @heading="Action Menu"
     *   @menuButtons={{array (hash text="Delete User" variant="primary" onClick=this.deleteUser)}}
     * >
     * </RGovActionMenu>
     */
    readonly menuButtons?: [];

    @computed('menuButtons.[]')
    public get hasMenuButtons() {
        return (this.menuButtons?.length ?? 0) > 0;
    }
}
