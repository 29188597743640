define("@nsf-open/ember-general-utils/utils/arrays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.intersection = intersection;
  /**
   * Given two array arguments, creates a new array that contains only the elements
   * found in both arguments.
   */
  function intersection(arrayA, arrayB) {
    if (Array.isArray(arrayA) && Array.isArray(arrayB)) {
      return arrayA.filter(item => arrayB.includes(item));
    }
    return [];
  }
});