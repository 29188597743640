define("@nsf-open/ember-currency-utils/formatters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fromUSDFormat = fromUSDFormat;
  _exports.toUSDFormat = toUSDFormat;
  /** Configuration options for the toUSD() formatter. */

  /**
   * Converts a numerical value to USD currency. Both numbers and parsable strings
   * are supported.
   *
   * ```javascript
   * toUSD(12345); // => "$12,345"
   *
   * toUSD(12345, { cents: true }); // => "$12,345.00"
   * ```
   */
  function toUSDFormat(value) {
    let showCentsOrOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const parsedValue = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(parsedValue)) {
      // @ts-expect-error - This method needs to be updated in a breaking
      // fashion to really make it make sense. It should throw an exception
      // or return undefined when it fails to format.
      return value;
    }
    const defaultOpts = {
      cents: false,
      currencySymbol: true,
      accountingFormat: false
    };
    const opts = typeof showCentsOrOptions === 'boolean' ? {
      ...defaultOpts,
      cents: showCentsOrOptions
    } : {
      ...defaultOpts,
      ...showCentsOrOptions
    };
    const formatterOpts = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
      // Not currently supported in Safari, but when it is we can let
      // the Internationalization lib do this work.
      // currencySign: opts.accountingFormat ? 'accounting' : 'standard',
    };
    if (!opts.cents) {
      formatterOpts.minimumFractionDigits = 0;
      formatterOpts.maximumFractionDigits = 0;
    }
    let formattedResult = new Intl.NumberFormat('en-US', formatterOpts).format(parsedValue);
    if (!opts.currencySymbol) {
      formattedResult = formattedResult.replace('$', '');
    }
    if (opts.accountingFormat && parsedValue < 0) {
      formattedResult = `(${formattedResult.replace('-', '')})`;
    }
    return formattedResult;
  }

  /**
   * Converts a formatted currency value to a float.
   *
   * ```javascript
   * fromUSD("$12,345"); // => 12345
   *
   * fromUSD("$12,345.55"); // => 12345.55
   *
   * fromUSD("(12,345.55)"); // => -12345.55
   * ```
   */
  function fromUSDFormat(value) {
    if (typeof value === 'string') {
      let val = value.trim();

      // Accounting format negative value
      if (val.startsWith('(') && val.endsWith(')')) {
        val = `-${val.substring(1, val.length - 1)}`;
      }
      return parseFloat(val.replace(/[^0-9\-.]/g, ''));
    }
    return typeof value === 'number' ? value : NaN;
  }
});