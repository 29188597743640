define("@nsf/ember-oauth-iam/errors/index", ["exports", "@nsf/ember-oauth-iam/errors/fetch-error", "@nsf/ember-oauth-iam/errors/invalid-session-error"], function (_exports, _fetchError, _invalidSessionError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "FetchError", {
    enumerable: true,
    get: function () {
      return _fetchError.default;
    }
  });
  Object.defineProperty(_exports, "InvalidSessionError", {
    enumerable: true,
    get: function () {
      return _invalidSessionError.default;
    }
  });
});