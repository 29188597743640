define("@nsf/ember-semantic-content-data/services/semantic-content-data", ["exports", "@ember/service", "fetch", "rsvp", "@nsf/ember-semantic-content-data/configuration"], function (_exports, _service, _fetch, _rsvp, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FetchError = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class FetchError extends Error {
    constructor(response) {
      super(response.statusText);
      _defineProperty(this, "response", void 0);
      _defineProperty(this, "status", void 0);
      this.response = response;
      this.status = response.status;
    }
  }

  /**
   * The Research.gov Semantic Content API.
   */
  _exports.FetchError = FetchError;
  class SemanticContentData extends _service.default {
    /** Retrieve header content from the service. */
    async getHeader(token) {
      return this.makeFetchRequest(`${(0, _configuration.getConfig)().basePath}header`, token);
    }

    /** Retrieve navigation menu content from the service. */
    async getNavigation(token) {
      return this.makeFetchRequest(`${(0, _configuration.getConfig)().basePath}navigation`, token);
    }

    /** Retrieve footer content from the service. */
    async getFooter(token) {
      return this.makeFetchRequest(`${(0, _configuration.getConfig)().basePath}footer`, token);
    }

    /** Retrieves the header, navigation, and footer within a single promise. */
    async getAll(token) {
      return (0, _rsvp.hashSettled)({
        header: this.getHeader(token),
        navigation: this.getNavigation(token),
        footer: this.getFooter(token)
      });
    }
    async makeFetchRequest(url, token) {
      const config = {
        cache: 'reload',
        method: 'GET'
      };
      if (typeof token === 'string') {
        config.headers = {
          Authorization: `bearer ${token}`
        };
      }
      const response = await (0, _fetch.default)(url, config);
      if (response.status >= 200 && response.status < 300) {
        return response.text();
      } else {
        throw new FetchError(response);
      }
    }
  }
  _exports.default = SemanticContentData;
});