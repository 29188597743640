/**
 * @module App
 */
import { isBlank } from '@ember/utils';

/**
 * Formats street addresses
 *
 * @namespace Helpers
 * @class formatAddress
 */

/**
 * Formats street addresses. If an address field is not provided, it is not shown.
 *
 * @method formatAddress
 *
 * @param {Object} address
 *    @param {String} address.streetAddress
 *    @param {String} address.streetAddress2
 *    @param {String} address.cityName
 *    @param {String} address.postalCode
 *    @param {String} address.stateCode
 *    @param {String} address.countryCode
 *
 * @return {String}
 *
 * @example
 *     {{format-address nsfAddressObject}} =>
 *     2415 Eisenhower Ave
 *     Alexandria, VA 22314 US
 */

type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

type Either<T, U> = Only<T, U> | Only<U, T>;

type StreetAddress1 = Either<{streetAddress: string}, { street1: string }>
type StreetAddress2 = Either<{streetAddress2: string}, { street2: string }>
type City = Either<{cityName: string}, { city: string }>
type ZipCode = Either<{postalCode: string}, { zipCode: string }>
type State = Either<{stateCode: string}, { state: string }>
type Country = Either<{countryCode: string}, { country: string }>

export type Address = Partial<StreetAddress1> & Partial<StreetAddress2> & City & ZipCode & State & Country;

export const formatAddress = (address: Address) => {
  const streetAddress = address.streetAddress ?? address.street1;
  const streetAddress2 = address.streetAddress2 ?? address.street2;
  const cityName = address.cityName ?? address.city;

  let postalCode = address.zipCode ?? address.postalCode ;
  if ((address.countryCode === 'US' || address.country === 'US' ) && postalCode.length > 5) {
    postalCode = postalCode.replace(/(\w{5})/, '$1-');
  }

  const stateAbbrev = address.stateCode ?? address.state;
  const countryAbbrev = address.countryCode ?? address.country;

  let fullAddress = !isBlank(streetAddress) ? `${streetAddress}<br />` : '';
  if (!isBlank(streetAddress2)) fullAddress = `${fullAddress}${streetAddress2}<br />`;

  fullAddress = `${fullAddress}${cityName}, ${stateAbbrev} ${postalCode}<br />${countryAbbrev}`;

  const res = fullAddress.replace(',', '');
  if (isBlank(res)) {
    return '';
  }

  return fullAddress;
}

export default formatAddress;
