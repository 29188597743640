define("@nsf/ember-oauth-iam/utils/activity-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * The ActivityMonitor class attaches one or more event listeners to the document body for the
   * purpose of watching whether .
   */
  class ActivityMonitor {
    constructor(eventNames, callback) {
      _defineProperty(this, "eventNames", void 0);
      _defineProperty(this, "callback", void 0);
      this.eventNames = typeof eventNames === 'string' ? eventNames.split(',').map(name => name.trim()) : eventNames;
      this.callback = callback;
    }

    /** Start listening for events. */
    start() {
      if (Array.isArray(this.eventNames)) {
        this.eventNames.forEach(name => {
          document.body.addEventListener(name, this.callback);
        });
      }
    }

    /** Stop listening for events. */
    stop() {
      if (Array.isArray(this.eventNames)) {
        this.eventNames.forEach(name => {
          document.body.removeEventListener(name, this.callback);
        });
      }
    }
  }
  _exports.default = ActivityMonitor;
});