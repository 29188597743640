// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { layout, tagName } from '@ember-decorators/component';
import template from './template';

/**
 * A single list item in the navigation.
 *
 * @class RGovSideNavItem
 */
@tagName('')
@layout(template)
export default class RGovSideNavItem extends Component {
  public text?: string;

  public onClick?: (event: Event) => void;
}
