define("@nsf/ember-oauth-iam/authenticators/iam", ["exports", "@ember/service", "@nsf/ember-oauth-iam/utils/timer", "@nsf/ember-oauth-iam/errors", "@nsf/ember-oauth-iam/utils/typed-ember-simple-auth-reexports"], function (_exports, _service, _timer, _errors, _typedEmberSimpleAuthReexports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let IamAuthenticator = _exports.default = (_class = class IamAuthenticator extends _typedEmberSimpleAuthReexports.BaseAuthenticator {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "iamDataService", _descriptor, this);
    }
    async authenticate(data) {
      return this.validateSession(data);
    }
    async restore(data) {
      return this.validateSession(data);
    }
    async invalidate() {
      return true; // No teardown is required.
    }
    async validateSession(data) {
      // Invalid if token, createdOn timestamp, or expiresOn timestamp do not exist.
      // Or if the expiresOn timestamp is in the past.
      if (data && typeof data.access_token === 'string' && typeof data.created_on === 'number' && typeof data.expires_on === 'number' && data.expires_on > _timer.default.now(_timer.default.second)) {
        if (!data.user) {
          // Relying on pass-by-reference here, will want to rethink the approach.
          data.user = await this.iamDataService.getUserInfo(data.access_token);
        }
        return data;
      }
      throw new _errors.InvalidSessionError();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "iamDataService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});