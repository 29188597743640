define("@nsf/ember-properties-loader/utils/is-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isObject = isObject;
  function isObject(obj) {
    return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
  }
});