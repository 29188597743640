define("@nsf/ember-properties-loader/services/properties", ["exports", "@ember/service", "@ember/object", "fetch", "@nsf/ember-properties-loader/configuration", "@nsf/ember-properties-loader/utils/object-merge", "@nsf/ember-properties-loader/utils/object-traverse"], function (_exports, _service, _object, _fetch, _configuration, _objectMerge, _objectTraverse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * The PropertiesService class handles property file loading, and the replacement of placeholders within
   * property values with real-time data when needed.
   *
   * @class PropertiesService
   */
  class PropertiesService extends _service.default {
    constructor() {
      super(...arguments);
      /** True if the service is currently loading/processing properties files. */
      _defineProperty(this, "isLoading", false);
      /** True if the service has successfully loaded all requested files. */
      _defineProperty(this, "isLoaded", false);
      /** An array containing the top-level names of the properties trees that have been loaded. */
      _defineProperty(this, "loadedPropertyNames", void 0);
      /**
       * Stores meta information on loaded properties: where they came from, what overloaded
       * them, their final data type, property name chain, and so on.
       */
      _defineProperty(this, "loadedPropertyMeta", void 0);
      /**
       * The property name chain delimiter used internally by `loadedPropertyMeta` to avoid
       * using the period character in object key names.
       */
      _defineProperty(this, "propertyChainDelimiter", '@-@');
      /**
       * The top-level get in SessionStorage and/or LocalStorage that will be used by the service if
       * it persists anything there.
       */
      _defineProperty(this, "storageKey", 'properties-loader-service');
    }
    /**
     * A string array of all the environment names provided in the configuration's
     * environmentMap array, in the order they were provided.
     */
    get environments() {
      return (0, _configuration.getConfig)().environmentMap.map(item => item.name);
    }

    /**
     * The name of the environment configuration that is (or will be) loaded.
     */
    get currentEnvironment() {
      return this.findEnvironmentPointer().resolved.name;
    }

    /**
     * Indicates whether the currently loaded environment has been overridden in some way (check SessionStorage).
     * One of two things will be returned:
     *
     * - A boolean false indicates that the environment was determined directly from the
     *   configuration's environmentMap array. This is the default and most typical behavior.
     * - An object of the type `{ configured: string, resolved: string }` wherein "configured" is
     *   what the configuration's environmentMap indicated to load, and "resolved" is what was
     *   actually loaded.
     */
    get currentEnvironmentIsOverridden() {
      const {
        configured,
        resolved
      } = this.findEnvironmentPointer();
      return configured.name === resolved.name ? false : {
        configured: configured.name,
        resolved: resolved.name
      };
    }

    /**
     * Kicks off the configuration loading process.
     */
    async load() {
      if (this.isLoading) {
        return true;
      }
      (0, _object.set)(this, 'isLoading', true);
      const loadQueue = this.flattenPointers().map(pointer => {
        return {
          pointer,
          url: this.buildUrl(pointer)
        };
      });
      return loadQueue.length ? this.requestNext(loadQueue, this.gatherLoadedProperties(), this.loadedPropertyMeta || new Map()) : true;
    }

    /**
     * Delete all loaded property values and allow a new load to be made.
     */
    reset() {
      this.loadedPropertyNames?.forEach(name => {
        delete this[name];
      });
      (0, _object.set)(this, 'loadedPropertyNames', undefined);
      (0, _object.set)(this, 'loadedPropertyMeta', undefined);
      (0, _object.set)(this, 'isLoaded', false);
      (0, _object.set)(this, 'isLoading', false);
    }

    /**
     * Similar to the `get()` method, but this takes a second optional argument that will be used
     * to replace any placeholder values in the retrieved property, if that property is a string.
     * Any non-string properties will be returned unaltered. The placeholder syntax is identical to
     * that found in ES6 string templates: `${...}`, with the ellipsis being any string key to identify
     * that placeholder. For example:
     *
     * ```javascript
     * const myProperty = "/posts/${post_id}/comments/${comment_id}";`
     * ```
     *
     * **Note:** while this uses ES6 syntax, do not use back ticks to actually identify it as a string
     * template. We do not want JavaScript to try to parse it.
     *
     * ```javascript
     * // Assume that the property 'post.comment.link' equals '/posts/${post_id}/comments/${comment_id}'
     *
     * // The unaltered property.
     * properties.getReplace('post.comment.link', null); // => '/posts/${post_id}/comments/${comment_id}'
     *
     * // If you only have a single placeholder to replace, you can pass the replacement value directly.
     * properties.getReplace('post.comment.link', '10'); // => '/posts/10/comments/${comment_id}'
     * properties.getReplace('post.comment.link', 10);   // => '/posts/10/comments/${comment_id}'
     * properties.getReplace('post.comment.link', true); // => '/posts/true/comments/${comment_id}'
     *
     * // The contents of Arrays will be used in order. The first placeholder is replaced by [0], the second
     * // by [1], and so on until either the end of the array is reached, or there are no more placeholders.
     * properties.getReplace('post.comment.link', ['10', '37']) // => '/posts/10/comments/37'
     *
     * // Any type of object may be used to target specific placeholders. The placeholder name will be matched
     * // to an object's property of the same name.
     * var postComment = Ember.Object.extend({
     *   post_id: '10',
     *   comment_id: '37'
     * }).create();
     *
     * properties.getReplace('post.comment.link', postComment) // => '/posts/10/comments/37'
     * ```
     *
     * @method getReplace
     * @public
     *
     * @param key The property name to retrieve. This argument adheres to the same rules as a property
     * name that you pass to `get()` on any typical Ember Object.
     *
     * @param [replacementValues] The replacementValues argument should contain the values that the
     * placeholders in the retrieved property string will be replaced with. It may be a null, string,
     * number, boolean, array of strings, or an Ember Object. See the examples for how each of these
     * types is treated.
     *
     * @return If the property named by `keyValue` is a non-string then it will be returned unaltered. If it
     * is a string then it will be returned after its placeholders have been replaced by the contents of the
     * replaceValues argument.
     */
    getReplace(key, replacementValues) {
      const sourceString = (0, _object.get)(this, key);
      if (typeof sourceString !== 'string' || replacementValues === null || replacementValues === undefined) {
        return sourceString;
      }
      let targetString = sourceString;
      const replacements = Array.isArray(replacementValues) ? replacementValues.slice(0) : replacementValues;
      let placeholderMatch;

      // Loops through a string, stopping for each ${...} placeholder capture.
      while ((placeholderMatch = /\${(.+?)}/.exec(targetString)) !== null) {
        // Strings, numbers, and booleans
        if (['string', 'number', 'boolean'].includes(typeof replacements)) {
          targetString = targetString.replace(placeholderMatch[0], replacements.toString());
          break;
        }
        // Arrays
        else if (Array.isArray(replacements)) {
          if (replacements.length) {
            targetString = targetString.replace(placeholderMatch[0], replacements.shift()?.toString() || '');
          }
          if (replacements.length === 0) {
            break;
          }
        }
        // Everything else is assumed to be some sort of object.
        else {
          // @ts-expect-error - It seems TS isn't good with regular expression match result arrays
          targetString = targetString.replace(placeholderMatch[0], (0, _object.get)(replacements, placeholderMatch[1]));
        }
      }
      return targetString;
    }

    /**
     * Called sequentially after each resource load until the queue is empty.
     */
    async requestNext(queue, propertiesAccumulator, meta) {
      const nextToLoad = queue.shift();

      // If there is nothing left in the queue then we're all done and can settle out of
      // the loading state of the service.
      if (!nextToLoad) {
        // @ts-expect-error - Incoming properties could be of any shape
        (0, _object.setProperties)(this, propertiesAccumulator);
        (0, _object.setProperties)(this, {
          isLoading: false,
          isLoaded: true,
          loadedPropertyNames: Object.keys(propertiesAccumulator),
          loadedPropertyMeta: meta
        });
        return propertiesAccumulator;
      }
      const {
        pointer,
        url
      } = nextToLoad;

      // The "conditional" check. Is the next queue item conditional, and if so does
      // the property chain that it describes have a truthy value. If not, then it will
      // be skipped.
      if ('condition' in pointer && typeof pointer.condition === 'string' && !(0, _object.get)(propertiesAccumulator, pointer.condition)) {
        return this.requestNext(queue, propertiesAccumulator, meta);
      }

      // If we've made it here then its time to fetch the properties resource described
      // by the pointer.
      const response = await (0, _fetch.default)(url);

      // Outside the HTTP 2xx range, this is an error. Whether or not it matters is up
      // to the configuration.
      if (response.status < 200 || response.status >= 300) {
        if (pointer.rejectOnFault) {
          throw new Error(response.statusText);
        }
        return this.requestNext(queue, propertiesAccumulator, meta);
      } else {
        let propertiesJson;
        try {
          propertiesJson = await response.json();
        } catch (e) {
          // A parsing error should probably come with some error control.
        }
        return this.requestNext(queue, _objectMerge.default.deep(propertiesAccumulator, propertiesJson), this.accumulateMetadata(propertiesJson, url, meta));
      }
    }

    /**
     * Given a JSON object, this will perform the steps to include its contents with the rest of the
     * property values that the service currently contains. This can be used to directly push new
     * content into the service without having it make any async calls.
     */
    ingest(properties) {
      const newMetadata = this.accumulateMetadata(properties, 'ingest', this.loadedPropertyMeta || new Map());
      const mergedProps = _objectMerge.default.deep(this.gatherLoadedProperties(), properties);

      // The shape of mergedProps could be anything
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (0, _object.setProperties)(this, mergedProps);
      (0, _object.setProperties)(this, {
        loadedPropertyNames: Object.keys(mergedProps),
        loadedPropertyMeta: newMetadata
      });
      if (!this.isLoading) {
        (0, _object.set)(this, 'isLoaded', true);
      }
    }

    /**
     * Gather metadata about an object and store it on the provided accumulator Map. This is used by both
     * `requestNext` and `ingest` to take care of some bookkeeping.
     */
    accumulateMetadata(properties, source, accumulator) {
      (0, _objectTraverse.default)(properties, (_, type, propertyChain) => {
        const propertyChainString = propertyChain.join(this.propertyChainDelimiter);
        if (propertyChainString.trim() !== '') {
          if (accumulator.has(propertyChainString)) {
            const entry = accumulator.get(propertyChainString);
            if (entry) {
              const previousType = entry.sources[entry.sources.length - 1].type;

              // What's happening here is that something that used to be marked as an object no
              // longer is. This means that we have to prune the metadata Map of everything that
              // used to descend from it because it no longer exists.
              if (previousType === 'object' && type !== 'object') {
                [...accumulator.keys()].filter(key => key !== propertyChainString && key.startsWith(propertyChainString)).forEach(key => accumulator.delete(key));
              }
              entry.sources.push({
                type,
                url: source
              });
            }
          } else {
            accumulator.set(propertyChainString, {
              propertyChain,
              sources: [{
                type,
                url: source
              }]
            });
          }
        }
      });
      return accumulator;
    }

    /**
     * Creates a simple object containing just the currently loaded property values.
     */
    gatherLoadedProperties() {
      if (this.loadedPropertyNames) {
        return this.loadedPropertyNames.reduce((acc, cur) => {
          acc[cur] = this[cur];
          return acc;
        }, {});
      }
      return {};
    }

    /**
     * Persist a value into the session storage cache.
     */
    updateSessionStorage(values) {
      if (values) {
        window.sessionStorage.setItem(this.storageKey, JSON.stringify(values));
      } else {
        window.sessionStorage.removeItem(this.storageKey);
      }
    }

    /**
     * Read the current session storage cache.
     */
    readSessionStorage() {
      const result = window.sessionStorage.getItem(this.storageKey);
      return typeof result === 'string' ? JSON.parse(result) : {};
    }

    /**
     * Returns the configuration pointer whose `hosts` array has a matching value for the
     * current hostname that the application is running in.
     */
    findEnvironmentPointer() {
      const hostName = this.hostname();
      const mappings = (0, _configuration.getConfig)().environmentMap;
      const pointer = mappings.find(item => {
        return Array.isArray(item.hosts) ? item.hosts.some(host => hostName.match(host)) : false;
      });
      const configured = pointer ?? mappings[mappings.length - 1];
      let resolved = configured;
      const {
        environmentOverride
      } = this.readSessionStorage();
      if (environmentOverride) {
        const override = mappings.find(item => item.name === environmentOverride);
        if (override) {
          resolved = override;
        }
      }
      return {
        configured,
        resolved
      };
    }

    /**
     * Generates a flat array of configuration pointers in the order to be loaded.
     */
    flattenPointers() {
      const config = (0, _configuration.getConfig)();
      const pointers = [];
      const {
        resolved: envPointer
      } = this.findEnvironmentPointer();

      // Global "before" loads
      if (Array.isArray(config.beforeEnvMap)) {
        pointers.push(...config.beforeEnvMap);
      }

      // Environment specific "before" loads
      if (Array.isArray(envPointer.before)) {
        pointers.push(...envPointer.before.map(item => this.inheritPathProperties(item, envPointer)));
      }

      // The environment load
      pointers.push(envPointer);

      // Environment specific "before" loads
      if (Array.isArray(envPointer.after)) {
        pointers.push(...envPointer.after.map(item => this.inheritPathProperties(item, envPointer)));
      }

      // Global "after" loads
      if (Array.isArray(config.afterEnvMap)) {
        pointers.push(...config.afterEnvMap);
      }
      return pointers;
    }

    /**
     * Copies the values of `domain` and `namespace` from the second argument onto
     * the first if the first does not have its own value.
     */
    inheritPathProperties(to, from) {
      const clone = Object.assign({}, to);
      clone.domain = typeof clone.domain === 'string' ? clone.domain : from.domain;
      clone.namespace = typeof clone.namespace === 'string' ? clone.namespace : from.namespace;
      return clone;
    }

    /**
     * Builds URL based on path from buildUrlPathFromPointer
     * as well as whether noCache is true or false
     */
    buildUrl(pointer) {
      const config = (0, _configuration.getConfig)();
      const currentTimeAsParam = `?${Date.now()}`;
      const appendToUrl = config.noCache ? currentTimeAsParam : '';
      const urlPath = this.buildUrlPathFromPointer(pointer);
      return `${urlPath}${appendToUrl}`;
    }

    /**
     * Stitches together a URL - either fully qualified or root relative - for the
     * given configuration pointer.
     */
    buildUrlPathFromPointer(pointer) {
      const config = (0, _configuration.getConfig)();
      pointer = this.inheritPathProperties(pointer, config);
      const beginsWith = typeof pointer.domain === 'string' && pointer.domain.trim() !== '' ? pointer.domain : config.rootURL;

      // The second replace might not be the most efficient...
      // The goal is to ignore double forward slashes that are prefixed with a colon, most
      // notably for the protocol bit of a URL. The first capture group grabs anything that
      // is not a colon however, so we add whatever that character was back into the
      // replacement string.
      return [beginsWith, pointer.namespace, pointer.name].join('/').replace(/[\\]+/g, '/').replace(/((^|[^:])(?:[/]{2,}))/g, '$2/');
    }

    /**
     * The lowercase value of `window.location.hostname`.
     */
    hostname() {
      return window.location.hostname.toLowerCase();
    }
  }
  _exports.default = PropertiesService;
});