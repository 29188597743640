define("@nsf-open/ember-general-utils/helpers/is-primitive", ["exports", "@ember/component/helper", "@nsf-open/ember-general-utils/utils/objects"], function (_exports, _helper, _objects) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * An Ember template helper to test whether the argument is one of
   * Javascript's primitive types.
   *
   * ```handlebars
   * {{#if (is-primitive @someValue)}}
   *   String, number, boolean logic
   * {{else}}
   *   Object, array logic
   * {{}}
   * ```
   *
   * @function is-primitive
   * @category helpers
   */
  var _default = _exports.default = (0, _helper.helper)(function isPrimitiveHelperFunction(args) {
    return (0, _objects.isPrimitive)(args[0]);
  });
});