define("@nsf-open/ember-general-utils/utils/numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInteger = isInteger;
  _exports.isNumeric = isNumeric;
  /**
   * Tests whether the argument is a finite number. Strings that could be
   * parsed as such are valid as well.
   */
  function isNumeric(value) {
    if (typeof value === 'number') {
      return isFinite(value);
    }
    if (typeof value === 'string') {
      return !isNaN(parseFloat(value));
    }
    return false;
  }

  /**
   * Tests whether the argument is a whole number.
   */
  function isInteger(value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }
});