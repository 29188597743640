define("@nsf/ember-webtrend-analytics/services/webtrend-analytics", ["exports", "@ember/service", "@ember/object", "@ember/runloop", "rsvp", "@nsf/ember-webtrend-analytics/constants", "@nsf/ember-webtrend-analytics/utils/enhanced-tracking-support", "@nsf/ember-webtrend-analytics/utils/webtrends-instance"], function (_exports, _service, _object, _runloop, _rsvp, _constants, _enhancedTrackingSupport, _webtrendsInstance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * @class WebTrendAnalyticsService
   * @see https://onpremises.webtrends.help/docs/tracking-complex-web-page-interactions-using-dcsmultitrack
   */
  let WebTrendsAnalyticsService = _exports.default = (_class = class WebTrendsAnalyticsService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      /**
       * The rootURL of the running application. This value is provided at startup.
       */
      _defineProperty(this, "rootURL", '/');
      _defineProperty(this, "_enabled", true);
      _defineProperty(this, "_elementTracking", true);
      /**
       * Cached note values from the application that will be sent with the next
       * `pageView()` call.
       */
      _defineProperty(this, "_pageViewNote", void 0);
      /**
       * Cached scenario values that will be sent with the next `pageView()` call.
       */
      _defineProperty(this, "_scenario", void 0);
      /**
       * An array that holds tracking details gathered internally by the service to
       * be mixed in with other data points for both page views and generic events.
       */
      _defineProperty(this, "_trackingInternals", void 0);
      /**
       * Has tracking already begun. This is an internal state flag used to make sure
       * that things like multiple Webtrends instances or event handlers don't wind
       * up getting created.
       */
      _defineProperty(this, "_started", false);
      /**
       * The callback function invoked with the router service's "routeWillChange" event.
       * Stored to provide a reference to the method that allows it to be removed if needed.
       */
      _defineProperty(this, "_routeWillChangeHandler", void 0);
      /**
       * The callback function invoked with the router service's "routeDidChange" event.
       * Stored to provide a reference to the method that allows it to be removed if needed.
       */
      _defineProperty(this, "_routeDidChangeHandler", void 0);
      /**
       * The callback function invoked with the document's "click" event.
       * Stored to provide a reference to the method that allows it to be removed if needed.
       */
      _defineProperty(this, "_documentClickHandler", void 0);
      /**
       * A reference to the last <a> or <button> that was clicked on in the application.
       * This will be held for a brief period during which time if a tracking event is
       * requested to be sent we'll assume that it was the interaction with this button that
       * kicked it off.
       */
      _defineProperty(this, "_lastTrackedElement", void 0);
      /**
       * The ID of the timer that clears _lastTrackedElement after a short moment. We don't
       * want to hold onto these references for any longer than needed since it's probable
       * the element has been detached from the DOM.
       */
      _defineProperty(this, "_correlationTimer", void 0);
    }
    /**
     * Boolean true/false about whether tracking events should be sent to the data server. If disabled
     * and then enabled, the `start()` method must also be called in order to begin tracking again.
     */
    get enabled() {
      return this._enabled;
    }
    set enabled(value) {
      this._enabled = value;
      if (!value) {
        this.stop();
      }
    }
    /**
     * Instead of manually annotating each element interaction that you want to track, or providing
     * custom notes to tell which anchor a page change originated from, element tracking will attempt
     * to correlate this "cause and effect" for you, and add additional details to the tracking event.
     *
     * This is a new feature that should be considered as a beta offering. If you're getting really
     * weird results then disable and file a ticket.
     */
    get elementTracking() {
      return this._elementTracking;
    }
    set elementTracking(value) {
      this._elementTracking = value;
      if (value) {
        this.startElementTracking();
      } else {
        this.stopElementTracking();
      }
    }
    /**
     * A reference to `window.location.host`.
     */
    get host() {
      return window.location.host;
    }

    /**
     * The current document title.
     */
    get title() {
      return window.document.title;
    }

    /**
     * The current URL path as reported by the application router.
     */
    get currentUrl() {
      const url = this.router.currentURL;
      return this.rootURL + (typeof url === 'string' && url.charAt(0) === '/' ? url.substring(1) : url);
    }
    /**
     * Manually initialize the Webtrends instance if not already done.
     */
    start(config) {
      if (this.enabled && !this._started) {
        (0, _webtrendsInstance.webtrendsInstance)(config);
        const routeWillChange = this._routeWillChangeHandler = this.handleRouteWillChange.bind(this);
        const routeDidChange = this._routeDidChangeHandler = this.handleRouteDidChange.bind(this);
        this.router.on('routeWillChange', routeWillChange);
        this.router.on('routeDidChange', routeDidChange);
        this.startElementTracking();
        this._started = true;
      }
    }

    /**
     * Stop tracking events.
     */
    stop() {
      if (this._started) {
        (0, _webtrendsInstance.destroyWebtrendsInstance)();

        // The RouterService implements the Evented mixin, it's just missing from the typings here.
        // @ts-expect-error - The `off()` method is the complement to `on()`.
        const off = this.router.off;
        off.call(this.router, 'routeWillChange', this._routeWillChangeHandler);
        off.call(this.router, 'routeDidChange', this._routeDidChangeHandler);
        this._routeWillChangeHandler = undefined;
        this._routeDidChangeHandler = undefined;
        this.stopElementTracking();
        this._started = false;
      }
    }

    /**
     * True if a global Webtrends instance has been created.
     */
    exists() {
      return (0, _webtrendsInstance.hasWebtrendsInstance)();
    }

    /**
     * Attaches WebTrend scenario values to the next `pageView()` call.
     * [https://help.webtrends.com/legacy/en/Analytics9Admin/scenario_analysis_parameters.html]
     *
     * @param name         The name of the Scenario Analysis.
     * @param [step]       The identity of the step by name.
     * @param [position]   The identity of the step by position.
     * @param [conversion] The identity of the step in which conversion occurs for Visitor Data Mart profiles.
     */
    scenario(name, step, position, conversion) {
      this._scenario = [_constants.WebtrendsTags.scenarioName, name];
      if (step) {
        this._scenario.push(_constants.WebtrendsTags.scenarioStep, step);
      }
      if (position) {
        this._scenario.push(_constants.WebtrendsTags.scenarioPosition, position.toString());
      }
      if (conversion) {
        this._scenario.push(_constants.WebtrendsTags.scenarioConversion, conversion);
      }
    }

    /**
     * Clear current scenario values that are queued to be sent with the next `pageView()` call.
     */
    clearScenario() {
      this._scenario = undefined;
    }

    /**
     * Add one or more text notes to be sent with the next `pageView()` call. Notes can be any
     * string values required to adequately annotate the page view event in Webtrends.
     */
    pageViewNote(note) {
      this._pageViewNote = note;
    }

    /**
     * Clear current note values that are queued to be sent with the next `pageView()` call.
     */
    clearPageViewNote() {
      this._pageViewNote = undefined;
    }

    /**
     * A convenience method that clears all note and scenario info from the next `pageView()` call.
     */
    clearPendingPageViewDetails() {
      this.clearPageViewNote();
      this.clearScenario();
    }

    /**
     * Send a page view tracking event.
     *
     * @param [note] Additional information to send with the track. If a string, it will be provided to
     * DCSext.note and appear as `note` in the tracking query string. If an array, duples are expected
     * where the first value is the key name to provide to the DCSext namespace, and the second value
     * is what to set on that key.
     */
    pageView(note) {
      const extras = [...(this._trackingInternals || []), ...(this._scenario || [])];
      this.send(note || this._pageViewNote, _constants.WebtrendsEvents.pageView, undefined, extras);
      this.clearPendingPageViewDetails();
    }

    /**
     * Send a tracking event.
     *
     * @param [note] Additional information to send with the track. If a string, it will be provided to
     * DCSext.note (and appear as `note` in the tracking query string). If an array, duples are expected
     * where the first value is the key name to provide to the DCSext namespace, and the second value is
     * what to set on that key.
     *
     * @param [type] The value to set WebTrends WT.dl flag to - indicating what kind of event triggered the track.
     *
     * @param [uri] The URI of a link destination, usually defined by the link's href value.
     */
    trackEvent(note) {
      let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _constants.WebtrendsEvents.linkClickOther;
      let uri = arguments.length > 2 ? arguments[2] : undefined;
      this.loadTrackedElementDetails();
      this.send(note, type, uri, this._trackingInternals);
    }

    /**
     * Sends a tracking event via WebTrends dcsMultiTrack method.
     *
     * ```javascript
     * // No note and generic link click type
     * send(null, 99);
     *
     * // A string note and generic link click type
     * // DCSext.note will receive the value 'Link Clicked' and ?note=Link%20Clicked will
     * // be in the track call query string
     * send('Link Clicked', 99);
     *
     * // An array note and generic link click type
     * // DCSext.paramA will receive the value 'Link Clicked' and DCSext.paramB will
     * // receive 'More Info'
     * send(['paramA', 'Link Clicked', 'paramB', 'More Info'], 99);
     * ```
     *
     * @param note Additional information to send with the track. If a string, it will be provided to
     * DCSext.note (and appear as `note` in the tracking query string). If an array, duples are expected
     * where the first value is the key name to provide to the DCSext namespace, and the second value is what
     * to set on that key.
     *
     * @param type The value to set WebTrends WT.dl flag to - indicating what kind of event triggered the track.
     *
     * @param uri The URI of a link destination, usually defined by the link's href value.
     *
     * @param extraNotes Any additional query params that might need to be tacked on. Follows the same general
     * duple rules at `note`.
     */
    send(note, type, uri, extraNotes) {
      if (!this.enabled) {
        this.clearPendingPageViewDetails();
        return;
      }
      const tracker = (0, _webtrendsInstance.webtrendsInstance)();
      if (!tracker) {
        return;
      }
      const params = [_constants.WebtrendsTags.domain, this.host, _constants.WebtrendsTags.path, uri || this.currentUrl, _constants.WebtrendsTags.title, this.title, _constants.WebtrendsTags.eventType, type];
      if (uri) {
        params.push(_constants.WebtrendsTags.eventSource, this.currentUrl);
      }
      if (Array.isArray(note)) {
        for (let i = 0; i < note.length; i += 2) {
          const tagName = note[i].startsWith('WT.') || note[i].startsWith('DCS.') ? note[i] : `${_constants.WebtrendsTags.defaultCustomNote}.${note[i]}`;
          params.push(tagName, note[i + 1]);
        }
      } else if (typeof note === 'string') {
        params.push(_constants.WebtrendsTags.defaultCustomNote, note);
      }
      if (Array.isArray(extraNotes)) {
        params.push(...extraNotes);
      }
      tracker.dcsMultiTrack(...params);

      // Properties accumulate over time for a single page app since there are no reloads occurring. Here
      // we are taking care of that cleanup by manually resetting the some thing each time a tracking call
      // occurs.
      tracker.WT = {};
      tracker.DCS = {};
      tracker.DCSext = {};
      this._trackingInternals = undefined;
    }

    /**
     * The handler function for the RouterService's "routeWillChange" event.
     */
    handleRouteWillChange(transition) {
      var _this = this;
      this._trackingInternals = this._trackingInternals || [];
      this._trackingInternals.push(_constants.WebtrendsTags.referrer, this.currentUrl);

      // Cleanup if the transition does not complete for whatever reason.
      transition.then(_rsvp.resolve, function () {
        _this._trackingInternals = undefined;
        return (0, _rsvp.reject)(...arguments);
      });
    }

    /**
     * The handler function for the RouterService's "routeDidChange" event.
     */
    handleRouteDidChange() {
      this.loadTrackedElementDetails();
      this.pageView();
    }

    /**
     * Cleanup. Called automatically as the service is disposed of during application unload.
     */
    destroy() {
      this.stop();
      return super.destroy();
    }
    startElementTracking() {
      if (!this._documentClickHandler) {
        const documentClick = this._documentClickHandler = this.handleDocumentClick.bind(this);
        document.addEventListener('click', documentClick, {
          capture: true
        });
      }
    }
    stopElementTracking() {
      if (this._documentClickHandler) {
        document.removeEventListener('click', this._documentClickHandler, {
          capture: true
        });
      }
      if (this._correlationTimer) {
        (0, _runloop.cancel)(this._correlationTimer);
      }
      this._documentClickHandler = undefined;
      this._lastTrackedElement = undefined;
      this._correlationTimer = undefined;
    }
    handleDocumentClick(event) {
      const target = event.target;
      if (target.tagName === 'A' || target.tagName === 'BUTTON') {
        this._lastTrackedElement = target;
        this._correlationTimer = (0, _runloop.later)(() => {
          this._lastTrackedElement = undefined;
        }, 10);
      }
    }
    loadTrackedElementDetails() {
      if (this._lastTrackedElement) {
        const {
          query,
          text
        } = (0, _enhancedTrackingSupport.getElementDetails)(this._lastTrackedElement);
        this._trackingInternals = [...(this._trackingInternals || []), 'DCSext.target', query, 'DCSext.targetText', text];
      }
      if (this._correlationTimer) {
        (0, _runloop.cancel)(this._correlationTimer);
      }
      this._lastTrackedElement = undefined;
      this._correlationTimer = undefined;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "scenario", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "scenario"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pageViewNote", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "pageViewNote"), _class.prototype)), _class);
});