define("@nsf-open/ember-datetime-utils/parser", ["exports", "moment", "@nsf-open/ember-datetime-utils/constants"], function (_exports, _moment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = oneParserToRuleThemAll;
  /** Configuration options for the parser. */

  /**
   * Takes a variety of possible inputs and attempts to make a date. This will
   * only ever return either a Date instance, or null.
   */
  function oneParserToRuleThemAll(value) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      format,
      utc,
      tz,
      exactFormatMatch
    } = options;
    if (value instanceof Date) {
      return value;
    } else if (_moment.default.isMoment(value) && value.isValid()) {
      return value.toDate();
    } else if (typeof value === 'number') {
      return new Date(value);
    } else if (typeof value === 'string' && value.trim().length) {
      const formats = format ? exactFormatMatch ? format : [format].concat(_constants.PARSE_FORMATS) : _constants.PARSE_FORMATS;
      let time;
      if (utc) {
        time = _moment.default.utc(value, formats);
      } else if (typeof tz === 'string') {
        time = _moment.default.parseZone(value, formats);
        if (time.isValid()) {
          const flags = time.parsingFlags();
          const mask = time.creationData()?.format;

          // Only apply a timezone if the incoming value didn't provide one
          if (mask && !mask.includes('ZZ') || flags.unusedTokens.includes('ZZ')) {
            time.utcOffset(tz, true);
          }
        }
      } else {
        time = (0, _moment.default)(value, formats);
      }
      if (time.isValid()) {
        return time.toDate();
      }
    }
    return null;
  }
});