define("@nsf/ember-properties-loader/utils/object-chain-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getChainValue;
  const ARRAY_IDX_REGEX = /\[(\d+)]/;

  /**
   * Sort of like Ember's own `get()` method, but this supports array indices as well
   * so that things like `foo.bar.[2].baz` can be written.
   *
   * ```javascript
   * const obj = { foo: { bar: ['a', 'b', { baz: 'Hello' }] } };
   * getChainValue(obj, 'foo.bar.[2].baz') // 'Hello'
   * ```
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getChainValue(obj, chain) {
    let parts;
    if (Array.isArray(chain)) {
      parts = chain;
    } else if (typeof chain === 'string') {
      parts = chain.split('.').filter(Boolean);
    } else {
      return obj;
    }
    if (!parts.length) {
      return obj;
    }
    let temp = obj;
    for (let i = 0; i < parts.length; i += 1) {
      if (temp === null || temp === undefined) {
        return undefined;
      }
      const part = parts[i];
      const arrayMatch = part.match(ARRAY_IDX_REGEX);
      if (arrayMatch) {
        temp = temp[parseInt(arrayMatch[1], 10)];
      } else {
        temp = temp[part];
      }
    }
    return temp;
  }
});