define("@nsf/ember-oauth-iam/utils/path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const looseParseRegex = /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/;
  const parseQueryRegex = /(?:^|&)([^&=]*)=?([^&]*)/g;

  // The names of that various parts of a URI in the order they appear.
  const parsedUriKeys = ['source', 'protocol', 'authority', 'userInfo', 'user', 'password', 'host', 'port', 'relative', 'path', 'directory', 'file', 'query', 'anchor'];

  /**
   * URI path utilities.
   */
  class Path {
    /**
     * Concatenates a series of path segments.
     */
    static join() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      // The second replace might not be the most efficient...
      // The goal is to ignore double forward slashes that are prefixed with a colon, most
      // notably for the protocol bit of a URL. The first capture group grabs anything that
      // is not a colon however, so we add whatever that character was back into the
      // replacement string.
      return args.join('/').replace(/\\+/g, '/').replace(/((^|[^:])(\/{2,}))/g, '$2/');
    }

    /**
     * Split a URI into its constituent components.
     */
    static parse(uri) {
      const results = {
        queryKey: {}
      };
      const matches = looseParseRegex.exec(uri);
      if (matches) {
        let i = parsedUriKeys.length;
        while (i -= 1) {
          results[parsedUriKeys[i]] = matches[i] || '';
        }
      }
      results.query?.replace(parseQueryRegex, function ($0, $1, $2) {
        if (typeof $1 === 'string') {
          results.queryKey[$1] = $2;
        }
        return $0;
      });
      return results;
    }
  }
  _exports.default = Path;
});