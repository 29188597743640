define("@nsf/ember-properties-loader/initializers/properties-loader", ["exports", "@nsf/ember-properties-loader/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initialize;
  function initialize(application) {
    const env = application.resolveRegistration('config:environment');
    (0, _configuration.initializeConfiguration)(Object.assign({
      rootURL: env.rootURL,
      environment: env.environment
    }, env['ember-properties-loader'] || {}));
  }
});