define("@nsf/ember-semantic-content-data/initializers/semantic-content-data", ["exports", "@nsf/ember-semantic-content-data/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initialize;
  /**
   * Puts together the configuration singleton for the running application.
   *
   * @param {Application} application
   */
  function initialize(application) {
    const env = application.resolveRegistration('config:environment');
    (0, _configuration.initializeConfiguration)(Object.assign({
      rootURL: env.rootURL
    }, env['ember-semantic-content-data'] || {}));
  }
});