define("@nsf/ember-oauth-iam/routes/unauthenticated-route", ["exports", "@nsf/ember-oauth-iam/configuration", "@ember/routing/route", "@ember/application", "@ember/debug"], function (_exports, _configuration, _route, _application, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.unauthenticated = unauthenticated;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * Routes that extend from UnauthenticatedRoute may only be accessed if the current session IS NOT authenticated.
   *
   * A registration form is one example of the sort of route that you would only want an anonymous user to be
   * able to access.
   *
   * Note that this class performs its authentication check in its `beforeModel` method, so if your extending
   * class also uses that method be sure to call `super`.
   */
  class UnauthenticatedRoute extends _route.default {
    async beforeModel(transition) {
      const session = (0, _application.getOwner)(this).lookup('service:session');
      return session.isAuthenticated ? session.prohibitAuthentication((0, _configuration.getConfig)().routeIfAlreadyAuthenticated) : super.beforeModel(transition);
    }
  }

  /**
   * An UnauthenticatedSetupMethod can be used to configure ember-oauth-iam when decorating a Route.
   */
  _exports.default = UnauthenticatedRoute;
  const DecoratedSetupCalled = Symbol('AUTHENTICATED_DECORATOR_SETUP_CALLED');

  /**
   * A decorator that can be used to annotate an Ember Route subclass and enforce that an authenticated
   * session NOT be present in order to visit the route (or any of its descendents).
   *
   * A registration form is one example of the sort of route that you would only want an anonymous
   * user to be able to access.
   *
   * In the event that you need to configure this addon in the same Route that you're annotating with
   * this decorator, a `setup` method can be provided. If an object is returned, its properties will
   * be applied to the global OAuth Configuration.
   *
   * ```ts
   * @unauthenticated(async function setupOAuth() {
   *   return (await this.properties.load()).oauthConfig;
   * });
   * export default class ApplicationRoute extends Route {
   *   @service
   *   public readonly properties!: PropertiesService;
   * }
   * ```
   */
  function unauthenticated(setup) {
    return function createAnonymityRequiredSubclass(SuperClass) {
      (false && !(SuperClass === _route.default || SuperClass.prototype instanceof _route.default) && (0, _debug.assert)('The unauthenticated decorator can only be applied to Ember Route subclasses', SuperClass === _route.default || SuperClass.prototype instanceof _route.default)); // @ts-expect-error - return the class definition as provided, instead of a subclass definition.
      // This also makes TS happy, as the Route class typings utilize private types that cannot be reflected.
      return class AnonymityRequiredRoute extends _route.default {
        constructor() {
          super(...arguments);
          _defineProperty(this, DecoratedSetupCalled, false);
        }
        async beforeModel(transition) {
          const session = (0, _application.getOwner)(this).lookup('service:session');
          if (typeof setup === 'function' && !this[DecoratedSetupCalled]) {
            await session.setup(await setup.call(this, this));
            this[DecoratedSetupCalled] = true;
          }
          return session.isAuthenticated ? session.prohibitAuthentication((0, _configuration.getConfig)().routeIfAlreadyAuthenticated) : super.beforeModel(transition);
        }
      };
    };
  }
});