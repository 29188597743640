define("@nsf-open/ember-datetime-utils/formatters", ["exports", "moment", "@nsf-open/ember-datetime-utils/parser", "@nsf-open/ember-datetime-utils/constants"], function (_exports, _moment, _parser, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toBigEndianDateString = toBigEndianDateString;
  _exports.toBigEndianDateTimeString = toBigEndianDateTimeString;
  _exports.toBigEndianTimeString = toBigEndianTimeString;
  _exports.toFormattedString = toFormattedString;
  _exports.toMiddleEndianDateString = toMiddleEndianDateString;
  _exports.toMiddleEndianDateTimeString = toMiddleEndianDateTimeString;
  _exports.toMiddleEndianTimeString = toMiddleEndianTimeString;
  /**
   * Given a value that can be converted into a valid MomentJS instance, format
   * it with the provided mask. If it cannot be converted, a default value
   * (usually a string) is returned.
   */
  function toFormattedString(value, formatOut) {
    let defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    const date = (0, _parser.default)(value, options);
    return date ? (0, _moment.default)(date).format(formatOut) : defaultValue;
  }

  /**
   * Formats a date-like value as "MONTH/DAY/YEAR".
   *
   * ```javascript
   * toMiddleEndianDateString(new Date(1970, 9, 25, 13, 10, 50)); // => '10/25/1970'
   * ```
   */
  function toMiddleEndianDateString(value) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return toFormattedString(value, _constants.MIDDLE_ENDIAN_DATE_FORMAT, defaultValue, options);
  }

  /**
   * Formats a date-like value as "HOUR:MINUTE MERIDIEM".
   *
   * ```javascript
   * toMiddleEndianTimeString(new Date(1970, 9, 25, 13, 10, 50)); // => '1:10 PM'
   * ```
   */
  function toMiddleEndianTimeString(value) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return toFormattedString(value, _constants.MIDDLE_ENDIAN_TIME_FORMAT, defaultValue, options);
  }

  /**
   * Formats a date-like value as "MONTH/DAY/YEAR HOUR:MINUTE MERIDIEM".
   *
   * ```javascript
   * toMiddleEndianDateTimeString(new Date(1970, 9, 25, 13, 10, 50)); // => '10/25/1970 1:10 PM'
   * ```
   */
  function toMiddleEndianDateTimeString(value) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return toFormattedString(value, _constants.MIDDLE_ENDIAN_DATETIME_FORMAT, defaultValue, options);
  }

  /**
   * Formats a date-like value as "YEAR-MONTH-DAY".
   *
   * ```javascript
   * toBigEndianDateString(new Date(1970, 9, 25, 13, 10, 50)); // => '1970-10-25'
   * ```
   */
  function toBigEndianDateString(value) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return toFormattedString(value, _constants.BIG_ENDIAN_DATE_FORMAT, defaultValue, options);
  }

  /**
   * Formats a date-like value as "HOUR:MINUTE:SECOND.MILLISECOND[TIMEZONE]".
   *
   * ```javascript
   * toBigEndianTimeString(new Date(1970, 9, 25, 13, 10, 50)); // => '13:10:50.000-05:00' (assuming EST)
   * ```
   */
  function toBigEndianTimeString(value) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return toFormattedString(value, _constants.BIG_ENDIAN_TIME_FORMAT, defaultValue, options);
  }

  /**
   * Formats a date-like value as "YEAR-MONTH-DAY HOUR:MINUTE:SECOND.MILLISECOND[TIMEZONE]".
   *
   * ```javascript
   * toBigEndianDateTimeString(new Date(1970, 9, 25, 13, 10, 50)); // => '1970-10-25 13:10:50.000-05:00' (assuming EST)
   * ```
   */
  function toBigEndianDateTimeString(value) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'None';
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return toFormattedString(value, _constants.BIG_ENDIAN_DATETIME_FORMAT, defaultValue, options);
  }
});