// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import template from './template';
import { action } from '@ember/object';
import { layout } from '@ember-decorators/component';

class Link {
  url!: string;
  hasIcon!: boolean;
  cssClasses!: string;
  name!: string;
}

@layout(template)
// eslint-disable-next-line ember/require-tagless-components
export default class RgovHelpfulLinks extends Component {
  public links?: Link[];

  @action
  goToUrl(url: string) {
    window.open(url);
  }
}
