// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, set } from '@ember/object';
import { layout, tagName } from '@ember-decorators/component';
import template from './template';

@tagName('')
@layout(template)
export default class RGovSideNavGroup extends Component {
  public label?: string;

  public isOpen = true;

  @action
  toggleCollapsedState() {
    set(this, 'isOpen', !this.isOpen);
  }
}
