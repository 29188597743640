define("@nsf/ember-oauth-iam/components/oauth-iam-screen-overlay/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
  	role="dialog"
  	id="{{this.modalId}}"
  	class="iam-modal-wrapper"
  	aria-labelledby="{{this.labelledById}}"
  	aria-describedby="{{this.describedById}}"
  >
  		<div class="iam-modal-dialog">
  				<div class="iam-modal-content">
  						{{#if this.title}}
  								<div class="iam-modal-header">
  										{{#if this.cancelButtonAction}}
  												<button
  													type="button"
  													class="close"
  													aria-label="Close"
  													onclick={{action this.cancelButtonAction}}
  												>
  													<span aria-hidden="true">&times;</span>
  												</button>
  										{{/if}}
  										<h4 id="{{this.labelledById}}">
  											<span class="fa fa-warning text-warning" aria-hidden="true"></span> {{this.title}}
  										</h4>
  										<hr />
  								</div>
  						{{/if}}
  						<div class="iam-modal-body">
  								{{yield (hash describedById = this.describedById)}}
  						</div>
  				</div>
  		</div>
  </div>
  <div class="iam-modal-backdrop"></div>
  */
  {
    "id": "zOEcoD48",
    "block": "[[[10,0],[14,\"role\",\"dialog\"],[15,1,[29,[[30,0,[\"modalId\"]]]]],[14,0,\"iam-modal-wrapper\"],[15,\"aria-labelledby\",[29,[[30,0,[\"labelledById\"]]]]],[15,\"aria-describedby\",[29,[[30,0,[\"describedById\"]]]]],[12],[1,\"\\n\\t\\t\"],[10,0],[14,0,\"iam-modal-dialog\"],[12],[1,\"\\n\\t\\t\\t\\t\"],[10,0],[14,0,\"iam-modal-content\"],[12],[1,\"\\n\"],[41,[30,0,[\"title\"]],[[[1,\"\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,0],[14,0,\"iam-modal-header\"],[12],[1,\"\\n\"],[41,[30,0,[\"cancelButtonAction\"]],[[[1,\"\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"button\"],[14,0,\"close\"],[14,\"aria-label\",\"Close\"],[15,\"onclick\",[28,[37,1],[[30,0],[30,0,[\"cancelButtonAction\"]]],null]],[14,4,\"button\"],[12],[1,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"h4\"],[15,1,[29,[[30,0,[\"labelledById\"]]]]],[12],[1,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,1],[14,0,\"fa fa-warning text-warning\"],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\" \"],[1,[30,0,[\"title\"]]],[1,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\\t\\t\"],[10,\"hr\"],[12],[13],[1,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\t\\t\\t\\t\\t\\t\"],[10,0],[14,0,\"iam-modal-body\"],[12],[1,\"\\n\\t\\t\\t\\t\\t\\t\\t\\t\"],[18,1,[[28,[37,3],null,[[\"describedById\"],[[30,0,[\"describedById\"]]]]]]],[1,\"\\n\\t\\t\\t\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\\t\\t\"],[13],[1,\"\\n\\t\\t\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"iam-modal-backdrop\"],[12],[13]],[\"&default\"],false,[\"if\",\"action\",\"yield\",\"hash\"]]",
    "moduleName": "@nsf/ember-oauth-iam/components/oauth-iam-screen-overlay/template.hbs",
    "isStrictMode": false
  });
});