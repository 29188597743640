define("@nsf/ember-oauth-iam/services/session", ["exports", "@ember/object", "@ember/object/computed", "@ember/service", "@nsf/ember-oauth-iam/internal-session", "@nsf/ember-oauth-iam/configuration", "@nsf/ember-oauth-iam/errors", "@nsf/ember-oauth-iam/utils", "@nsf/ember-oauth-iam/utils/typed-ember-simple-auth-reexports"], function (_exports, _object, _computed, _service, _internalSession, _configuration, _errors, _utils, _typedEmberSimpleAuthReexports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const getParameters = routeInfo => {
    let allParameters = [];
    let current = routeInfo;
    do {
      const {
        params,
        paramNames
      } = current;
      const currentParameters = paramNames.map(n => params[n]);
      allParameters = [...currentParameters, ...allParameters];
    } while (current = current.parent);
    return allParameters;
  };

  /**
   * The SessionService is built on top of ember-simple-auth's
   * [SessionService](http://ember-simple-auth.com/api/classes/SessionService.html),
   * and offers several additional properties and methods.
   */
  let AuthSessionService = _exports.default = (_dec = (0, _computed.oneWay)('data.authenticated.user'), _dec2 = (0, _computed.readOnly)('data.authenticated.access_token'), _dec3 = (0, _computed.readOnly)('data.authenticated.expires_on'), (_class = class AuthSessionService extends _typedEmberSimpleAuthReexports.SessionService {
    constructor() {
      super(...arguments);
      /**
       * The number of seconds since the user was last active.
       */
      _defineProperty(this, "timeSinceActive", 0);
      /**
       * The number of seconds remaining until the token will expire.
       */
      _defineProperty(this, "tokenExpiresIn", 0);
      /**
       * The number of seconds remaining until the session will be invalidated due to user inactivity.
       */
      _defineProperty(this, "idleLogoutIn", void 0);
      /**
       * A boolean flag that will be set to true once the first configured `pendingExpirationWarning`
       * has been reached.
       */
      _defineProperty(this, "isExpiring", false);
      /**
       * A boolean flag that will be set to true once the first configured `pendingInactivityWarning`
       * has been reached.
       */
      _defineProperty(this, "isInactive", false);
      /**
       * The ActivityMonitor instance responsible for watching user interactions with
       * the application and reporting them to the session.
       */
      _defineProperty(this, "activityMonitor", void 0);
      /**
       * The current value of the user object from the authenticated store, or undefined if not
       * authenticated. The object will only contain basic information such as name and
       * NSF ID.
       */
      _initializerDefineProperty(this, "user", _descriptor, this);
      /**
       * The current value of the OAuth access_token from the authenticated store, or undefined
       * if not authenticated.
       */
      _initializerDefineProperty(this, "accessToken", _descriptor2, this);
      /**
       * A UNIX timestamp, in seconds, indicating when the current access_token is set to expire.
       */
      _initializerDefineProperty(this, "expiresOn", _descriptor3, this);
      /**
       * A UNIX timestamp, in seconds, indicating the last time that the user triggered
       * a qualifying interaction event (defaults are "click" and "keyup") which reset
       * the idle timeout counter.
       */
      _defineProperty(this, "lastActive", void 0);
      /* ******************************* */
      /* Private API                     */
      /* ******************************* */
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "iamDataService", _descriptor5, this);
      /**
       * The BroadcastManager instance responsible for coordinating the logout of one window/tab
       * with any others that are opened.
       */
      _defineProperty(this, "broadcastManager", new _utils.BroadcastManager(this));
      /**
       * Guard flag indicating that the setup method has been called.
       */
      _defineProperty(this, "hasBeenSetup", false);
      /**
       * The Timer instance responsible for maintaining the clock ticks used to compute
       * the second by second session state.
       */
      _defineProperty(this, "updateTimer", void 0);
      /**
       * The TimedEventPlanner that manages the configured `pendingExpirationWarning` events.
       */
      _defineProperty(this, "expirationEvents", void 0);
      /**
       * The TimedEventPlanner that manages the configured `pendingInactivityWarning` events.
       */
      _defineProperty(this, "inactivityEvents", void 0);
    }
    /**
     * Sets up the session service.
     *
     * This method must be called when the application starts up, usually as the first
     * thing in the `application` route's `beforeModel` method.
     */
    async setup(configuration) {
      if (this.hasBeenSetup) {
        return;
      }
      const config = (0, _configuration.getConfig)(configuration);
      this.hasBeenSetup = true;
      this.idleLogoutIn = config.inactivityTimeout;
      this.updateTimer = new _utils.Timer(config.timerInterval * _utils.Timer.second);
      (0, _object.setProperties)(this.store, {
        cookieName: config.cookieName,
        cookieDomain: config.configuredCookieDomain
      });
      this.updateTimer.every(this, this.handleTimerTick);
      this.expirationEvents = new _utils.TimedEventPlanner([...config.pendingExpirationWarning, 0], this.handleExpirationEvents.bind(this), true);
      this.inactivityEvents = new _utils.TimedEventPlanner([...config.pendingInactivityWarning, 0], this.handleInactiveEvents.bind(this), true);
      this.activityMonitor = new _utils.ActivityMonitor(config.activityEvents, this.handleActivityMonitorEvents.bind(this));
      this.session.on('restoreSucceeded', () => this.handleRestoration());
      return super.setup();
    }

    /**
     * Authenticates the session with an `authenticator` and appropriate arguments.
     *
     * @see https://github.com/mainmatter/ember-simple-auth/blob/master/packages/ember-simple-auth/addon/services/session.js
     */
    async authenticate() {
      try {
        return await super.authenticate((0, _configuration.getConfig)().authenticatorName);
      } catch (e) {
        if (e instanceof _errors.InvalidSessionError) {
          await this.store.persist({
            in_flight: 1,
            cookie_domain: (0, _configuration.getConfig)().configuredCookieDomain,
            return_path: this.getReturnToPath()
          });
          return await this.redirect(this.getIdentityProviderURL(), 'authenticate');
        } else {
          throw e;
        }
      }
    }

    /**
     * Checks whether the session is authenticated and if it is not, transitions to the specified
     * route or invokes the specified callback.
     *
     * @see https://github.com/mainmatter/ember-simple-auth/blob/master/packages/ember-simple-auth/addon/services/session.js
     */
    async requireAuthentication(transition) {
      if (!this.isAuthenticated) {
        (0, _object.set)(this, 'attemptedTransition', transition);
        return await this.authenticate();
      }
      return transition;
    }

    /**
     * Invalidates the session with the authenticator it is currently authenticated with.
     *
     * @see https://github.com/mainmatter/ember-simple-auth/blob/master/packages/ember-simple-auth/addon/services/session.js
     */
    async invalidate() {
      await super.invalidate();
      await this.redirect((0, _configuration.getConfig)().logoutURL, 'invalidate');
    }

    /**
     * Reset the idle monitor back to zero in the same way that keyboard/mouse interaction would.
     */
    resetIdle() {
      this.handleActivityMonitorEvents();
    }

    /**
     * Update the user information that is stored in the session cookie. If you're using the
     * cookie info to display user details, and those details change, then you'll need to
     * use this. Do note that this does not persist anything to any API - that'll need to
     * be done elsewhere.
     */
    async updateUser(record) {
      let merge = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      const user = merge ? Object.assign({}, this.user, record) : record;
      if (this.data.authenticated && user && 'user' in this.data.authenticated) {
        (0, _object.set)(this.data.authenticated, 'user', user);
        return await this.store.persist(this.data);
      }
    }

    /**
     * Returns a fully formed URL to the identity provider.
     */
    getIdentityProviderURL() {
      const config = (0, _configuration.getConfig)();
      const redirectUri = config.overrideProviderRedirectUri ? config.overrideProviderRedirectUri : _utils.Path.join(this.getOrigin(), config.callbackURL);
      const query = `response_type=${encodeURIComponent(config.responseType)}` + `&scope=${encodeURIComponent(config.scope)}` + `&client_id=${encodeURIComponent(config.clientId)}` + `&redirect_uri=${encodeURIComponent(redirectUri)}`;
      return `${config.loginURL}?${query}`;
    }

    /**
     * Returns the current value of `window.location.origin`. Its separated
     * out, so it can be overwritten for testing.
     */
    getOrigin() {
      return window.location.origin;
    }

    /**
     * Attempts to retrieve a route to return the application to after the authentication has
     * succeeded. This will either come from the last attempted transition or it will be the
     * configured `routeAfterAuthentication`.
     */
    getReturnToPath() {
      const config = (0, _configuration.getConfig)();
      let target = config.routeAfterAuthentication;
      let options = [];
      if (this.attemptedTransition) {
        target = this.attemptedTransition.to.name;
        options = getParameters(this.attemptedTransition.to);
      }
      let route = options.length > 0 ? this.router.urlFor(target, ...options) : this.router.urlFor(target);
      if (!route.startsWith(config.rootURL)) {
        route = _utils.Path.join(config.rootURL, route);
      }
      if (config.includeDomainInReturnToValue) {
        route = _utils.Path.join(this.getOrigin(), route);
      }
      return route;
    }

    /**
     * Lifecycle method that is called immediately before the service instance is destroyed. Used
     * for cleanup here.
     */
    willDestroy() {
      this.updateTimer?.stop(true);
      this.activityMonitor?.stop();
      this.broadcastManager.reset();
      super.willDestroy();
    }
    /**
     * Update the current window URL with `window.location.assign`.
     *
     * This method intentionally returns a Promise (even though `window.location.assign`
     * does not) as doing so avoids the introduction of visual artifacts in the brief
     * period of time between when logging out resets a bunch of values in this service
     * and the browser begins loading the new location.
     */
    async redirect(to, _purpose) {
      await new Promise(resolve => {
        window.location.assign(to);
        setTimeout(resolve, 5000);
      });
    }

    /**
     * Called when the session is "restored", which means that it transitioned from
     * unauthenticated to authenticated without having to redirect through IAM's
     * login workflow. This method sees much more usage than `handleAuthentication`
     * because of the way that hard redirects are used. By the time the application
     * loads after the IAM workflow is completed, the cookie information that it needs
     * is already in place and so it "restores" the session.
     *
     * Note that this is not an Ember Simple Auth concept. The ESA internal-session
     * instance, which provides for `handleAuthentication` and `handleInvalidation` does not
     * provide for this scenario. This package's own internal-session class exists to
     * fill the gap.
     */
    handleRestoration() {
      this.setupAuthenticatedSession();
    }

    /**
     * Called when the session transitions from unauthenticated to authenticated, but
     * not on "restoration". Since hard redirects are used for authenticating with IAM
     * the `handleRestoration` method sees much more use. This exists here to be
     * thorough.
     *
     * @protected
     */
    handleAuthentication(routeAfterAuthentication) {
      this.setupAuthenticatedSession();
      return super.handleAuthentication(routeAfterAuthentication);
    }

    /**
     * Called when the session transitions from authenticated to unauthenticated.
     *
     * @protected
     */
    handleInvalidation(routeAfterInvalidation) {
      this.tearDownAuthenticatedSession();
      return super.handleInvalidation(routeAfterInvalidation);
    }
    setupAuthenticatedSession() {
      (0, _object.set)(this, 'lastActive', _utils.Timer.now());
      this.broadcastManager.login();
      this.updateTimer?.start();
      this.activityMonitor?.start();
      this.handleTimerTick();
    }
    tearDownAuthenticatedSession() {
      this.broadcastManager.logout();
      this.updateTimer?.stop();
      this.activityMonitor?.stop();
      this.expirationEvents?.reset();
      this.inactivityEvents?.reset();
      (0, _object.set)(this, 'timeSinceActive', 0);
      (0, _object.set)(this, 'tokenExpiresIn', 0);
      (0, _object.set)(this, 'idleLogoutIn', 0);
      (0, _object.set)(this, 'isExpiring', false);
      (0, _object.set)(this, 'isInactive', false);
    }

    /**
     * Callback executed once per Timer tick while an authenticated session is active. This
     * drives most other expiration/idle behavior.
     */
    handleTimerTick() {
      if (this.isDestroyed) {
        return;
      }
      const now = _utils.Timer.now();
      const tokenExpiresIn = (this.expiresOn || now) - now;
      const timeSinceActive = now - (this.lastActive || now);
      const idleLogoutIn = (0, _configuration.getConfig)().inactivityTimeout - timeSinceActive;
      (0, _object.set)(this, 'tokenExpiresIn', tokenExpiresIn);
      (0, _object.set)(this, 'timeSinceActive', timeSinceActive);
      (0, _object.set)(this, 'idleLogoutIn', idleLogoutIn);
      this.expirationEvents?.update(tokenExpiresIn);
      this.inactivityEvents?.update(idleLogoutIn);
    }

    /**
     * Callback executed by the "expirationEvents" TimedEventPlanner when the remaining
     * token lifetime drops within its configured duration.
     */
    handleExpirationEvents(idx, satisfiedOn, calledOn) {
      if (this.isDestroyed) {
        return;
      }
      (0, _object.set)(this, 'isExpiring', true);
      if (satisfiedOn === 0) {
        this.invalidate().catch(() => {/* noop */});
      }
      this.session.trigger(_internalSession.SessionEvents.PENDING_EXPIRATION, idx, calledOn);
      if (idx === 0) {
        // Reset idle time on server so token expiration always occurs first
        this.handleActivityMonitorEvents(undefined, true);
        this.activityMonitor?.stop();
      }
    }

    /**
     * Callback executed by the "inactivityEvents" TimedEventPlanner when it detects that
     * interaction with the application has not occurred within its configured duration.
     */
    handleInactiveEvents(idx, satisfiedOn, calledOn) {
      // Swallow any inactivity events after the session enters the expiring state
      if (this.isDestroyed || this.isExpiring) {
        return;
      }
      (0, _object.set)(this, 'isInactive', true);
      if (satisfiedOn === 0) {
        this.invalidate().catch(() => {/* noop */});
      } else {
        this.session.trigger(_internalSession.SessionEvents.PENDING_INACTIVE, idx, calledOn);
      }
    }

    /**
     * Callback executed by the ActivityMonitor when it detects interaction.
     */
    handleActivityMonitorEvents(_event) {
      let resetIdle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (this.isDestroyed) {
        return;
      }
      if (this.isInactive || resetIdle) {
        this.iamDataService.resetIdleTime().catch(() => {/* noop */});
      }
      this.inactivityEvents?.reset();
      (0, _object.set)(this, 'lastActive', _utils.Timer.now());
      (0, _object.set)(this, 'timeSinceActive', 0);
      (0, _object.set)(this, 'idleLogoutIn', (0, _configuration.getConfig)().inactivityTimeout);
      (0, _object.set)(this, 'isInactive', false);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "user", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "accessToken", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "expiresOn", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "iamDataService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});