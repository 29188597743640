define("@nsf-open/ember-currency-utils/computed-macros", ["exports", "@ember/object", "@nsf-open/ember-currency-utils/formatters"], function (_exports, _object, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fromUSD = fromUSD;
  _exports.toUSD = toUSD;
  /**
   * Exposes the `fromUSDFormat` formatter as an Ember computed decorator that converts
   * a formatted currency value to a float.
   *
   * ```javascript
   * public totalCost = "$12,345.55"
   *
   * @fromUSD('totalCost')
   * public numericCost!: number; // => 12345.55
   * ```
   */
  function fromUSD(dependantKey) {
    return (0, _object.computed)(dependantKey, function computedFromUSD() {
      return (0, _formatters.fromUSDFormat)((0, _object.get)(this, dependantKey));
    });
  }

  /**
   * Exposes the `toUSDFormat` formatter as an Ember computed decorator that converts
   * a numerical value to USD currency.
   *
   * ```javascript
   * public someNumber = 12345.55
   *
   * @toUSD('someNumber')
   * public formattedWhole!: string; // => "$12,345"
   *
   * @toUSD('someNumber', { cents: true })
   * public formattedDouble!: string; // => "$12,345.55"
   * ```
   */
  function toUSD(dependantKey) {
    let showCentsOrOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return (0, _object.computed)(dependantKey, function computedToUSD() {
      return (0, _formatters.toUSDFormat)((0, _object.get)(this, dependantKey), showCentsOrOptions);
    });
  }
});